import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Differ from 'react-differ'

type Props = {
  branch: PropTypes.object.isRequired,
  branchMigrationCompleted: PropTypes.bool.isRequired,
  onMigrate: PropTypes.func.isRequired,
  onCommit: PropTypes.func.isRequired,
  diffs: PropTypes.array.isRequired,
  readonly: PropTypes.bool.isRequired,
}
export default class BranchInfo extends Component<Props> {
  render() {
    const { diffs, branch, branchMigrationCompleted } = this.props
    return <div style={{height: "100%", background: 'rgb(247, 247, 247)', borderLeft: "1px solid #ccc", flex: 1, display: 'flex', flexDirection: 'column', maxWidth: 480}}>
        <div style={{display: 'flex', flex: '1 1 28px', alignItems: 'center' }}>
          <div style={{flex: 1, paddingLeft: 8}}>Change List</div>
          <div style={{flex: 1}}>
            { this.props.readonly ? null : <button onClick={this.props.onMigrate} style={{flex: 2}}>Migrate</button> }
            { this.props.readonly ? null : <button onClick={this.props.onCommit} style={{flex: 2}} disabled={!branchMigrationCompleted}>Commit</button> }
          </div>
        </div>
        <div style={{flex: '1 1 100%', overflow: 'scroll'}}>
        { (diffs||[]).map((diff, i) =>
          <ExpansionPanel key={i} style={{marginBottom: 4, marginTop: 4}}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>{diff.objectName}</ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Differ key={i} from={diff.prev === undefined ? "" : diff.prev} to={diff.curr === undefined ? "" : diff.curr} style={{ width: 260}}/>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )}
      </div>
    </div>
  }
}
