import React, { useRef, useState } from 'react'
import * as R from 'ramda'

import { useViewedObject, useBranch } from './Contexts';

function EnumValueEditor(props) {
  let branch = useBranch();
  let readOnly = branch.closed || branch.merged;
  let { value, onChange, onDelete } = props;
  let [isEditingDescription, setIsEditingDescription] = useState(false);
  let descriptionInputRef = useRef();

  function onDescriptionSaved(e) {
    e.preventDefault();
    setIsEditingDescription(false);
    onChange(R.assocPath(['description'], descriptionInputRef.current.value, value));
  }

  return (
    <div key={value.value} style={{borderBottom: "1px solid #ddd", padding: "5 0", fontSize: 13}}>
      <div>
        <span style={{fontWeight: "bold"}}>Value</span>
        <div style={{fontFamily: "monospace", fontSize: 14}}>{value.value}</div>
      </div>

      <div style={{marginTop: 5}}>
        {value.description && !isEditingDescription &&
          <div style={{marginTop: 10}}>
            <span style={{fontWeight: "bold"}}>Description</span>
            <div style={{color: "#666"}}>{value.description}</div>
          </div>
        }
        {isEditingDescription && 
          <form onSubmit={onDescriptionSaved}>
            <input ref={descriptionInputRef} type="text" disabled={readOnly} />
            <input type="submit" value="Save Description" disabled={readOnly} />
          </form>
        }
        <div>
          <button disabled={isEditingDescription} onClick={() => setIsEditingDescription(true)} disabled={readOnly}>Edit Description</button>
          <button onClick={onDelete} disabled={readOnly}>Delete</button>
        </div>
      </div>
    </div>
  );
}

export default function EnumEditorForm(props) {
  let branch = useBranch();
  let readOnly = branch.closed || branch.merged;
  let { onEdited, onSaved } = props;
  let [object, isEdited] = useViewedObject();
  let [shouldShowCreateForm, setShouldShowCreateForm] = useState(false);
  let [currentFormInputValue, setCurrentFormInputValue] = useState('');

  function onCreateFormSubmit(e) {
    e.preventDefault();
    setShouldShowCreateForm(false);

    let newEnumValue = {
      value: currentFormInputValue,
      description: null
    };

    onEdited(R.assocPath(['body', 'values'], R.append(newEnumValue, object.body.values), object));
  }
  function onEnumValueChange(index, newEnumValue) {
    onEdited(R.assocPath(['body', 'values'], R.update(index, newEnumValue, object.body.values), object));
  }

  function onEnumValueDelete(index) {
    onEdited(R.assocPath(['body', 'values'], R.remove(index, 1, object.body.values), object));
  }

  function shouldDisableSubmit() {
    return !currentFormInputValue || object.body.values.findIndex(val => val.value === currentFormInputValue) != -1;
  }

  return (
    <div style={{padding: 10}}>
      <div>
        <span style={{fontSize: 11, fontFamily: "monospace", padding: "2 5", background: "#eee", color: "#888"}}>Enum</span>
        <div style={{fontSize: 18, fontFamily: "monospace", marginTop: 5}}>{object.name}</div>
      </div>
      <hr/>
      <div>
        {
          object.body.values.map((value, index) => 
            <EnumValueEditor 
              key={value.value} 
              value={value} 
              onChange={(newEnumValue) => onEnumValueChange(index, newEnumValue)} 
              onDelete={() => onEnumValueDelete(index)}/>
          )
        }
      </div>
      <div style={{marginTop: 10}}> 
        {shouldShowCreateForm ? 
          <form onSubmit={onCreateFormSubmit}>
            <label>Create New Enum Value:</label>
            <input value={currentFormInputValue} onChange={(e) => setCurrentFormInputValue(e.target.value)} type="text" />
            <input disabled={shouldDisableSubmit()} type="submit" value="Create"/>
          </form>
          : readOnly
            ? null
            : <button onClick={() => setShouldShowCreateForm(true)} >Add Value</button>
        }
      </div>
    </div>
  );
}
