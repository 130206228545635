import React, { Component, useState } from 'react'
import { useViewedSchema } from './Contexts';

export default function SchemaExplorer(props) {
  
  let { onObjectSelected } = props;
  let [searchKeyword, setSearchKeyword] = useState('');
  let schema = useViewedSchema();

  function getObjects() {
    return Object.values(schema.objects)
      .filter(o => !!!searchKeyword || o.name.toLowerCase().match(searchKeyword.toLowerCase()))
      .sort((o1, o2) => o1.name < o2.name ? -1 : 1 );
      
  }

  function renderObjectRow(object) {
    return <div
      style={{padding: 5, fontSize: 14, fontFamily: "monospace", cursor: "pointer"}}
      key={object.name}
      onClick={() => onObjectSelected(object.name)}>
      {object.name}
    </div>
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column', flex: 10}}>
      <div style={{padding: 5, background: "#eee", borderBottom: "1px solid #ccc", fontSize: 14, fontWeight: "bold", flex: 1}}>
        <input type="text" onKeyUp={evt => setSearchKeyword(evt.target.value)} style={{width: '100%'}} placeholder={"Search object name"}/>
      </div>
      <div style={{'display': 'flex', flex: 20, overflow: "scroll", flexDirection: 'column'}}>
        {getObjects().map(renderObjectRow)}
      </div>
    </div>
  );
}
