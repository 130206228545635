// Generated from ./grammar/entity.g4 by ANTLR 4.7.1
// jshint ignore: start
var antlr4 = require('antlr4/index');
var entityListener = require('./entityListener').entityListener;
var entityVisitor = require('./entityVisitor').entityVisitor;

var grammarFileName = "entity.g4";

var serializedATN = ["\u0003\u608b\ua72a\u8133\ub9ed\u417c\u3be7\u7786\u5964",
    "\u0003\\\u02f5\u0004\u0002\t\u0002\u0004\u0003\t\u0003\u0004\u0004\t",
    "\u0004\u0004\u0005\t\u0005\u0004\u0006\t\u0006\u0004\u0007\t\u0007\u0004",
    "\b\t\b\u0004\t\t\t\u0004\n\t\n\u0004\u000b\t\u000b\u0004\f\t\f\u0004",
    "\r\t\r\u0004\u000e\t\u000e\u0004\u000f\t\u000f\u0004\u0010\t\u0010\u0004",
    "\u0011\t\u0011\u0004\u0012\t\u0012\u0004\u0013\t\u0013\u0004\u0014\t",
    "\u0014\u0004\u0015\t\u0015\u0004\u0016\t\u0016\u0004\u0017\t\u0017\u0004",
    "\u0018\t\u0018\u0004\u0019\t\u0019\u0004\u001a\t\u001a\u0004\u001b\t",
    "\u001b\u0004\u001c\t\u001c\u0004\u001d\t\u001d\u0004\u001e\t\u001e\u0004",
    "\u001f\t\u001f\u0004 \t \u0004!\t!\u0004\"\t\"\u0004#\t#\u0004$\t$\u0004",
    "%\t%\u0004&\t&\u0004\'\t\'\u0004(\t(\u0004)\t)\u0004*\t*\u0004+\t+\u0004",
    ",\t,\u0004-\t-\u0004.\t.\u0004/\t/\u00040\t0\u00041\t1\u00042\t2\u0004",
    "3\t3\u00044\t4\u00045\t5\u00046\t6\u00047\t7\u00048\t8\u00049\t9\u0004",
    ":\t:\u0004;\t;\u0004<\t<\u0004=\t=\u0004>\t>\u0004?\t?\u0004@\t@\u0004",
    "A\tA\u0004B\tB\u0004C\tC\u0004D\tD\u0004E\tE\u0004F\tF\u0004G\tG\u0004",
    "H\tH\u0004I\tI\u0004J\tJ\u0004K\tK\u0004L\tL\u0004M\tM\u0004N\tN\u0004",
    "O\tO\u0004P\tP\u0004Q\tQ\u0004R\tR\u0004S\tS\u0004T\tT\u0004U\tU\u0004",
    "V\tV\u0004W\tW\u0004X\tX\u0004Y\tY\u0004Z\tZ\u0003\u0002\u0003\u0002",
    "\u0003\u0002\u0007\u0002\u00b8\n\u0002\f\u0002\u000e\u0002\u00bb\u000b",
    "\u0002\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003",
    "\u0003\u0003\u0004\u0005\u0004\u00c4\n\u0004\u0003\u0004\u0003\u0004",
    "\u0005\u0004\u00c8\n\u0004\u0003\u0004\u0005\u0004\u00cb\n\u0004\u0003",
    "\u0005\u0003\u0005\u0003\u0005\u0003\u0005\u0006\u0005\u00d1\n\u0005",
    "\r\u0005\u000e\u0005\u00d2\u0003\u0005\u0003\u0005\u0003\u0006\u0003",
    "\u0006\u0003\u0006\u0005\u0006\u00da\n\u0006\u0003\u0007\u0003\u0007",
    "\u0003\u0007\u0006\u0007\u00df\n\u0007\r\u0007\u000e\u0007\u00e0\u0003",
    "\u0007\u0003\u0007\u0003\b\u0003\b\u0003\b\u0003\t\u0003\t\u0003\t\u0003",
    "\t\u0003\t\u0006\t\u00ed\n\t\r\t\u000e\t\u00ee\u0003\t\u0003\t\u0005",
    "\t\u00f3\n\t\u0003\n\u0003\n\u0003\u000b\u0003\u000b\u0003\u000b\u0003",
    "\u000b\u0005\u000b\u00fb\n\u000b\u0003\f\u0003\f\u0003\f\u0003\f\u0003",
    "\r\u0003\r\u0003\r\u0003\r\u0003\u000e\u0003\u000e\u0003\u000e\u0003",
    "\u000e\u0003\u000f\u0003\u000f\u0003\u000f\u0003\u000f\u0003\u0010\u0003",
    "\u0010\u0003\u0011\u0003\u0011\u0003\u0011\u0006\u0011\u0112\n\u0011",
    "\r\u0011\u000e\u0011\u0113\u0003\u0011\u0003\u0011\u0003\u0012\u0005",
    "\u0012\u0119\n\u0012\u0003\u0012\u0003\u0012\u0003\u0012\u0003\u0012",
    "\u0003\u0012\u0006\u0012\u0120\n\u0012\r\u0012\u000e\u0012\u0121\u0003",
    "\u0012\u0003\u0012\u0005\u0012\u0126\n\u0012\u0003\u0013\u0003\u0013",
    "\u0003\u0013\u0003\u0013\u0003\u0013\u0003\u0013\u0003\u0013\u0003\u0013",
    "\u0003\u0013\u0005\u0013\u0131\n\u0013\u0003\u0014\u0003\u0014\u0003",
    "\u0014\u0006\u0014\u0136\n\u0014\r\u0014\u000e\u0014\u0137\u0003\u0014",
    "\u0003\u0014\u0003\u0015\u0003\u0015\u0005\u0015\u013e\n\u0015\u0003",
    "\u0016\u0003\u0016\u0003\u0016\u0003\u0016\u0003\u0017\u0003\u0017\u0003",
    "\u0017\u0003\u0017\u0003\u0018\u0003\u0018\u0003\u0018\u0006\u0018\u014b",
    "\n\u0018\r\u0018\u000e\u0018\u014c\u0003\u0018\u0003\u0018\u0003\u0019",
    "\u0003\u0019\u0003\u0019\u0003\u0019\u0003\u001a\u0003\u001a\u0003\u001a",
    "\u0006\u001a\u0158\n\u001a\r\u001a\u000e\u001a\u0159\u0003\u001a\u0003",
    "\u001a\u0005\u001a\u015e\n\u001a\u0003\u001b\u0003\u001b\u0005\u001b",
    "\u0162\n\u001b\u0003\u001c\u0003\u001c\u0003\u001c\u0003\u001c\u0003",
    "\u001d\u0003\u001d\u0003\u001d\u0006\u001d\u016b\n\u001d\r\u001d\u000e",
    "\u001d\u016c\u0003\u001d\u0003\u001d\u0003\u001e\u0003\u001e\u0003\u001e",
    "\u0006\u001e\u0174\n\u001e\r\u001e\u000e\u001e\u0175\u0003\u001e\u0003",
    "\u001e\u0003\u001f\u0003\u001f\u0005\u001f\u017c\n\u001f\u0003 \u0003",
    " \u0003!\u0003!\u0003!\u0003!\u0003!\u0003!\u0003!\u0007!\u0187\n!\f",
    "!\u000e!\u018a\u000b!\u0005!\u018c\n!\u0003!\u0003!\u0003!\u0003!\u0003",
    "!\u0003\"\u0003\"\u0003\"\u0003#\u0003#\u0005#\u0198\n#\u0003$\u0003",
    "$\u0005$\u019c\n$\u0003$\u0005$\u019f\n$\u0003$\u0005$\u01a2\n$\u0003",
    "$\u0003$\u0003%\u0003%\u0003%\u0003%\u0003%\u0003&\u0003&\u0005&\u01ad",
    "\n&\u0003\'\u0003\'\u0003\'\u0005\'\u01b2\n\'\u0003\'\u0005\'\u01b5",
    "\n\'\u0003\'\u0005\'\u01b8\n\'\u0003(\u0003(\u0003(\u0003(\u0003)\u0003",
    ")\u0003*\u0003*\u0003*\u0006*\u01c3\n*\r*\u000e*\u01c4\u0003+\u0003",
    "+\u0003,\u0003,\u0005,\u01cb\n,\u0003,\u0005,\u01ce\n,\u0003,\u0003",
    ",\u0003-\u0003-\u0003-\u0003-\u0003-\u0003.\u0003.\u0003.\u0003.\u0003",
    ".\u0003/\u0003/\u0003/\u0003/\u0007/\u01e0\n/\f/\u000e/\u01e3\u000b",
    "/\u00030\u00030\u00031\u00031\u00031\u00031\u00032\u00032\u00033\u0003",
    "3\u00033\u00053\u01f0\n3\u00033\u00033\u00033\u00033\u00053\u01f6\n",
    "3\u00034\u00034\u00035\u00055\u01fb\n5\u00035\u00035\u00036\u00036\u0003",
    "6\u00066\u0202\n6\r6\u000e6\u0203\u00036\u00036\u00037\u00037\u0003",
    "7\u00037\u00038\u00038\u00038\u00038\u00038\u00039\u00039\u00039\u0006",
    "9\u0214\n9\r9\u000e9\u0215\u00039\u00039\u0003:\u0003:\u0003:\u0003",
    ";\u0003;\u0003<\u0003<\u0003<\u0003<\u0003=\u0003=\u0003=\u0007=\u0226",
    "\n=\f=\u000e=\u0229\u000b=\u0003>\u0003>\u0003>\u0007>\u022e\n>\f>\u000e",
    ">\u0231\u000b>\u0003?\u0003?\u0003?\u0007?\u0236\n?\f?\u000e?\u0239",
    "\u000b?\u0003@\u0007@\u023c\n@\f@\u000e@\u023f\u000b@\u0003A\u0003A",
    "\u0003A\u0003A\u0005A\u0245\nA\u0003B\u0003B\u0003B\u0003B\u0005B\u024b",
    "\nB\u0003B\u0003B\u0003C\u0003C\u0003C\u0007C\u0252\nC\fC\u000eC\u0255",
    "\u000bC\u0003D\u0003D\u0003D\u0005D\u025a\nD\u0003D\u0003D\u0003D\u0005",
    "D\u025f\nD\u0003E\u0003E\u0003F\u0003F\u0003F\u0003F\u0003F\u0003F\u0005",
    "F\u0269\nF\u0003G\u0003G\u0005G\u026d\nG\u0003H\u0003H\u0003H\u0003",
    "H\u0003H\u0003H\u0003H\u0003H\u0003H\u0005H\u0278\nH\u0003H\u0003H\u0003",
    "H\u0003H\u0005H\u027e\nH\u0003I\u0003I\u0003I\u0003I\u0003I\u0003I\u0003",
    "J\u0003J\u0005J\u0288\nJ\u0003J\u0003J\u0003J\u0007J\u028d\nJ\fJ\u000e",
    "J\u0290\u000bJ\u0003K\u0003K\u0003K\u0003K\u0007K\u0296\nK\fK\u000e",
    "K\u0299\u000bK\u0003L\u0003L\u0003M\u0003M\u0003M\u0003M\u0007M\u02a1",
    "\nM\fM\u000eM\u02a4\u000bM\u0003N\u0003N\u0003O\u0003O\u0003O\u0003",
    "O\u0003O\u0003O\u0003O\u0005O\u02af\nO\u0003P\u0003P\u0003P\u0005P\u02b4",
    "\nP\u0003Q\u0003Q\u0003Q\u0003Q\u0003R\u0003R\u0003R\u0003R\u0003R\u0003",
    "R\u0005R\u02c0\nR\u0003R\u0005R\u02c3\nR\u0003S\u0003S\u0003S\u0003",
    "S\u0003S\u0003S\u0003S\u0003S\u0005S\u02cd\nS\u0003T\u0003T\u0003U\u0006",
    "U\u02d2\nU\rU\u000eU\u02d3\u0003V\u0003V\u0003V\u0003V\u0003V\u0003",
    "W\u0003W\u0003W\u0007W\u02de\nW\fW\u000eW\u02e1\u000bW\u0003X\u0003",
    "X\u0003X\u0003X\u0005X\u02e7\nX\u0003X\u0005X\u02ea\nX\u0003X\u0003",
    "X\u0003X\u0005X\u02ef\nX\u0003Y\u0003Y\u0003Z\u0003Z\u0003Z\u0002\u0002",
    "[\u0002\u0004\u0006\b\n\f\u000e\u0010\u0012\u0014\u0016\u0018\u001a",
    "\u001c\u001e \"$&(*,.02468:<>@BDFHJLNPRTVXZ\\^`bdfhjlnprtvxz|~\u0080",
    "\u0082\u0084\u0086\u0088\u008a\u008c\u008e\u0090\u0092\u0094\u0096\u0098",
    "\u009a\u009c\u009e\u00a0\u00a2\u00a4\u00a6\u00a8\u00aa\u00ac\u00ae\u00b0",
    "\u00b2\u0002\u000f\u0003\u0002UV\u0003\u0002\f\u0010\u0003\u0002SU\u0003",
    "\u0002,-\u0004\u0002\u001e\u001e05\u0003\u0002NR\u0004\u0002SSUU\u0004",
    "\u0002MMVV\u0003\u00029<\u0003\u0002AB\u0003\u0002CE\u0004\u0002AEI",
    "I\u0005\u0002,-03JJ\u0002\u02f5\u0002\u00b9\u0003\u0002\u0002\u0002",
    "\u0004\u00bc\u0003\u0002\u0002\u0002\u0006\u00c3\u0003\u0002\u0002\u0002",
    "\b\u00cc\u0003\u0002\u0002\u0002\n\u00d6\u0003\u0002\u0002\u0002\f\u00db",
    "\u0003\u0002\u0002\u0002\u000e\u00e4\u0003\u0002\u0002\u0002\u0010\u00f2",
    "\u0003\u0002\u0002\u0002\u0012\u00f4\u0003\u0002\u0002\u0002\u0014\u00fa",
    "\u0003\u0002\u0002\u0002\u0016\u00fc\u0003\u0002\u0002\u0002\u0018\u0100",
    "\u0003\u0002\u0002\u0002\u001a\u0104\u0003\u0002\u0002\u0002\u001c\u0108",
    "\u0003\u0002\u0002\u0002\u001e\u010c\u0003\u0002\u0002\u0002 \u010e",
    "\u0003\u0002\u0002\u0002\"\u0118\u0003\u0002\u0002\u0002$\u0130\u0003",
    "\u0002\u0002\u0002&\u0132\u0003\u0002\u0002\u0002(\u013d\u0003\u0002",
    "\u0002\u0002*\u013f\u0003\u0002\u0002\u0002,\u0143\u0003\u0002\u0002",
    "\u0002.\u0147\u0003\u0002\u0002\u00020\u0150\u0003\u0002\u0002\u0002",
    "2\u0154\u0003\u0002\u0002\u00024\u0161\u0003\u0002\u0002\u00026\u0163",
    "\u0003\u0002\u0002\u00028\u0167\u0003\u0002\u0002\u0002:\u0170\u0003",
    "\u0002\u0002\u0002<\u0179\u0003\u0002\u0002\u0002>\u017d\u0003\u0002",
    "\u0002\u0002@\u017f\u0003\u0002\u0002\u0002B\u0192\u0003\u0002\u0002",
    "\u0002D\u0197\u0003\u0002\u0002\u0002F\u0199\u0003\u0002\u0002\u0002",
    "H\u01a5\u0003\u0002\u0002\u0002J\u01ac\u0003\u0002\u0002\u0002L\u01ae",
    "\u0003\u0002\u0002\u0002N\u01b9\u0003\u0002\u0002\u0002P\u01bd\u0003",
    "\u0002\u0002\u0002R\u01bf\u0003\u0002\u0002\u0002T\u01c6\u0003\u0002",
    "\u0002\u0002V\u01c8\u0003\u0002\u0002\u0002X\u01d1\u0003\u0002\u0002",
    "\u0002Z\u01d6\u0003\u0002\u0002\u0002\\\u01db\u0003\u0002\u0002\u0002",
    "^\u01e4\u0003\u0002\u0002\u0002`\u01e6\u0003\u0002\u0002\u0002b\u01ea",
    "\u0003\u0002\u0002\u0002d\u01f5\u0003\u0002\u0002\u0002f\u01f7\u0003",
    "\u0002\u0002\u0002h\u01fa\u0003\u0002\u0002\u0002j\u01fe\u0003\u0002",
    "\u0002\u0002l\u0207\u0003\u0002\u0002\u0002n\u020b\u0003\u0002\u0002",
    "\u0002p\u0210\u0003\u0002\u0002\u0002r\u0219\u0003\u0002\u0002\u0002",
    "t\u021c\u0003\u0002\u0002\u0002v\u021e\u0003\u0002\u0002\u0002x\u0222",
    "\u0003\u0002\u0002\u0002z\u022a\u0003\u0002\u0002\u0002|\u0232\u0003",
    "\u0002\u0002\u0002~\u023d\u0003\u0002\u0002\u0002\u0080\u0244\u0003",
    "\u0002\u0002\u0002\u0082\u0246\u0003\u0002\u0002\u0002\u0084\u024e\u0003",
    "\u0002\u0002\u0002\u0086\u025e\u0003\u0002\u0002\u0002\u0088\u0260\u0003",
    "\u0002\u0002\u0002\u008a\u0262\u0003\u0002\u0002\u0002\u008c\u026c\u0003",
    "\u0002\u0002\u0002\u008e\u026e\u0003\u0002\u0002\u0002\u0090\u027f\u0003",
    "\u0002\u0002\u0002\u0092\u0287\u0003\u0002\u0002\u0002\u0094\u0291\u0003",
    "\u0002\u0002\u0002\u0096\u029a\u0003\u0002\u0002\u0002\u0098\u029c\u0003",
    "\u0002\u0002\u0002\u009a\u02a5\u0003\u0002\u0002\u0002\u009c\u02ae\u0003",
    "\u0002\u0002\u0002\u009e\u02b3\u0003\u0002\u0002\u0002\u00a0\u02b5\u0003",
    "\u0002\u0002\u0002\u00a2\u02c2\u0003\u0002\u0002\u0002\u00a4\u02cc\u0003",
    "\u0002\u0002\u0002\u00a6\u02ce\u0003\u0002\u0002\u0002\u00a8\u02d1\u0003",
    "\u0002\u0002\u0002\u00aa\u02d5\u0003\u0002\u0002\u0002\u00ac\u02da\u0003",
    "\u0002\u0002\u0002\u00ae\u02ee\u0003\u0002\u0002\u0002\u00b0\u02f0\u0003",
    "\u0002\u0002\u0002\u00b2\u02f2\u0003\u0002\u0002\u0002\u00b4\u00b8\u0005",
    "> \u0002\u00b5\u00b8\u0005\b\u0005\u0002\u00b6\u00b8\u0005\u0004\u0003",
    "\u0002\u00b7\u00b4\u0003\u0002\u0002\u0002\u00b7\u00b5\u0003\u0002\u0002",
    "\u0002\u00b7\u00b6\u0003\u0002\u0002\u0002\u00b8\u00bb\u0003\u0002\u0002",
    "\u0002\u00b9\u00b7\u0003\u0002\u0002\u0002\u00b9\u00ba\u0003\u0002\u0002",
    "\u0002\u00ba\u0003\u0003\u0002\u0002\u0002\u00bb\u00b9\u0003\u0002\u0002",
    "\u0002\u00bc\u00bd\u0007\u0003\u0002\u0002\u00bd\u00be\u0007U\u0002",
    "\u0002\u00be\u00bf\u0007\u0004\u0002\u0002\u00bf\u00c0\u0005\u0006\u0004",
    "\u0002\u00c0\u00c1\u0007\u0005\u0002\u0002\u00c1\u0005\u0003\u0002\u0002",
    "\u0002\u00c2\u00c4\u0005\f\u0007\u0002\u00c3\u00c2\u0003\u0002\u0002",
    "\u0002\u00c3\u00c4\u0003\u0002\u0002\u0002\u00c4\u00c5\u0003\u0002\u0002",
    "\u0002\u00c5\u00c7\u0005 \u0011\u0002\u00c6\u00c8\u00058\u001d\u0002",
    "\u00c7\u00c6\u0003\u0002\u0002\u0002\u00c7\u00c8\u0003\u0002\u0002\u0002",
    "\u00c8\u00ca\u0003\u0002\u0002\u0002\u00c9\u00cb\u0005.\u0018\u0002",
    "\u00ca\u00c9\u0003\u0002\u0002\u0002\u00ca\u00cb\u0003\u0002\u0002\u0002",
    "\u00cb\u0007\u0003\u0002\u0002\u0002\u00cc\u00cd\u0007\u0006\u0002\u0002",
    "\u00cd\u00ce\u0007U\u0002\u0002\u00ce\u00d0\u0007\u0004\u0002\u0002",
    "\u00cf\u00d1\u0005\n\u0006\u0002\u00d0\u00cf\u0003\u0002\u0002\u0002",
    "\u00d1\u00d2\u0003\u0002\u0002\u0002\u00d2\u00d0\u0003\u0002\u0002\u0002",
    "\u00d2\u00d3\u0003\u0002\u0002\u0002\u00d3\u00d4\u0003\u0002\u0002\u0002",
    "\u00d4\u00d5\u0007\u0005\u0002\u0002\u00d5\t\u0003\u0002\u0002\u0002",
    "\u00d6\u00d9\t\u0002\u0002\u0002\u00d7\u00d8\u0007\u0007\u0002\u0002",
    "\u00d8\u00da\u0007O\u0002\u0002\u00d9\u00d7\u0003\u0002\u0002\u0002",
    "\u00d9\u00da\u0003\u0002\u0002\u0002\u00da\u000b\u0003\u0002\u0002\u0002",
    "\u00db\u00dc\u0007\b\u0002\u0002\u00dc\u00de\u0007\u0004\u0002\u0002",
    "\u00dd\u00df\u0005\u000e\b\u0002\u00de\u00dd\u0003\u0002\u0002\u0002",
    "\u00df\u00e0\u0003\u0002\u0002\u0002\u00e0\u00de\u0003\u0002\u0002\u0002",
    "\u00e0\u00e1\u0003\u0002\u0002\u0002\u00e1\u00e2\u0003\u0002\u0002\u0002",
    "\u00e2\u00e3\u0007\u0005\u0002\u0002\u00e3\r\u0003\u0002\u0002\u0002",
    "\u00e4\u00e5\u0005\u0012\n\u0002\u00e5\u00e6\u0005\u0010\t\u0002\u00e6",
    "\u000f\u0003\u0002\u0002\u0002\u00e7\u00f3\u0007\t\u0002\u0002\u00e8",
    "\u00f3\u0007\n\u0002\u0002\u00e9\u00f3\u0007\u000b\u0002\u0002\u00ea",
    "\u00ec\u0007\u0004\u0002\u0002\u00eb\u00ed\u0005\u0014\u000b\u0002\u00ec",
    "\u00eb\u0003\u0002\u0002\u0002\u00ed\u00ee\u0003\u0002\u0002\u0002\u00ee",
    "\u00ec\u0003\u0002\u0002\u0002\u00ee\u00ef\u0003\u0002\u0002\u0002\u00ef",
    "\u00f0\u0003\u0002\u0002\u0002\u00f0\u00f1\u0007\u0005\u0002\u0002\u00f1",
    "\u00f3\u0003\u0002\u0002\u0002\u00f2\u00e7\u0003\u0002\u0002\u0002\u00f2",
    "\u00e8\u0003\u0002\u0002\u0002\u00f2\u00e9\u0003\u0002\u0002\u0002\u00f2",
    "\u00ea\u0003\u0002\u0002\u0002\u00f3\u0011\u0003\u0002\u0002\u0002\u00f4",
    "\u00f5\t\u0003\u0002\u0002\u00f5\u0013\u0003\u0002\u0002\u0002\u00f6",
    "\u00fb\u0005\u0016\f\u0002\u00f7\u00fb\u0005\u0018\r\u0002\u00f8\u00fb",
    "\u0005\u001a\u000e\u0002\u00f9\u00fb\u0005\u001c\u000f\u0002\u00fa\u00f6",
    "\u0003\u0002\u0002\u0002\u00fa\u00f7\u0003\u0002\u0002\u0002\u00fa\u00f8",
    "\u0003\u0002\u0002\u0002\u00fa\u00f9\u0003\u0002\u0002\u0002\u00fb\u0015",
    "\u0003\u0002\u0002\u0002\u00fc\u00fd\u0007\u0011\u0002\u0002\u00fd\u00fe",
    "\u0007\u0007\u0002\u0002\u00fe\u00ff\u0007O\u0002\u0002\u00ff\u0017",
    "\u0003\u0002\u0002\u0002\u0100\u0101\u0007\u0012\u0002\u0002\u0101\u0102",
    "\u0007\u0007\u0002\u0002\u0102\u0103\u0007O\u0002\u0002\u0103\u0019",
    "\u0003\u0002\u0002\u0002\u0104\u0105\u0007\u0013\u0002\u0002\u0105\u0106",
    "\u0007\u0007\u0002\u0002\u0106\u0107\u0005\u001e\u0010\u0002\u0107\u001b",
    "\u0003\u0002\u0002\u0002\u0108\u0109\u0005\u0082B\u0002\u0109\u010a",
    "\u0007\u0014\u0002\u0002\u010a\u010b\u0007O\u0002\u0002\u010b\u001d",
    "\u0003\u0002\u0002\u0002\u010c\u010d\u0007N\u0002\u0002\u010d\u001f",
    "\u0003\u0002\u0002\u0002\u010e\u010f\u0007\u0015\u0002\u0002\u010f\u0111",
    "\u0007\u0004\u0002\u0002\u0110\u0112\u0005\"\u0012\u0002\u0111\u0110",
    "\u0003\u0002\u0002\u0002\u0112\u0113\u0003\u0002\u0002\u0002\u0113\u0111",
    "\u0003\u0002\u0002\u0002\u0113\u0114\u0003\u0002\u0002\u0002\u0114\u0115",
    "\u0003\u0002\u0002\u0002\u0115\u0116\u0007\u0005\u0002\u0002\u0116!",
    "\u0003\u0002\u0002\u0002\u0117\u0119\u0007\u0016\u0002\u0002\u0118\u0117",
    "\u0003\u0002\u0002\u0002\u0118\u0119\u0003\u0002\u0002\u0002\u0119\u011a",
    "\u0003\u0002\u0002\u0002\u011a\u011b\u0007V\u0002\u0002\u011b\u011c",
    "\u0007\u0007\u0002\u0002\u011c\u0125\u0007U\u0002\u0002\u011d\u011f",
    "\u0007\u0004\u0002\u0002\u011e\u0120\u0005$\u0013\u0002\u011f\u011e",
    "\u0003\u0002\u0002\u0002\u0120\u0121\u0003\u0002\u0002\u0002\u0121\u011f",
    "\u0003\u0002\u0002\u0002\u0121\u0122\u0003\u0002\u0002\u0002\u0122\u0123",
    "\u0003\u0002\u0002\u0002\u0123\u0124\u0007\u0005\u0002\u0002\u0124\u0126",
    "\u0003\u0002\u0002\u0002\u0125\u011d\u0003\u0002\u0002\u0002\u0125\u0126",
    "\u0003\u0002\u0002\u0002\u0126#\u0003\u0002\u0002\u0002\u0127\u0131",
    "\u0007\u0017\u0002\u0002\u0128\u0131\u0007\u0018\u0002\u0002\u0129\u0131",
    "\u0007\u0019\u0002\u0002\u012a\u0131\u0007\u001a\u0002\u0002\u012b\u012c",
    "\u0007\u001b\u0002\u0002\u012c\u012d\u0007\u0004\u0002\u0002\u012d\u012e",
    "\u0005&\u0014\u0002\u012e\u012f\u0007\u0005\u0002\u0002\u012f\u0131",
    "\u0003\u0002\u0002\u0002\u0130\u0127\u0003\u0002\u0002\u0002\u0130\u0128",
    "\u0003\u0002\u0002\u0002\u0130\u0129\u0003\u0002\u0002\u0002\u0130\u012a",
    "\u0003\u0002\u0002\u0002\u0130\u012b\u0003\u0002\u0002\u0002\u0131%",
    "\u0003\u0002\u0002\u0002\u0132\u0133\u0007V\u0002\u0002\u0133\u0135",
    "\u0007\u0004\u0002\u0002\u0134\u0136\u0005(\u0015\u0002\u0135\u0134",
    "\u0003\u0002\u0002\u0002\u0136\u0137\u0003\u0002\u0002\u0002\u0137\u0135",
    "\u0003\u0002\u0002\u0002\u0137\u0138\u0003\u0002\u0002\u0002\u0138\u0139",
    "\u0003\u0002\u0002\u0002\u0139\u013a\u0007\u0005\u0002\u0002\u013a\'",
    "\u0003\u0002\u0002\u0002\u013b\u013e\u0005*\u0016\u0002\u013c\u013e",
    "\u0005,\u0017\u0002\u013d\u013b\u0003\u0002\u0002\u0002\u013d\u013c",
    "\u0003\u0002\u0002\u0002\u013e)\u0003\u0002\u0002\u0002\u013f\u0140",
    "\u0007\u001c\u0002\u0002\u0140\u0141\u0007\u0007\u0002\u0002\u0141\u0142",
    "\u0007O\u0002\u0002\u0142+\u0003\u0002\u0002\u0002\u0143\u0144\u0007",
    "\u0012\u0002\u0002\u0144\u0145\u0007\u0007\u0002\u0002\u0145\u0146\u0007",
    "O\u0002\u0002\u0146-\u0003\u0002\u0002\u0002\u0147\u0148\u0007\u001d",
    "\u0002\u0002\u0148\u014a\u0007\u0004\u0002\u0002\u0149\u014b\u00050",
    "\u0019\u0002\u014a\u0149\u0003\u0002\u0002\u0002\u014b\u014c\u0003\u0002",
    "\u0002\u0002\u014c\u014a\u0003\u0002\u0002\u0002\u014c\u014d\u0003\u0002",
    "\u0002\u0002\u014d\u014e\u0003\u0002\u0002\u0002\u014e\u014f\u0007\u0005",
    "\u0002\u0002\u014f/\u0003\u0002\u0002\u0002\u0150\u0151\u0007V\u0002",
    "\u0002\u0151\u0152\u0007\u0007\u0002\u0002\u0152\u0153\u00052\u001a",
    "\u0002\u01531\u0003\u0002\u0002\u0002\u0154\u015d\t\u0004\u0002\u0002",
    "\u0155\u0157\u0007\u0004\u0002\u0002\u0156\u0158\u00054\u001b\u0002",
    "\u0157\u0156\u0003\u0002\u0002\u0002\u0158\u0159\u0003\u0002\u0002\u0002",
    "\u0159\u0157\u0003\u0002\u0002\u0002\u0159\u015a\u0003\u0002\u0002\u0002",
    "\u015a\u015b\u0003\u0002\u0002\u0002\u015b\u015c\u0007\u0005\u0002\u0002",
    "\u015c\u015e\u0003\u0002\u0002\u0002\u015d\u0155\u0003\u0002\u0002\u0002",
    "\u015d\u015e\u0003\u0002\u0002\u0002\u015e3\u0003\u0002\u0002\u0002",
    "\u015f\u0162\u0005Z.\u0002\u0160\u0162\u0005p9\u0002\u0161\u015f\u0003",
    "\u0002\u0002\u0002\u0161\u0160\u0003\u0002\u0002\u0002\u01625\u0003",
    "\u0002\u0002\u0002\u0163\u0164\u0007V\u0002\u0002\u0164\u0165\u0007",
    "\u001e\u0002\u0002\u0165\u0166\u0005z>\u0002\u01667\u0003\u0002\u0002",
    "\u0002\u0167\u0168\u0007\u001f\u0002\u0002\u0168\u016a\u0007\u0004\u0002",
    "\u0002\u0169\u016b\u0005:\u001e\u0002\u016a\u0169\u0003\u0002\u0002",
    "\u0002\u016b\u016c\u0003\u0002\u0002\u0002\u016c\u016a\u0003\u0002\u0002",
    "\u0002\u016c\u016d\u0003\u0002\u0002\u0002\u016d\u016e\u0003\u0002\u0002",
    "\u0002\u016e\u016f\u0007\u0005\u0002\u0002\u016f9\u0003\u0002\u0002",
    "\u0002\u0170\u0171\u0007V\u0002\u0002\u0171\u0173\u0007\u0004\u0002",
    "\u0002\u0172\u0174\u0005<\u001f\u0002\u0173\u0172\u0003\u0002\u0002",
    "\u0002\u0174\u0175\u0003\u0002\u0002\u0002\u0175\u0173\u0003\u0002\u0002",
    "\u0002\u0175\u0176\u0003\u0002\u0002\u0002\u0176\u0177\u0003\u0002\u0002",
    "\u0002\u0177\u0178\u0007\u0005\u0002\u0002\u0178;\u0003\u0002\u0002",
    "\u0002\u0179\u017b\u0007V\u0002\u0002\u017a\u017c\u0007K\u0002\u0002",
    "\u017b\u017a\u0003\u0002\u0002\u0002\u017b\u017c\u0003\u0002\u0002\u0002",
    "\u017c=\u0003\u0002\u0002\u0002\u017d\u017e\u0005@!\u0002\u017e?\u0003",
    "\u0002\u0002\u0002\u017f\u0180\u0005B\"\u0002\u0180\u0181\u0007 \u0002",
    "\u0002\u0181\u0182\u0007V\u0002\u0002\u0182\u018b\u0007!\u0002\u0002",
    "\u0183\u0188\u0005v<\u0002\u0184\u0185\u0007\"\u0002\u0002\u0185\u0187",
    "\u0005v<\u0002\u0186\u0184\u0003\u0002\u0002\u0002\u0187\u018a\u0003",
    "\u0002\u0002\u0002\u0188\u0186\u0003\u0002\u0002\u0002\u0188\u0189\u0003",
    "\u0002\u0002\u0002\u0189\u018c\u0003\u0002\u0002\u0002\u018a\u0188\u0003",
    "\u0002\u0002\u0002\u018b\u0183\u0003\u0002\u0002\u0002\u018b\u018c\u0003",
    "\u0002\u0002\u0002\u018c\u018d\u0003\u0002\u0002\u0002\u018d\u018e\u0007",
    "#\u0002\u0002\u018e\u018f\u0007\u0007\u0002\u0002\u018f\u0190\t\u0004",
    "\u0002\u0002\u0190\u0191\u0005D#\u0002\u0191A\u0003\u0002\u0002\u0002",
    "\u0192\u0193\u0007$\u0002\u0002\u0193\u0194\u0005\u0012\n\u0002\u0194",
    "C\u0003\u0002\u0002\u0002\u0195\u0198\u0005F$\u0002\u0196\u0198\u0005",
    "V,\u0002\u0197\u0195\u0003\u0002\u0002\u0002\u0197\u0196\u0003\u0002",
    "\u0002\u0002\u0198E\u0003\u0002\u0002\u0002\u0199\u019b\u0007\u0004",
    "\u0002\u0002\u019a\u019c\u0005H%\u0002\u019b\u019a\u0003\u0002\u0002",
    "\u0002\u019b\u019c\u0003\u0002\u0002\u0002\u019c\u019e\u0003\u0002\u0002",
    "\u0002\u019d\u019f\u0005Z.\u0002\u019e\u019d\u0003\u0002\u0002\u0002",
    "\u019e\u019f\u0003\u0002\u0002\u0002\u019f\u01a1\u0003\u0002\u0002\u0002",
    "\u01a0\u01a2\u0005p9\u0002\u01a1\u01a0\u0003\u0002\u0002\u0002\u01a1",
    "\u01a2\u0003\u0002\u0002\u0002\u01a2\u01a3\u0003\u0002\u0002\u0002\u01a3",
    "\u01a4\u0007\u0005\u0002\u0002\u01a4G\u0003\u0002\u0002\u0002\u01a5",
    "\u01a6\u0007%\u0002\u0002\u01a6\u01a7\u0007\u0004\u0002\u0002\u01a7",
    "\u01a8\u0005J&\u0002\u01a8\u01a9\u0007\u0005\u0002\u0002\u01a9I\u0003",
    "\u0002\u0002\u0002\u01aa\u01ad\u0005L\'\u0002\u01ab\u01ad\u0005P)\u0002",
    "\u01ac\u01aa\u0003\u0002\u0002\u0002\u01ac\u01ab\u0003\u0002\u0002\u0002",
    "\u01adK\u0003\u0002\u0002\u0002\u01ae\u01b7\u0007&\u0002\u0002\u01af",
    "\u01b1\u0007\u0004\u0002\u0002\u01b0\u01b2\u0005R*\u0002\u01b1\u01b0",
    "\u0003\u0002\u0002\u0002\u01b1\u01b2\u0003\u0002\u0002\u0002\u01b2\u01b4",
    "\u0003\u0002\u0002\u0002\u01b3\u01b5\u0005N(\u0002\u01b4\u01b3\u0003",
    "\u0002\u0002\u0002\u01b4\u01b5\u0003\u0002\u0002\u0002\u01b5\u01b6\u0003",
    "\u0002\u0002\u0002\u01b6\u01b8\u0007\u0005\u0002\u0002\u01b7\u01af\u0003",
    "\u0002\u0002\u0002\u01b7\u01b8\u0003\u0002\u0002\u0002\u01b8M\u0003",
    "\u0002\u0002\u0002\u01b9\u01ba\u0007\'\u0002\u0002\u01ba\u01bb\u0007",
    "\u0007\u0002\u0002\u01bb\u01bc\u0007Q\u0002\u0002\u01bcO\u0003\u0002",
    "\u0002\u0002\u01bd\u01be\u0007(\u0002\u0002\u01beQ\u0003\u0002\u0002",
    "\u0002\u01bf\u01c0\u0007)\u0002\u0002\u01c0\u01c2\u0007\u0007\u0002",
    "\u0002\u01c1\u01c3\u0005T+\u0002\u01c2\u01c1\u0003\u0002\u0002\u0002",
    "\u01c3\u01c4\u0003\u0002\u0002\u0002\u01c4\u01c2\u0003\u0002\u0002\u0002",
    "\u01c4\u01c5\u0003\u0002\u0002\u0002\u01c5S\u0003\u0002\u0002\u0002",
    "\u01c6\u01c7\u0007V\u0002\u0002\u01c7U\u0003\u0002\u0002\u0002\u01c8",
    "\u01ca\u0007\u0004\u0002\u0002\u01c9\u01cb\u0005H%\u0002\u01ca\u01c9",
    "\u0003\u0002\u0002\u0002\u01ca\u01cb\u0003\u0002\u0002\u0002\u01cb\u01cd",
    "\u0003\u0002\u0002\u0002\u01cc\u01ce\u0005X-\u0002\u01cd\u01cc\u0003",
    "\u0002\u0002\u0002\u01cd\u01ce\u0003\u0002\u0002\u0002\u01ce\u01cf\u0003",
    "\u0002\u0002\u0002\u01cf\u01d0\u0007\u0005\u0002\u0002\u01d0W\u0003",
    "\u0002\u0002\u0002\u01d1\u01d2\u0007*\u0002\u0002\u01d2\u01d3\u0007",
    "\u0004\u0002\u0002\u01d3\u01d4\u0005h5\u0002\u01d4\u01d5\u0007\u0005",
    "\u0002\u0002\u01d5Y\u0003\u0002\u0002\u0002\u01d6\u01d7\u0007+\u0002",
    "\u0002\u01d7\u01d8\u0007\u0004\u0002\u0002\u01d8\u01d9\u0005\\/\u0002",
    "\u01d9\u01da\u0007\u0005\u0002\u0002\u01da[\u0003\u0002\u0002\u0002",
    "\u01db\u01e1\u0005`1\u0002\u01dc\u01dd\u0005^0\u0002\u01dd\u01de\u0005",
    "`1\u0002\u01de\u01e0\u0003\u0002\u0002\u0002\u01df\u01dc\u0003\u0002",
    "\u0002\u0002\u01e0\u01e3\u0003\u0002\u0002\u0002\u01e1\u01df\u0003\u0002",
    "\u0002\u0002\u01e1\u01e2\u0003\u0002\u0002\u0002\u01e2]\u0003\u0002",
    "\u0002\u0002\u01e3\u01e1\u0003\u0002\u0002\u0002\u01e4\u01e5\t\u0005",
    "\u0002\u0002\u01e5_\u0003\u0002\u0002\u0002\u01e6\u01e7\u0005b2\u0002",
    "\u01e7\u01e8\u0005f4\u0002\u01e8\u01e9\u0005d3\u0002\u01e9a\u0003\u0002",
    "\u0002\u0002\u01ea\u01eb\u0007V\u0002\u0002\u01ebc\u0003\u0002\u0002",
    "\u0002\u01ec\u01f6\u0005t;\u0002\u01ed\u01f6\u0007L\u0002\u0002\u01ee",
    "\u01f0\u0007.\u0002\u0002\u01ef\u01ee\u0003\u0002\u0002\u0002\u01ef",
    "\u01f0\u0003\u0002\u0002\u0002\u01f0\u01f1\u0003\u0002\u0002\u0002\u01f1",
    "\u01f6\u0007V\u0002\u0002\u01f2\u01f3\u0007U\u0002\u0002\u01f3\u01f4",
    "\u0007/\u0002\u0002\u01f4\u01f6\u0005z>\u0002\u01f5\u01ec\u0003\u0002",
    "\u0002\u0002\u01f5\u01ed\u0003\u0002\u0002\u0002\u01f5\u01ef\u0003\u0002",
    "\u0002\u0002\u01f5\u01f2\u0003\u0002\u0002\u0002\u01f6e\u0003\u0002",
    "\u0002\u0002\u01f7\u01f8\t\u0006\u0002\u0002\u01f8g\u0003\u0002\u0002",
    "\u0002\u01f9\u01fb\u0005j6\u0002\u01fa\u01f9\u0003\u0002\u0002\u0002",
    "\u01fa\u01fb\u0003\u0002\u0002\u0002\u01fb\u01fc\u0003\u0002\u0002\u0002",
    "\u01fc\u01fd\u0005n8\u0002\u01fdi\u0003\u0002\u0002\u0002\u01fe\u01ff",
    "\u00076\u0002\u0002\u01ff\u0201\u0007\u0004\u0002\u0002\u0200\u0202",
    "\u0005l7\u0002\u0201\u0200\u0003\u0002\u0002\u0002\u0202\u0203\u0003",
    "\u0002\u0002\u0002\u0203\u0201\u0003\u0002\u0002\u0002\u0203\u0204\u0003",
    "\u0002\u0002\u0002\u0204\u0205\u0003\u0002\u0002\u0002\u0205\u0206\u0007",
    "\u0005\u0002\u0002\u0206k\u0003\u0002\u0002\u0002\u0207\u0208\u0007",
    "V\u0002\u0002\u0208\u0209\u0007\u0007\u0002\u0002\u0209\u020a\u0005",
    "d3\u0002\u020am\u0003\u0002\u0002\u0002\u020b\u020c\u0007 \u0002\u0002",
    "\u020c\u020d\u0007\u0004\u0002\u0002\u020d\u020e\u0007P\u0002\u0002",
    "\u020e\u020f\u0007\u0005\u0002\u0002\u020fo\u0003\u0002\u0002\u0002",
    "\u0210\u0211\u00077\u0002\u0002\u0211\u0213\u0007\u0004\u0002\u0002",
    "\u0212\u0214\u0005r:\u0002\u0213\u0212\u0003\u0002\u0002\u0002\u0214",
    "\u0215\u0003\u0002\u0002\u0002\u0215\u0213\u0003\u0002\u0002\u0002\u0215",
    "\u0216\u0003\u0002\u0002\u0002\u0216\u0217\u0003\u0002\u0002\u0002\u0217",
    "\u0218\u0007\u0005\u0002\u0002\u0218q\u0003\u0002\u0002\u0002\u0219",
    "\u021a\u0007V\u0002\u0002\u021a\u021b\u0007K\u0002\u0002\u021bs\u0003",
    "\u0002\u0002\u0002\u021c\u021d\t\u0007\u0002\u0002\u021du\u0003\u0002",
    "\u0002\u0002\u021e\u021f\u0007V\u0002\u0002\u021f\u0220\u0007\u0007",
    "\u0002\u0002\u0220\u0221\t\b\u0002\u0002\u0221w\u0003\u0002\u0002\u0002",
    "\u0222\u0227\u0007V\u0002\u0002\u0223\u0224\u0007\"\u0002\u0002\u0224",
    "\u0226\u0007V\u0002\u0002\u0225\u0223\u0003\u0002\u0002\u0002\u0226",
    "\u0229\u0003\u0002\u0002\u0002\u0227\u0225\u0003\u0002\u0002\u0002\u0227",
    "\u0228\u0003\u0002\u0002\u0002\u0228y\u0003\u0002\u0002\u0002\u0229",
    "\u0227\u0003\u0002\u0002\u0002\u022a\u022f\t\t\u0002\u0002\u022b\u022c",
    "\u0007/\u0002\u0002\u022c\u022e\u0007V\u0002\u0002\u022d\u022b\u0003",
    "\u0002\u0002\u0002\u022e\u0231\u0003\u0002\u0002\u0002\u022f\u022d\u0003",
    "\u0002\u0002\u0002\u022f\u0230\u0003\u0002\u0002\u0002\u0230{\u0003",
    "\u0002\u0002\u0002\u0231\u022f\u0003\u0002\u0002\u0002\u0232\u0237\u0005",
    "z>\u0002\u0233\u0234\u0007\"\u0002\u0002\u0234\u0236\u0005z>\u0002\u0235",
    "\u0233\u0003\u0002\u0002\u0002\u0236\u0239\u0003\u0002\u0002\u0002\u0237",
    "\u0235\u0003\u0002\u0002\u0002\u0237\u0238\u0003\u0002\u0002\u0002\u0238",
    "}\u0003\u0002\u0002\u0002\u0239\u0237\u0003\u0002\u0002\u0002\u023a",
    "\u023c\u0005\u0080A\u0002\u023b\u023a\u0003\u0002\u0002\u0002\u023c",
    "\u023f\u0003\u0002\u0002\u0002\u023d\u023b\u0003\u0002\u0002\u0002\u023d",
    "\u023e\u0003\u0002\u0002\u0002\u023e\u007f\u0003\u0002\u0002\u0002\u023f",
    "\u023d\u0003\u0002\u0002\u0002\u0240\u0245\u0005\u008aF\u0002\u0241",
    "\u0245\u0005\u008cG\u0002\u0242\u0245\u0005\u009eP\u0002\u0243\u0245",
    "\u0005\u0082B\u0002\u0244\u0240\u0003\u0002\u0002\u0002\u0244\u0241",
    "\u0003\u0002\u0002\u0002\u0244\u0242\u0003\u0002\u0002\u0002\u0244\u0243",
    "\u0003\u0002\u0002\u0002\u0245\u0081\u0003\u0002\u0002\u0002\u0246\u0247",
    "\u00078\u0002\u0002\u0247\u0248\u0007V\u0002\u0002\u0248\u024a\u0007",
    "!\u0002\u0002\u0249\u024b\u0005\u0084C\u0002\u024a\u0249\u0003\u0002",
    "\u0002\u0002\u024a\u024b\u0003\u0002\u0002\u0002\u024b\u024c\u0003\u0002",
    "\u0002\u0002\u024c\u024d\u0007#\u0002\u0002\u024d\u0083\u0003\u0002",
    "\u0002\u0002\u024e\u0253\u0005\u0086D\u0002\u024f\u0250\u0007\"\u0002",
    "\u0002\u0250\u0252\u0005\u0086D\u0002\u0251\u024f\u0003\u0002\u0002",
    "\u0002\u0252\u0255\u0003\u0002\u0002\u0002\u0253\u0251\u0003\u0002\u0002",
    "\u0002\u0253\u0254\u0003\u0002\u0002\u0002\u0254\u0085\u0003\u0002\u0002",
    "\u0002\u0255\u0253\u0003\u0002\u0002\u0002\u0256\u0257\u0005\u0088E",
    "\u0002\u0257\u0258\u0007/\u0002\u0002\u0258\u025a\u0003\u0002\u0002",
    "\u0002\u0259\u0256\u0003\u0002\u0002\u0002\u0259\u025a\u0003\u0002\u0002",
    "\u0002\u025a\u025b\u0003\u0002\u0002\u0002\u025b\u025f\u0005z>\u0002",
    "\u025c\u025f\u0005t;\u0002\u025d\u025f\u0005z>\u0002\u025e\u0259\u0003",
    "\u0002\u0002\u0002\u025e\u025c\u0003\u0002\u0002\u0002\u025e\u025d\u0003",
    "\u0002\u0002\u0002\u025f\u0087\u0003\u0002\u0002\u0002\u0260\u0261\t",
    "\n\u0002\u0002\u0261\u0089\u0003\u0002\u0002\u0002\u0262\u0263\u0007",
    "=\u0002\u0002\u0263\u0264\u0007V\u0002\u0002\u0264\u0265\u0007\u0007",
    "\u0002\u0002\u0265\u0268\t\b\u0002\u0002\u0266\u0267\u0007\u001e\u0002",
    "\u0002\u0267\u0269\u0005\u009eP\u0002\u0268\u0266\u0003\u0002\u0002",
    "\u0002\u0268\u0269\u0003\u0002\u0002\u0002\u0269\u008b\u0003\u0002\u0002",
    "\u0002\u026a\u026d\u0005\u008eH\u0002\u026b\u026d\u0005\u0090I\u0002",
    "\u026c\u026a\u0003\u0002\u0002\u0002\u026c\u026b\u0003\u0002\u0002\u0002",
    "\u026d\u008d\u0003\u0002\u0002\u0002\u026e\u026f\u0007>\u0002\u0002",
    "\u026f\u0270\u0007!\u0002\u0002\u0270\u0271\u0005\u0092J\u0002\u0271",
    "\u0272\u0007#\u0002\u0002\u0272\u0273\u0007\u0004\u0002\u0002\u0273",
    "\u0274\u0005~@\u0002\u0274\u027d\u0007\u0005\u0002\u0002\u0275\u0277",
    "\u0007?\u0002\u0002\u0276\u0278\u0005\u008eH\u0002\u0277\u0276\u0003",
    "\u0002\u0002\u0002\u0277\u0278\u0003\u0002\u0002\u0002\u0278\u0279\u0003",
    "\u0002\u0002\u0002\u0279\u027a\u0007\u0004\u0002\u0002\u027a\u027b\u0005",
    "~@\u0002\u027b\u027c\u0007\u0005\u0002\u0002\u027c\u027e\u0003\u0002",
    "\u0002\u0002\u027d\u0275\u0003\u0002\u0002\u0002\u027d\u027e\u0003\u0002",
    "\u0002\u0002\u027e\u008f\u0003\u0002\u0002\u0002\u027f\u0280\u0005z",
    ">\u0002\u0280\u0281\u0007@\u0002\u0002\u0281\u0282\u0007\u0004\u0002",
    "\u0002\u0282\u0283\u0005\u00a8U\u0002\u0283\u0284\u0007\u0005\u0002",
    "\u0002\u0284\u0091\u0003\u0002\u0002\u0002\u0285\u0288\u0005z>\u0002",
    "\u0286\u0288\u0005t;\u0002\u0287\u0285\u0003\u0002\u0002\u0002\u0287",
    "\u0286\u0003\u0002\u0002\u0002\u0288\u028e\u0003\u0002\u0002\u0002\u0289",
    "\u028a\u0005\u00b2Z\u0002\u028a\u028b\u0005\u0092J\u0002\u028b\u028d",
    "\u0003\u0002\u0002\u0002\u028c\u0289\u0003\u0002\u0002\u0002\u028d\u0290",
    "\u0003\u0002\u0002\u0002\u028e\u028c\u0003\u0002\u0002\u0002\u028e\u028f",
    "\u0003\u0002\u0002\u0002\u028f\u0093\u0003\u0002\u0002\u0002\u0290\u028e",
    "\u0003\u0002\u0002\u0002\u0291\u0297\u0005\u0098M\u0002\u0292\u0293",
    "\u0005\u0096L\u0002\u0293\u0294\u0005\u0098M\u0002\u0294\u0296\u0003",
    "\u0002\u0002\u0002\u0295\u0292\u0003\u0002\u0002\u0002\u0296\u0299\u0003",
    "\u0002\u0002\u0002\u0297\u0295\u0003\u0002\u0002\u0002\u0297\u0298\u0003",
    "\u0002\u0002\u0002\u0298\u0095\u0003\u0002\u0002\u0002\u0299\u0297\u0003",
    "\u0002\u0002\u0002\u029a\u029b\t\u000b\u0002\u0002\u029b\u0097\u0003",
    "\u0002\u0002\u0002\u029c\u02a2\u0005\u009cO\u0002\u029d\u029e\u0005",
    "\u009aN\u0002\u029e\u029f\u0005\u009cO\u0002\u029f\u02a1\u0003\u0002",
    "\u0002\u0002\u02a0\u029d\u0003\u0002\u0002\u0002\u02a1\u02a4\u0003\u0002",
    "\u0002\u0002\u02a2\u02a0\u0003\u0002\u0002\u0002\u02a2\u02a3\u0003\u0002",
    "\u0002\u0002\u02a3\u0099\u0003\u0002\u0002\u0002\u02a4\u02a2\u0003\u0002",
    "\u0002\u0002\u02a5\u02a6\t\f\u0002\u0002\u02a6\u009b\u0003\u0002\u0002",
    "\u0002\u02a7\u02af\u0005z>\u0002\u02a8\u02af\u0007Q\u0002\u0002\u02a9",
    "\u02af\u0007R\u0002\u0002\u02aa\u02ab\u0007!\u0002\u0002\u02ab\u02ac",
    "\u0005\u0094K\u0002\u02ac\u02ad\u0007#\u0002\u0002\u02ad\u02af\u0003",
    "\u0002\u0002\u0002\u02ae\u02a7\u0003\u0002\u0002\u0002\u02ae\u02a8\u0003",
    "\u0002\u0002\u0002\u02ae\u02a9\u0003\u0002\u0002\u0002\u02ae\u02aa\u0003",
    "\u0002\u0002\u0002\u02af\u009d\u0003\u0002\u0002\u0002\u02b0\u02b4\u0005",
    "t;\u0002\u02b1\u02b4\u0005\u0094K\u0002\u02b2\u02b4\u0005\u00a0Q\u0002",
    "\u02b3\u02b0\u0003\u0002\u0002\u0002\u02b3\u02b1\u0003\u0002\u0002\u0002",
    "\u02b3\u02b2\u0003\u0002\u0002\u0002\u02b4\u009f\u0003\u0002\u0002\u0002",
    "\u02b5\u02b6\u0005z>\u0002\u02b6\u02b7\u0007\u001e\u0002\u0002\u02b7",
    "\u02b8\u0005\u00a2R\u0002\u02b8\u00a1\u0003\u0002\u0002\u0002\u02b9",
    "\u02c3\u0005z>\u0002\u02ba\u02c3\u0005t;\u0002\u02bb\u02c3\u0005\u0094",
    "K\u0002\u02bc\u02bd\t\b\u0002\u0002\u02bd\u02bf\u0007!\u0002\u0002\u02be",
    "\u02c0\u0005|?\u0002\u02bf\u02be\u0003\u0002\u0002\u0002\u02bf\u02c0",
    "\u0003\u0002\u0002\u0002\u02c0\u02c1\u0003\u0002\u0002\u0002\u02c1\u02c3",
    "\u0007#\u0002\u0002\u02c2\u02b9\u0003\u0002\u0002\u0002\u02c2\u02ba",
    "\u0003\u0002\u0002\u0002\u02c2\u02bb\u0003\u0002\u0002\u0002\u02c2\u02bc",
    "\u0003\u0002\u0002\u0002\u02c3\u00a3\u0003\u0002\u0002\u0002\u02c4\u02c5",
    "\u0007\u0004\u0002\u0002\u02c5\u02c6\u0005\u00a8U\u0002\u02c6\u02c7",
    "\u0007\u0005\u0002\u0002\u02c7\u02cd\u0003\u0002\u0002\u0002\u02c8\u02c9",
    "\u0007\u0004\u0002\u0002\u02c9\u02ca\u0005\u00a6T\u0002\u02ca\u02cb",
    "\u0007\u0005\u0002\u0002\u02cb\u02cd\u0003\u0002\u0002\u0002\u02cc\u02c4",
    "\u0003\u0002\u0002\u0002\u02cc\u02c8\u0003\u0002\u0002\u0002\u02cd\u00a5",
    "\u0003\u0002\u0002\u0002\u02ce\u02cf\u0005~@\u0002\u02cf\u00a7\u0003",
    "\u0002\u0002\u0002\u02d0\u02d2\u0005\u00aaV\u0002\u02d1\u02d0\u0003",
    "\u0002\u0002\u0002\u02d2\u02d3\u0003\u0002\u0002\u0002\u02d3\u02d1\u0003",
    "\u0002\u0002\u0002\u02d3\u02d4\u0003\u0002\u0002\u0002\u02d4\u00a9\u0003",
    "\u0002\u0002\u0002\u02d5\u02d6\u0007F\u0002\u0002\u02d6\u02d7\u0005",
    "\u00acW\u0002\u02d7\u02d8\u0007G\u0002\u0002\u02d8\u02d9\u0005\u00a6",
    "T\u0002\u02d9\u00ab\u0003\u0002\u0002\u0002\u02da\u02df\u0005\u00ae",
    "X\u0002\u02db\u02dc\u0007H\u0002\u0002\u02dc\u02de\u0005\u00aeX\u0002",
    "\u02dd\u02db\u0003\u0002\u0002\u0002\u02de\u02e1\u0003\u0002\u0002\u0002",
    "\u02df\u02dd\u0003\u0002\u0002\u0002\u02df\u02e0\u0003\u0002\u0002\u0002",
    "\u02e0\u00ad\u0003\u0002\u0002\u0002\u02e1\u02df\u0003\u0002\u0002\u0002",
    "\u02e2\u02ef\u0005t;\u0002\u02e3\u02e9\u0007U\u0002\u0002\u02e4\u02e6",
    "\u0007!\u0002\u0002\u02e5\u02e7\u0005z>\u0002\u02e6\u02e5\u0003\u0002",
    "\u0002\u0002\u02e6\u02e7\u0003\u0002\u0002\u0002\u02e7\u02e8\u0003\u0002",
    "\u0002\u0002\u02e8\u02ea\u0007#\u0002\u0002\u02e9\u02e4\u0003\u0002",
    "\u0002\u0002\u02e9\u02ea\u0003\u0002\u0002\u0002\u02ea\u02ef\u0003\u0002",
    "\u0002\u0002\u02eb\u02ec\u0007V\u0002\u0002\u02ec\u02ed\u0007\u0007",
    "\u0002\u0002\u02ed\u02ef\t\b\u0002\u0002\u02ee\u02e2\u0003\u0002\u0002",
    "\u0002\u02ee\u02e3\u0003\u0002\u0002\u0002\u02ee\u02eb\u0003\u0002\u0002",
    "\u0002\u02ef\u00af\u0003\u0002\u0002\u0002\u02f0\u02f1\t\r\u0002\u0002",
    "\u02f1\u00b1\u0003\u0002\u0002\u0002\u02f2\u02f3\t\u000e\u0002\u0002",
    "\u02f3\u00b3\u0003\u0002\u0002\u0002I\u00b7\u00b9\u00c3\u00c7\u00ca",
    "\u00d2\u00d9\u00e0\u00ee\u00f2\u00fa\u0113\u0118\u0121\u0125\u0130\u0137",
    "\u013d\u014c\u0159\u015d\u0161\u016c\u0175\u017b\u0188\u018b\u0197\u019b",
    "\u019e\u01a1\u01ac\u01b1\u01b4\u01b7\u01c4\u01ca\u01cd\u01e1\u01ef\u01f5",
    "\u01fa\u0203\u0215\u0227\u022f\u0237\u023d\u0244\u024a\u0253\u0259\u025e",
    "\u0268\u026c\u0277\u027d\u0287\u028e\u0297\u02a2\u02ae\u02b3\u02bf\u02c2",
    "\u02cc\u02d3\u02df\u02e6\u02e9\u02ee"].join("");


var atn = new antlr4.atn.ATNDeserializer().deserialize(serializedATN);

var decisionsToDFA = atn.decisionToState.map( function(ds, index) { return new antlr4.dfa.DFA(ds, index); });

var sharedContextCache = new antlr4.PredictionContextCache();

var literalNames = [ null, "'entity'", "'{'", "'}'", "'enum'", "':'", "'accessControl'", 
                     "'always'", "'employee'", "'forbidden'", "'read'", 
                     "'create'", "'update'", "'delete'", "'list'", "'success'", 
                     "'failure'", "'authorization'", "'withError'", "'storedProps'", 
                     "'@'", "'searchable'", "'searchableKeyword'", "'unique'", 
                     "'identity'", "'validation'", "'regexp'", "'computedProps'", 
                     "'='", "'index'", "'query'", "'('", "','", "')'", "'acl:'", 
                     "'cache'", "'ttl'", "'seconds'", "'never'", "'key'", 
                     "'rawQuery'", "'where'", "'and'", "'or'", "'self.'", 
                     "'.'", "'>'", "'>='", "'<'", "'<='", "'isEmpty'", "'notEmpty'", 
                     "'params'", "'orderBy'", "'~'", "'ViewerContext'", 
                     "'Query'", "'Input'", "'Param'", "'let'", "'if'", "'else'", 
                     "'match'", "'+'", "'-'", "'*'", "'/'", "'%'", "'case'", 
                     "'=>'", "'|'", "'^'", "'=='", null, "'null'", "'self'", 
                     null, null, null, null, null, null, null, null, null, 
                     null, "';'" ];

var symbolicNames = [ null, null, null, null, null, null, null, null, null, 
                      null, null, null, null, null, null, null, null, null, 
                      null, null, null, null, null, null, null, null, null, 
                      null, null, null, null, null, null, null, null, null, 
                      null, null, null, null, null, null, null, null, null, 
                      null, null, null, null, null, null, null, null, null, 
                      null, null, null, null, null, null, null, null, null, 
                      null, null, null, null, null, null, null, null, null, 
                      null, "QuerySort", "Null", "Self", "BooleanLiteral", 
                      "StringLiteral", "MultiLineString", "IntegerLiteral", 
                      "FloatingPointLiteral", "CollectionTypeid", "NullableTypeid", 
                      "Typeid", "Varid", "WS", "Semi", "Paren", "Delim", 
                      "Comment", "Regexp" ];

var ruleNames =  [ "main", "entityDef", "entityBlock", "enumerationDef", 
                   "enumerationValue", "accessControlDef", "accessControl", 
                   "accessControlValue", "accessControlType", "accessControlBlock", 
                   "accessControlSuccess", "accessControlFailure", "accessControlAuthorization", 
                   "accessControlLambda", "accessControlAuthorizationValue", 
                   "propsDef", "storedProp", "metaPropertyDef", "metaPropertyValidationDef", 
                   "metaPropertyValidation", "metaPropertyValidationRegexp", 
                   "metaPropertyValidationFailure", "computedDef", "computedProp", 
                   "computedPropType", "metaComputedProp", "computedPropRel", 
                   "indexDef", "indexBlock", "indexField", "queriesDef", 
                   "queryBlock", "queryAccessControl", "queryOperation", 
                   "queryOperationBlock", "queryCacheBlock", "queryCacheStrategy", 
                   "queryCacheStrategyTTL", "queryCacheStrategyTTLOption", 
                   "queryCacheStrategyNever", "queryCacheKey", "queryCacheKeyValue", 
                   "queryOperationRaw", "queryOperationRawBlock", "queryWhereBlock", 
                   "queryWhereGroup", "queryWhereJunction", "queryWhere", 
                   "queryWhereField", "queryWhereValue", "queryOperator", 
                   "queryRawBlock", "queryRawParamsBlock", "queryRawParamBlock", 
                   "queryRawSqlBlock", "queryOrderBlock", "queryFieldSort", 
                   "literal", "binding", "varIds", "qualId", "qualIds", 
                   "exprs", "expr", "lambdaExpr", "lambdaArgs", "lambdaArg", 
                   "lambdaArgPrefix", "variableDeclExpr", "conditionalExpr", 
                   "ifExpr", "matchExpr", "logicExpr", "arithmeticExpr", 
                   "arithmeticExprOperator", "arithmeticTerm", "arithmeticTermOperator", 
                   "arithmeticFactor", "simpleExpr", "assignExpr", "assignValueExpr", 
                   "blockExpr", "block", "caseClauses", "caseClause", "patternExpr", 
                   "pattern", "arithmeticOperator", "logicOperator" ];

function entityParser (input) {
	antlr4.Parser.call(this, input);
    this._interp = new antlr4.atn.ParserATNSimulator(this, atn, decisionsToDFA, sharedContextCache);
    this.ruleNames = ruleNames;
    this.literalNames = literalNames;
    this.symbolicNames = symbolicNames;
    return this;
}

entityParser.prototype = Object.create(antlr4.Parser.prototype);
entityParser.prototype.constructor = entityParser;

Object.defineProperty(entityParser.prototype, "atn", {
	get : function() {
		return atn;
	}
});

entityParser.EOF = antlr4.Token.EOF;
entityParser.T__0 = 1;
entityParser.T__1 = 2;
entityParser.T__2 = 3;
entityParser.T__3 = 4;
entityParser.T__4 = 5;
entityParser.T__5 = 6;
entityParser.T__6 = 7;
entityParser.T__7 = 8;
entityParser.T__8 = 9;
entityParser.T__9 = 10;
entityParser.T__10 = 11;
entityParser.T__11 = 12;
entityParser.T__12 = 13;
entityParser.T__13 = 14;
entityParser.T__14 = 15;
entityParser.T__15 = 16;
entityParser.T__16 = 17;
entityParser.T__17 = 18;
entityParser.T__18 = 19;
entityParser.T__19 = 20;
entityParser.T__20 = 21;
entityParser.T__21 = 22;
entityParser.T__22 = 23;
entityParser.T__23 = 24;
entityParser.T__24 = 25;
entityParser.T__25 = 26;
entityParser.T__26 = 27;
entityParser.T__27 = 28;
entityParser.T__28 = 29;
entityParser.T__29 = 30;
entityParser.T__30 = 31;
entityParser.T__31 = 32;
entityParser.T__32 = 33;
entityParser.T__33 = 34;
entityParser.T__34 = 35;
entityParser.T__35 = 36;
entityParser.T__36 = 37;
entityParser.T__37 = 38;
entityParser.T__38 = 39;
entityParser.T__39 = 40;
entityParser.T__40 = 41;
entityParser.T__41 = 42;
entityParser.T__42 = 43;
entityParser.T__43 = 44;
entityParser.T__44 = 45;
entityParser.T__45 = 46;
entityParser.T__46 = 47;
entityParser.T__47 = 48;
entityParser.T__48 = 49;
entityParser.T__49 = 50;
entityParser.T__50 = 51;
entityParser.T__51 = 52;
entityParser.T__52 = 53;
entityParser.T__53 = 54;
entityParser.T__54 = 55;
entityParser.T__55 = 56;
entityParser.T__56 = 57;
entityParser.T__57 = 58;
entityParser.T__58 = 59;
entityParser.T__59 = 60;
entityParser.T__60 = 61;
entityParser.T__61 = 62;
entityParser.T__62 = 63;
entityParser.T__63 = 64;
entityParser.T__64 = 65;
entityParser.T__65 = 66;
entityParser.T__66 = 67;
entityParser.T__67 = 68;
entityParser.T__68 = 69;
entityParser.T__69 = 70;
entityParser.T__70 = 71;
entityParser.T__71 = 72;
entityParser.QuerySort = 73;
entityParser.Null = 74;
entityParser.Self = 75;
entityParser.BooleanLiteral = 76;
entityParser.StringLiteral = 77;
entityParser.MultiLineString = 78;
entityParser.IntegerLiteral = 79;
entityParser.FloatingPointLiteral = 80;
entityParser.CollectionTypeid = 81;
entityParser.NullableTypeid = 82;
entityParser.Typeid = 83;
entityParser.Varid = 84;
entityParser.WS = 85;
entityParser.Semi = 86;
entityParser.Paren = 87;
entityParser.Delim = 88;
entityParser.Comment = 89;
entityParser.Regexp = 90;

entityParser.RULE_main = 0;
entityParser.RULE_entityDef = 1;
entityParser.RULE_entityBlock = 2;
entityParser.RULE_enumerationDef = 3;
entityParser.RULE_enumerationValue = 4;
entityParser.RULE_accessControlDef = 5;
entityParser.RULE_accessControl = 6;
entityParser.RULE_accessControlValue = 7;
entityParser.RULE_accessControlType = 8;
entityParser.RULE_accessControlBlock = 9;
entityParser.RULE_accessControlSuccess = 10;
entityParser.RULE_accessControlFailure = 11;
entityParser.RULE_accessControlAuthorization = 12;
entityParser.RULE_accessControlLambda = 13;
entityParser.RULE_accessControlAuthorizationValue = 14;
entityParser.RULE_propsDef = 15;
entityParser.RULE_storedProp = 16;
entityParser.RULE_metaPropertyDef = 17;
entityParser.RULE_metaPropertyValidationDef = 18;
entityParser.RULE_metaPropertyValidation = 19;
entityParser.RULE_metaPropertyValidationRegexp = 20;
entityParser.RULE_metaPropertyValidationFailure = 21;
entityParser.RULE_computedDef = 22;
entityParser.RULE_computedProp = 23;
entityParser.RULE_computedPropType = 24;
entityParser.RULE_metaComputedProp = 25;
entityParser.RULE_computedPropRel = 26;
entityParser.RULE_indexDef = 27;
entityParser.RULE_indexBlock = 28;
entityParser.RULE_indexField = 29;
entityParser.RULE_queriesDef = 30;
entityParser.RULE_queryBlock = 31;
entityParser.RULE_queryAccessControl = 32;
entityParser.RULE_queryOperation = 33;
entityParser.RULE_queryOperationBlock = 34;
entityParser.RULE_queryCacheBlock = 35;
entityParser.RULE_queryCacheStrategy = 36;
entityParser.RULE_queryCacheStrategyTTL = 37;
entityParser.RULE_queryCacheStrategyTTLOption = 38;
entityParser.RULE_queryCacheStrategyNever = 39;
entityParser.RULE_queryCacheKey = 40;
entityParser.RULE_queryCacheKeyValue = 41;
entityParser.RULE_queryOperationRaw = 42;
entityParser.RULE_queryOperationRawBlock = 43;
entityParser.RULE_queryWhereBlock = 44;
entityParser.RULE_queryWhereGroup = 45;
entityParser.RULE_queryWhereJunction = 46;
entityParser.RULE_queryWhere = 47;
entityParser.RULE_queryWhereField = 48;
entityParser.RULE_queryWhereValue = 49;
entityParser.RULE_queryOperator = 50;
entityParser.RULE_queryRawBlock = 51;
entityParser.RULE_queryRawParamsBlock = 52;
entityParser.RULE_queryRawParamBlock = 53;
entityParser.RULE_queryRawSqlBlock = 54;
entityParser.RULE_queryOrderBlock = 55;
entityParser.RULE_queryFieldSort = 56;
entityParser.RULE_literal = 57;
entityParser.RULE_binding = 58;
entityParser.RULE_varIds = 59;
entityParser.RULE_qualId = 60;
entityParser.RULE_qualIds = 61;
entityParser.RULE_exprs = 62;
entityParser.RULE_expr = 63;
entityParser.RULE_lambdaExpr = 64;
entityParser.RULE_lambdaArgs = 65;
entityParser.RULE_lambdaArg = 66;
entityParser.RULE_lambdaArgPrefix = 67;
entityParser.RULE_variableDeclExpr = 68;
entityParser.RULE_conditionalExpr = 69;
entityParser.RULE_ifExpr = 70;
entityParser.RULE_matchExpr = 71;
entityParser.RULE_logicExpr = 72;
entityParser.RULE_arithmeticExpr = 73;
entityParser.RULE_arithmeticExprOperator = 74;
entityParser.RULE_arithmeticTerm = 75;
entityParser.RULE_arithmeticTermOperator = 76;
entityParser.RULE_arithmeticFactor = 77;
entityParser.RULE_simpleExpr = 78;
entityParser.RULE_assignExpr = 79;
entityParser.RULE_assignValueExpr = 80;
entityParser.RULE_blockExpr = 81;
entityParser.RULE_block = 82;
entityParser.RULE_caseClauses = 83;
entityParser.RULE_caseClause = 84;
entityParser.RULE_patternExpr = 85;
entityParser.RULE_pattern = 86;
entityParser.RULE_arithmeticOperator = 87;
entityParser.RULE_logicOperator = 88;

function MainContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_main;
    return this;
}

MainContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
MainContext.prototype.constructor = MainContext;

MainContext.prototype.queriesDef = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(QueriesDefContext);
    } else {
        return this.getTypedRuleContext(QueriesDefContext,i);
    }
};

MainContext.prototype.enumerationDef = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(EnumerationDefContext);
    } else {
        return this.getTypedRuleContext(EnumerationDefContext,i);
    }
};

MainContext.prototype.entityDef = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(EntityDefContext);
    } else {
        return this.getTypedRuleContext(EntityDefContext,i);
    }
};

MainContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterMain(this);
	}
};

MainContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitMain(this);
	}
};

MainContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitMain(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.MainContext = MainContext;

entityParser.prototype.main = function() {

    var localctx = new MainContext(this, this._ctx, this.state);
    this.enterRule(localctx, 0, entityParser.RULE_main);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 183;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        while(_la===entityParser.T__0 || _la===entityParser.T__3 || _la===entityParser.T__33) {
            this.state = 181;
            this._errHandler.sync(this);
            switch(this._input.LA(1)) {
            case entityParser.T__33:
                this.state = 178;
                this.queriesDef();
                break;
            case entityParser.T__3:
                this.state = 179;
                this.enumerationDef();
                break;
            case entityParser.T__0:
                this.state = 180;
                this.entityDef();
                break;
            default:
                throw new antlr4.error.NoViableAltException(this);
            }
            this.state = 185;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function EntityDefContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_entityDef;
    return this;
}

EntityDefContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
EntityDefContext.prototype.constructor = EntityDefContext;

EntityDefContext.prototype.Typeid = function() {
    return this.getToken(entityParser.Typeid, 0);
};

EntityDefContext.prototype.entityBlock = function() {
    return this.getTypedRuleContext(EntityBlockContext,0);
};

EntityDefContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterEntityDef(this);
	}
};

EntityDefContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitEntityDef(this);
	}
};

EntityDefContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitEntityDef(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.EntityDefContext = EntityDefContext;

entityParser.prototype.entityDef = function() {

    var localctx = new EntityDefContext(this, this._ctx, this.state);
    this.enterRule(localctx, 2, entityParser.RULE_entityDef);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 186;
        this.match(entityParser.T__0);
        this.state = 187;
        this.match(entityParser.Typeid);
        this.state = 188;
        this.match(entityParser.T__1);
        this.state = 189;
        this.entityBlock();
        this.state = 190;
        this.match(entityParser.T__2);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function EntityBlockContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_entityBlock;
    return this;
}

EntityBlockContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
EntityBlockContext.prototype.constructor = EntityBlockContext;

EntityBlockContext.prototype.propsDef = function() {
    return this.getTypedRuleContext(PropsDefContext,0);
};

EntityBlockContext.prototype.accessControlDef = function() {
    return this.getTypedRuleContext(AccessControlDefContext,0);
};

EntityBlockContext.prototype.indexDef = function() {
    return this.getTypedRuleContext(IndexDefContext,0);
};

EntityBlockContext.prototype.computedDef = function() {
    return this.getTypedRuleContext(ComputedDefContext,0);
};

EntityBlockContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterEntityBlock(this);
	}
};

EntityBlockContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitEntityBlock(this);
	}
};

EntityBlockContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitEntityBlock(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.EntityBlockContext = EntityBlockContext;

entityParser.prototype.entityBlock = function() {

    var localctx = new EntityBlockContext(this, this._ctx, this.state);
    this.enterRule(localctx, 4, entityParser.RULE_entityBlock);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 193;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(_la===entityParser.T__5) {
            this.state = 192;
            this.accessControlDef();
        }

        this.state = 195;
        this.propsDef();
        this.state = 197;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(_la===entityParser.T__28) {
            this.state = 196;
            this.indexDef();
        }

        this.state = 200;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(_la===entityParser.T__26) {
            this.state = 199;
            this.computedDef();
        }

    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function EnumerationDefContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_enumerationDef;
    return this;
}

EnumerationDefContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
EnumerationDefContext.prototype.constructor = EnumerationDefContext;

EnumerationDefContext.prototype.Typeid = function() {
    return this.getToken(entityParser.Typeid, 0);
};

EnumerationDefContext.prototype.enumerationValue = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(EnumerationValueContext);
    } else {
        return this.getTypedRuleContext(EnumerationValueContext,i);
    }
};

EnumerationDefContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterEnumerationDef(this);
	}
};

EnumerationDefContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitEnumerationDef(this);
	}
};

EnumerationDefContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitEnumerationDef(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.EnumerationDefContext = EnumerationDefContext;

entityParser.prototype.enumerationDef = function() {

    var localctx = new EnumerationDefContext(this, this._ctx, this.state);
    this.enterRule(localctx, 6, entityParser.RULE_enumerationDef);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 202;
        this.match(entityParser.T__3);
        this.state = 203;
        this.match(entityParser.Typeid);
        this.state = 204;
        this.match(entityParser.T__1);
        this.state = 206; 
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        do {
            this.state = 205;
            this.enumerationValue();
            this.state = 208; 
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        } while(_la===entityParser.Typeid || _la===entityParser.Varid);
        this.state = 210;
        this.match(entityParser.T__2);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function EnumerationValueContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_enumerationValue;
    return this;
}

EnumerationValueContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
EnumerationValueContext.prototype.constructor = EnumerationValueContext;

EnumerationValueContext.prototype.Typeid = function() {
    return this.getToken(entityParser.Typeid, 0);
};

EnumerationValueContext.prototype.Varid = function() {
    return this.getToken(entityParser.Varid, 0);
};

EnumerationValueContext.prototype.StringLiteral = function() {
    return this.getToken(entityParser.StringLiteral, 0);
};

EnumerationValueContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterEnumerationValue(this);
	}
};

EnumerationValueContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitEnumerationValue(this);
	}
};

EnumerationValueContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitEnumerationValue(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.EnumerationValueContext = EnumerationValueContext;

entityParser.prototype.enumerationValue = function() {

    var localctx = new EnumerationValueContext(this, this._ctx, this.state);
    this.enterRule(localctx, 8, entityParser.RULE_enumerationValue);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 212;
        _la = this._input.LA(1);
        if(!(_la===entityParser.Typeid || _la===entityParser.Varid)) {
        this._errHandler.recoverInline(this);
        }
        else {
        	this._errHandler.reportMatch(this);
            this.consume();
        }
        this.state = 215;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(_la===entityParser.T__4) {
            this.state = 213;
            this.match(entityParser.T__4);
            this.state = 214;
            this.match(entityParser.StringLiteral);
        }

    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function AccessControlDefContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_accessControlDef;
    return this;
}

AccessControlDefContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
AccessControlDefContext.prototype.constructor = AccessControlDefContext;

AccessControlDefContext.prototype.accessControl = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(AccessControlContext);
    } else {
        return this.getTypedRuleContext(AccessControlContext,i);
    }
};

AccessControlDefContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterAccessControlDef(this);
	}
};

AccessControlDefContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitAccessControlDef(this);
	}
};

AccessControlDefContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitAccessControlDef(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.AccessControlDefContext = AccessControlDefContext;

entityParser.prototype.accessControlDef = function() {

    var localctx = new AccessControlDefContext(this, this._ctx, this.state);
    this.enterRule(localctx, 10, entityParser.RULE_accessControlDef);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 217;
        this.match(entityParser.T__5);
        this.state = 218;
        this.match(entityParser.T__1);
        this.state = 220; 
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        do {
            this.state = 219;
            this.accessControl();
            this.state = 222; 
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        } while((((_la) & ~0x1f) == 0 && ((1 << _la) & ((1 << entityParser.T__9) | (1 << entityParser.T__10) | (1 << entityParser.T__11) | (1 << entityParser.T__12) | (1 << entityParser.T__13))) !== 0));
        this.state = 224;
        this.match(entityParser.T__2);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function AccessControlContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_accessControl;
    return this;
}

AccessControlContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
AccessControlContext.prototype.constructor = AccessControlContext;

AccessControlContext.prototype.accessControlType = function() {
    return this.getTypedRuleContext(AccessControlTypeContext,0);
};

AccessControlContext.prototype.accessControlValue = function() {
    return this.getTypedRuleContext(AccessControlValueContext,0);
};

AccessControlContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterAccessControl(this);
	}
};

AccessControlContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitAccessControl(this);
	}
};

AccessControlContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitAccessControl(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.AccessControlContext = AccessControlContext;

entityParser.prototype.accessControl = function() {

    var localctx = new AccessControlContext(this, this._ctx, this.state);
    this.enterRule(localctx, 12, entityParser.RULE_accessControl);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 226;
        this.accessControlType();
        this.state = 227;
        this.accessControlValue();
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function AccessControlValueContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_accessControlValue;
    return this;
}

AccessControlValueContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
AccessControlValueContext.prototype.constructor = AccessControlValueContext;

AccessControlValueContext.prototype.accessControlBlock = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(AccessControlBlockContext);
    } else {
        return this.getTypedRuleContext(AccessControlBlockContext,i);
    }
};

AccessControlValueContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterAccessControlValue(this);
	}
};

AccessControlValueContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitAccessControlValue(this);
	}
};

AccessControlValueContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitAccessControlValue(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.AccessControlValueContext = AccessControlValueContext;

entityParser.prototype.accessControlValue = function() {

    var localctx = new AccessControlValueContext(this, this._ctx, this.state);
    this.enterRule(localctx, 14, entityParser.RULE_accessControlValue);
    var _la = 0; // Token type
    try {
        this.state = 240;
        this._errHandler.sync(this);
        switch(this._input.LA(1)) {
        case entityParser.T__6:
            this.enterOuterAlt(localctx, 1);
            this.state = 229;
            this.match(entityParser.T__6);
            break;
        case entityParser.T__7:
            this.enterOuterAlt(localctx, 2);
            this.state = 230;
            this.match(entityParser.T__7);
            break;
        case entityParser.T__8:
            this.enterOuterAlt(localctx, 3);
            this.state = 231;
            this.match(entityParser.T__8);
            break;
        case entityParser.T__1:
            this.enterOuterAlt(localctx, 4);
            this.state = 232;
            this.match(entityParser.T__1);
            this.state = 234; 
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            do {
                this.state = 233;
                this.accessControlBlock();
                this.state = 236; 
                this._errHandler.sync(this);
                _la = this._input.LA(1);
            } while((((_la) & ~0x1f) == 0 && ((1 << _la) & ((1 << entityParser.T__14) | (1 << entityParser.T__15) | (1 << entityParser.T__16))) !== 0) || _la===entityParser.T__53);
            this.state = 238;
            this.match(entityParser.T__2);
            break;
        default:
            throw new antlr4.error.NoViableAltException(this);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function AccessControlTypeContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_accessControlType;
    return this;
}

AccessControlTypeContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
AccessControlTypeContext.prototype.constructor = AccessControlTypeContext;


AccessControlTypeContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterAccessControlType(this);
	}
};

AccessControlTypeContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitAccessControlType(this);
	}
};

AccessControlTypeContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitAccessControlType(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.AccessControlTypeContext = AccessControlTypeContext;

entityParser.prototype.accessControlType = function() {

    var localctx = new AccessControlTypeContext(this, this._ctx, this.state);
    this.enterRule(localctx, 16, entityParser.RULE_accessControlType);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 242;
        _la = this._input.LA(1);
        if(!((((_la) & ~0x1f) == 0 && ((1 << _la) & ((1 << entityParser.T__9) | (1 << entityParser.T__10) | (1 << entityParser.T__11) | (1 << entityParser.T__12) | (1 << entityParser.T__13))) !== 0))) {
        this._errHandler.recoverInline(this);
        }
        else {
        	this._errHandler.reportMatch(this);
            this.consume();
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function AccessControlBlockContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_accessControlBlock;
    return this;
}

AccessControlBlockContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
AccessControlBlockContext.prototype.constructor = AccessControlBlockContext;

AccessControlBlockContext.prototype.accessControlSuccess = function() {
    return this.getTypedRuleContext(AccessControlSuccessContext,0);
};

AccessControlBlockContext.prototype.accessControlFailure = function() {
    return this.getTypedRuleContext(AccessControlFailureContext,0);
};

AccessControlBlockContext.prototype.accessControlAuthorization = function() {
    return this.getTypedRuleContext(AccessControlAuthorizationContext,0);
};

AccessControlBlockContext.prototype.accessControlLambda = function() {
    return this.getTypedRuleContext(AccessControlLambdaContext,0);
};

AccessControlBlockContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterAccessControlBlock(this);
	}
};

AccessControlBlockContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitAccessControlBlock(this);
	}
};

AccessControlBlockContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitAccessControlBlock(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.AccessControlBlockContext = AccessControlBlockContext;

entityParser.prototype.accessControlBlock = function() {

    var localctx = new AccessControlBlockContext(this, this._ctx, this.state);
    this.enterRule(localctx, 18, entityParser.RULE_accessControlBlock);
    try {
        this.state = 248;
        this._errHandler.sync(this);
        switch(this._input.LA(1)) {
        case entityParser.T__14:
            this.enterOuterAlt(localctx, 1);
            this.state = 244;
            this.accessControlSuccess();
            break;
        case entityParser.T__15:
            this.enterOuterAlt(localctx, 2);
            this.state = 245;
            this.accessControlFailure();
            break;
        case entityParser.T__16:
            this.enterOuterAlt(localctx, 3);
            this.state = 246;
            this.accessControlAuthorization();
            break;
        case entityParser.T__53:
            this.enterOuterAlt(localctx, 4);
            this.state = 247;
            this.accessControlLambda();
            break;
        default:
            throw new antlr4.error.NoViableAltException(this);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function AccessControlSuccessContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_accessControlSuccess;
    return this;
}

AccessControlSuccessContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
AccessControlSuccessContext.prototype.constructor = AccessControlSuccessContext;

AccessControlSuccessContext.prototype.StringLiteral = function() {
    return this.getToken(entityParser.StringLiteral, 0);
};

AccessControlSuccessContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterAccessControlSuccess(this);
	}
};

AccessControlSuccessContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitAccessControlSuccess(this);
	}
};

AccessControlSuccessContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitAccessControlSuccess(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.AccessControlSuccessContext = AccessControlSuccessContext;

entityParser.prototype.accessControlSuccess = function() {

    var localctx = new AccessControlSuccessContext(this, this._ctx, this.state);
    this.enterRule(localctx, 20, entityParser.RULE_accessControlSuccess);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 250;
        this.match(entityParser.T__14);
        this.state = 251;
        this.match(entityParser.T__4);
        this.state = 252;
        this.match(entityParser.StringLiteral);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function AccessControlFailureContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_accessControlFailure;
    return this;
}

AccessControlFailureContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
AccessControlFailureContext.prototype.constructor = AccessControlFailureContext;

AccessControlFailureContext.prototype.StringLiteral = function() {
    return this.getToken(entityParser.StringLiteral, 0);
};

AccessControlFailureContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterAccessControlFailure(this);
	}
};

AccessControlFailureContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitAccessControlFailure(this);
	}
};

AccessControlFailureContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitAccessControlFailure(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.AccessControlFailureContext = AccessControlFailureContext;

entityParser.prototype.accessControlFailure = function() {

    var localctx = new AccessControlFailureContext(this, this._ctx, this.state);
    this.enterRule(localctx, 22, entityParser.RULE_accessControlFailure);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 254;
        this.match(entityParser.T__15);
        this.state = 255;
        this.match(entityParser.T__4);
        this.state = 256;
        this.match(entityParser.StringLiteral);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function AccessControlAuthorizationContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_accessControlAuthorization;
    return this;
}

AccessControlAuthorizationContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
AccessControlAuthorizationContext.prototype.constructor = AccessControlAuthorizationContext;

AccessControlAuthorizationContext.prototype.accessControlAuthorizationValue = function() {
    return this.getTypedRuleContext(AccessControlAuthorizationValueContext,0);
};

AccessControlAuthorizationContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterAccessControlAuthorization(this);
	}
};

AccessControlAuthorizationContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitAccessControlAuthorization(this);
	}
};

AccessControlAuthorizationContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitAccessControlAuthorization(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.AccessControlAuthorizationContext = AccessControlAuthorizationContext;

entityParser.prototype.accessControlAuthorization = function() {

    var localctx = new AccessControlAuthorizationContext(this, this._ctx, this.state);
    this.enterRule(localctx, 24, entityParser.RULE_accessControlAuthorization);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 258;
        this.match(entityParser.T__16);
        this.state = 259;
        this.match(entityParser.T__4);
        this.state = 260;
        this.accessControlAuthorizationValue();
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function AccessControlLambdaContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_accessControlLambda;
    return this;
}

AccessControlLambdaContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
AccessControlLambdaContext.prototype.constructor = AccessControlLambdaContext;

AccessControlLambdaContext.prototype.lambdaExpr = function() {
    return this.getTypedRuleContext(LambdaExprContext,0);
};

AccessControlLambdaContext.prototype.StringLiteral = function() {
    return this.getToken(entityParser.StringLiteral, 0);
};

AccessControlLambdaContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterAccessControlLambda(this);
	}
};

AccessControlLambdaContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitAccessControlLambda(this);
	}
};

AccessControlLambdaContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitAccessControlLambda(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.AccessControlLambdaContext = AccessControlLambdaContext;

entityParser.prototype.accessControlLambda = function() {

    var localctx = new AccessControlLambdaContext(this, this._ctx, this.state);
    this.enterRule(localctx, 26, entityParser.RULE_accessControlLambda);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 262;
        this.lambdaExpr();
        this.state = 263;
        this.match(entityParser.T__17);
        this.state = 264;
        this.match(entityParser.StringLiteral);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function AccessControlAuthorizationValueContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_accessControlAuthorizationValue;
    return this;
}

AccessControlAuthorizationValueContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
AccessControlAuthorizationValueContext.prototype.constructor = AccessControlAuthorizationValueContext;

AccessControlAuthorizationValueContext.prototype.BooleanLiteral = function() {
    return this.getToken(entityParser.BooleanLiteral, 0);
};

AccessControlAuthorizationValueContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterAccessControlAuthorizationValue(this);
	}
};

AccessControlAuthorizationValueContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitAccessControlAuthorizationValue(this);
	}
};

AccessControlAuthorizationValueContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitAccessControlAuthorizationValue(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.AccessControlAuthorizationValueContext = AccessControlAuthorizationValueContext;

entityParser.prototype.accessControlAuthorizationValue = function() {

    var localctx = new AccessControlAuthorizationValueContext(this, this._ctx, this.state);
    this.enterRule(localctx, 28, entityParser.RULE_accessControlAuthorizationValue);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 266;
        this.match(entityParser.BooleanLiteral);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function PropsDefContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_propsDef;
    return this;
}

PropsDefContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
PropsDefContext.prototype.constructor = PropsDefContext;

PropsDefContext.prototype.storedProp = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(StoredPropContext);
    } else {
        return this.getTypedRuleContext(StoredPropContext,i);
    }
};

PropsDefContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterPropsDef(this);
	}
};

PropsDefContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitPropsDef(this);
	}
};

PropsDefContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitPropsDef(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.PropsDefContext = PropsDefContext;

entityParser.prototype.propsDef = function() {

    var localctx = new PropsDefContext(this, this._ctx, this.state);
    this.enterRule(localctx, 30, entityParser.RULE_propsDef);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 268;
        this.match(entityParser.T__18);
        this.state = 269;
        this.match(entityParser.T__1);
        this.state = 271; 
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        do {
            this.state = 270;
            this.storedProp();
            this.state = 273; 
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        } while(_la===entityParser.T__19 || _la===entityParser.Varid);
        this.state = 275;
        this.match(entityParser.T__2);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function StoredPropContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_storedProp;
    return this;
}

StoredPropContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
StoredPropContext.prototype.constructor = StoredPropContext;

StoredPropContext.prototype.Varid = function() {
    return this.getToken(entityParser.Varid, 0);
};

StoredPropContext.prototype.Typeid = function() {
    return this.getToken(entityParser.Typeid, 0);
};

StoredPropContext.prototype.metaPropertyDef = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(MetaPropertyDefContext);
    } else {
        return this.getTypedRuleContext(MetaPropertyDefContext,i);
    }
};

StoredPropContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterStoredProp(this);
	}
};

StoredPropContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitStoredProp(this);
	}
};

StoredPropContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitStoredProp(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.StoredPropContext = StoredPropContext;

entityParser.prototype.storedProp = function() {

    var localctx = new StoredPropContext(this, this._ctx, this.state);
    this.enterRule(localctx, 32, entityParser.RULE_storedProp);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 278;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(_la===entityParser.T__19) {
            this.state = 277;
            this.match(entityParser.T__19);
        }

        this.state = 280;
        this.match(entityParser.Varid);
        this.state = 281;
        this.match(entityParser.T__4);
        this.state = 282;
        this.match(entityParser.Typeid);
        this.state = 291;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(_la===entityParser.T__1) {
            this.state = 283;
            this.match(entityParser.T__1);
            this.state = 285; 
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            do {
                this.state = 284;
                this.metaPropertyDef();
                this.state = 287; 
                this._errHandler.sync(this);
                _la = this._input.LA(1);
            } while((((_la) & ~0x1f) == 0 && ((1 << _la) & ((1 << entityParser.T__20) | (1 << entityParser.T__21) | (1 << entityParser.T__22) | (1 << entityParser.T__23) | (1 << entityParser.T__24))) !== 0));
            this.state = 289;
            this.match(entityParser.T__2);
        }

    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function MetaPropertyDefContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_metaPropertyDef;
    return this;
}

MetaPropertyDefContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
MetaPropertyDefContext.prototype.constructor = MetaPropertyDefContext;

MetaPropertyDefContext.prototype.metaPropertyValidationDef = function() {
    return this.getTypedRuleContext(MetaPropertyValidationDefContext,0);
};

MetaPropertyDefContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterMetaPropertyDef(this);
	}
};

MetaPropertyDefContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitMetaPropertyDef(this);
	}
};

MetaPropertyDefContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitMetaPropertyDef(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.MetaPropertyDefContext = MetaPropertyDefContext;

entityParser.prototype.metaPropertyDef = function() {

    var localctx = new MetaPropertyDefContext(this, this._ctx, this.state);
    this.enterRule(localctx, 34, entityParser.RULE_metaPropertyDef);
    try {
        this.state = 302;
        this._errHandler.sync(this);
        switch(this._input.LA(1)) {
        case entityParser.T__20:
            this.enterOuterAlt(localctx, 1);
            this.state = 293;
            this.match(entityParser.T__20);
            break;
        case entityParser.T__21:
            this.enterOuterAlt(localctx, 2);
            this.state = 294;
            this.match(entityParser.T__21);
            break;
        case entityParser.T__22:
            this.enterOuterAlt(localctx, 3);
            this.state = 295;
            this.match(entityParser.T__22);
            break;
        case entityParser.T__23:
            this.enterOuterAlt(localctx, 4);
            this.state = 296;
            this.match(entityParser.T__23);
            break;
        case entityParser.T__24:
            this.enterOuterAlt(localctx, 5);
            this.state = 297;
            this.match(entityParser.T__24);
            this.state = 298;
            this.match(entityParser.T__1);
            this.state = 299;
            this.metaPropertyValidationDef();
            this.state = 300;
            this.match(entityParser.T__2);
            break;
        default:
            throw new antlr4.error.NoViableAltException(this);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function MetaPropertyValidationDefContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_metaPropertyValidationDef;
    return this;
}

MetaPropertyValidationDefContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
MetaPropertyValidationDefContext.prototype.constructor = MetaPropertyValidationDefContext;

MetaPropertyValidationDefContext.prototype.Varid = function() {
    return this.getToken(entityParser.Varid, 0);
};

MetaPropertyValidationDefContext.prototype.metaPropertyValidation = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(MetaPropertyValidationContext);
    } else {
        return this.getTypedRuleContext(MetaPropertyValidationContext,i);
    }
};

MetaPropertyValidationDefContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterMetaPropertyValidationDef(this);
	}
};

MetaPropertyValidationDefContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitMetaPropertyValidationDef(this);
	}
};

MetaPropertyValidationDefContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitMetaPropertyValidationDef(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.MetaPropertyValidationDefContext = MetaPropertyValidationDefContext;

entityParser.prototype.metaPropertyValidationDef = function() {

    var localctx = new MetaPropertyValidationDefContext(this, this._ctx, this.state);
    this.enterRule(localctx, 36, entityParser.RULE_metaPropertyValidationDef);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 304;
        this.match(entityParser.Varid);
        this.state = 305;
        this.match(entityParser.T__1);
        this.state = 307; 
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        do {
            this.state = 306;
            this.metaPropertyValidation();
            this.state = 309; 
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        } while(_la===entityParser.T__15 || _la===entityParser.T__25);
        this.state = 311;
        this.match(entityParser.T__2);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function MetaPropertyValidationContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_metaPropertyValidation;
    return this;
}

MetaPropertyValidationContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
MetaPropertyValidationContext.prototype.constructor = MetaPropertyValidationContext;

MetaPropertyValidationContext.prototype.metaPropertyValidationRegexp = function() {
    return this.getTypedRuleContext(MetaPropertyValidationRegexpContext,0);
};

MetaPropertyValidationContext.prototype.metaPropertyValidationFailure = function() {
    return this.getTypedRuleContext(MetaPropertyValidationFailureContext,0);
};

MetaPropertyValidationContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterMetaPropertyValidation(this);
	}
};

MetaPropertyValidationContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitMetaPropertyValidation(this);
	}
};

MetaPropertyValidationContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitMetaPropertyValidation(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.MetaPropertyValidationContext = MetaPropertyValidationContext;

entityParser.prototype.metaPropertyValidation = function() {

    var localctx = new MetaPropertyValidationContext(this, this._ctx, this.state);
    this.enterRule(localctx, 38, entityParser.RULE_metaPropertyValidation);
    try {
        this.state = 315;
        this._errHandler.sync(this);
        switch(this._input.LA(1)) {
        case entityParser.T__25:
            this.enterOuterAlt(localctx, 1);
            this.state = 313;
            this.metaPropertyValidationRegexp();
            break;
        case entityParser.T__15:
            this.enterOuterAlt(localctx, 2);
            this.state = 314;
            this.metaPropertyValidationFailure();
            break;
        default:
            throw new antlr4.error.NoViableAltException(this);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function MetaPropertyValidationRegexpContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_metaPropertyValidationRegexp;
    return this;
}

MetaPropertyValidationRegexpContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
MetaPropertyValidationRegexpContext.prototype.constructor = MetaPropertyValidationRegexpContext;

MetaPropertyValidationRegexpContext.prototype.StringLiteral = function() {
    return this.getToken(entityParser.StringLiteral, 0);
};

MetaPropertyValidationRegexpContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterMetaPropertyValidationRegexp(this);
	}
};

MetaPropertyValidationRegexpContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitMetaPropertyValidationRegexp(this);
	}
};

MetaPropertyValidationRegexpContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitMetaPropertyValidationRegexp(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.MetaPropertyValidationRegexpContext = MetaPropertyValidationRegexpContext;

entityParser.prototype.metaPropertyValidationRegexp = function() {

    var localctx = new MetaPropertyValidationRegexpContext(this, this._ctx, this.state);
    this.enterRule(localctx, 40, entityParser.RULE_metaPropertyValidationRegexp);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 317;
        this.match(entityParser.T__25);
        this.state = 318;
        this.match(entityParser.T__4);
        this.state = 319;
        this.match(entityParser.StringLiteral);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function MetaPropertyValidationFailureContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_metaPropertyValidationFailure;
    return this;
}

MetaPropertyValidationFailureContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
MetaPropertyValidationFailureContext.prototype.constructor = MetaPropertyValidationFailureContext;

MetaPropertyValidationFailureContext.prototype.StringLiteral = function() {
    return this.getToken(entityParser.StringLiteral, 0);
};

MetaPropertyValidationFailureContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterMetaPropertyValidationFailure(this);
	}
};

MetaPropertyValidationFailureContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitMetaPropertyValidationFailure(this);
	}
};

MetaPropertyValidationFailureContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitMetaPropertyValidationFailure(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.MetaPropertyValidationFailureContext = MetaPropertyValidationFailureContext;

entityParser.prototype.metaPropertyValidationFailure = function() {

    var localctx = new MetaPropertyValidationFailureContext(this, this._ctx, this.state);
    this.enterRule(localctx, 42, entityParser.RULE_metaPropertyValidationFailure);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 321;
        this.match(entityParser.T__15);
        this.state = 322;
        this.match(entityParser.T__4);
        this.state = 323;
        this.match(entityParser.StringLiteral);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function ComputedDefContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_computedDef;
    return this;
}

ComputedDefContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
ComputedDefContext.prototype.constructor = ComputedDefContext;

ComputedDefContext.prototype.computedProp = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(ComputedPropContext);
    } else {
        return this.getTypedRuleContext(ComputedPropContext,i);
    }
};

ComputedDefContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterComputedDef(this);
	}
};

ComputedDefContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitComputedDef(this);
	}
};

ComputedDefContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitComputedDef(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.ComputedDefContext = ComputedDefContext;

entityParser.prototype.computedDef = function() {

    var localctx = new ComputedDefContext(this, this._ctx, this.state);
    this.enterRule(localctx, 44, entityParser.RULE_computedDef);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 325;
        this.match(entityParser.T__26);
        this.state = 326;
        this.match(entityParser.T__1);
        this.state = 328; 
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        do {
            this.state = 327;
            this.computedProp();
            this.state = 330; 
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        } while(_la===entityParser.Varid);
        this.state = 332;
        this.match(entityParser.T__2);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function ComputedPropContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_computedProp;
    return this;
}

ComputedPropContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
ComputedPropContext.prototype.constructor = ComputedPropContext;

ComputedPropContext.prototype.Varid = function() {
    return this.getToken(entityParser.Varid, 0);
};

ComputedPropContext.prototype.computedPropType = function() {
    return this.getTypedRuleContext(ComputedPropTypeContext,0);
};

ComputedPropContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterComputedProp(this);
	}
};

ComputedPropContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitComputedProp(this);
	}
};

ComputedPropContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitComputedProp(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.ComputedPropContext = ComputedPropContext;

entityParser.prototype.computedProp = function() {

    var localctx = new ComputedPropContext(this, this._ctx, this.state);
    this.enterRule(localctx, 46, entityParser.RULE_computedProp);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 334;
        this.match(entityParser.Varid);
        this.state = 335;
        this.match(entityParser.T__4);
        this.state = 336;
        this.computedPropType();
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function ComputedPropTypeContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_computedPropType;
    return this;
}

ComputedPropTypeContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
ComputedPropTypeContext.prototype.constructor = ComputedPropTypeContext;

ComputedPropTypeContext.prototype.NullableTypeid = function() {
    return this.getToken(entityParser.NullableTypeid, 0);
};

ComputedPropTypeContext.prototype.Typeid = function() {
    return this.getToken(entityParser.Typeid, 0);
};

ComputedPropTypeContext.prototype.CollectionTypeid = function() {
    return this.getToken(entityParser.CollectionTypeid, 0);
};

ComputedPropTypeContext.prototype.metaComputedProp = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(MetaComputedPropContext);
    } else {
        return this.getTypedRuleContext(MetaComputedPropContext,i);
    }
};

ComputedPropTypeContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterComputedPropType(this);
	}
};

ComputedPropTypeContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitComputedPropType(this);
	}
};

ComputedPropTypeContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitComputedPropType(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.ComputedPropTypeContext = ComputedPropTypeContext;

entityParser.prototype.computedPropType = function() {

    var localctx = new ComputedPropTypeContext(this, this._ctx, this.state);
    this.enterRule(localctx, 48, entityParser.RULE_computedPropType);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 338;
        _la = this._input.LA(1);
        if(!(((((_la - 81)) & ~0x1f) == 0 && ((1 << (_la - 81)) & ((1 << (entityParser.CollectionTypeid - 81)) | (1 << (entityParser.NullableTypeid - 81)) | (1 << (entityParser.Typeid - 81)))) !== 0))) {
        this._errHandler.recoverInline(this);
        }
        else {
        	this._errHandler.reportMatch(this);
            this.consume();
        }
        this.state = 347;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(_la===entityParser.T__1) {
            this.state = 339;
            this.match(entityParser.T__1);
            this.state = 341; 
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            do {
                this.state = 340;
                this.metaComputedProp();
                this.state = 343; 
                this._errHandler.sync(this);
                _la = this._input.LA(1);
            } while(_la===entityParser.T__40 || _la===entityParser.T__52);
            this.state = 345;
            this.match(entityParser.T__2);
        }

    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function MetaComputedPropContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_metaComputedProp;
    return this;
}

MetaComputedPropContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
MetaComputedPropContext.prototype.constructor = MetaComputedPropContext;

MetaComputedPropContext.prototype.queryWhereBlock = function() {
    return this.getTypedRuleContext(QueryWhereBlockContext,0);
};

MetaComputedPropContext.prototype.queryOrderBlock = function() {
    return this.getTypedRuleContext(QueryOrderBlockContext,0);
};

MetaComputedPropContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterMetaComputedProp(this);
	}
};

MetaComputedPropContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitMetaComputedProp(this);
	}
};

MetaComputedPropContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitMetaComputedProp(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.MetaComputedPropContext = MetaComputedPropContext;

entityParser.prototype.metaComputedProp = function() {

    var localctx = new MetaComputedPropContext(this, this._ctx, this.state);
    this.enterRule(localctx, 50, entityParser.RULE_metaComputedProp);
    try {
        this.state = 351;
        this._errHandler.sync(this);
        switch(this._input.LA(1)) {
        case entityParser.T__40:
            this.enterOuterAlt(localctx, 1);
            this.state = 349;
            this.queryWhereBlock();
            break;
        case entityParser.T__52:
            this.enterOuterAlt(localctx, 2);
            this.state = 350;
            this.queryOrderBlock();
            break;
        default:
            throw new antlr4.error.NoViableAltException(this);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function ComputedPropRelContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_computedPropRel;
    return this;
}

ComputedPropRelContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
ComputedPropRelContext.prototype.constructor = ComputedPropRelContext;

ComputedPropRelContext.prototype.Varid = function() {
    return this.getToken(entityParser.Varid, 0);
};

ComputedPropRelContext.prototype.qualId = function() {
    return this.getTypedRuleContext(QualIdContext,0);
};

ComputedPropRelContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterComputedPropRel(this);
	}
};

ComputedPropRelContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitComputedPropRel(this);
	}
};

ComputedPropRelContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitComputedPropRel(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.ComputedPropRelContext = ComputedPropRelContext;

entityParser.prototype.computedPropRel = function() {

    var localctx = new ComputedPropRelContext(this, this._ctx, this.state);
    this.enterRule(localctx, 52, entityParser.RULE_computedPropRel);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 353;
        this.match(entityParser.Varid);
        this.state = 354;
        this.match(entityParser.T__27);
        this.state = 355;
        this.qualId();
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function IndexDefContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_indexDef;
    return this;
}

IndexDefContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
IndexDefContext.prototype.constructor = IndexDefContext;

IndexDefContext.prototype.indexBlock = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(IndexBlockContext);
    } else {
        return this.getTypedRuleContext(IndexBlockContext,i);
    }
};

IndexDefContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterIndexDef(this);
	}
};

IndexDefContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitIndexDef(this);
	}
};

IndexDefContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitIndexDef(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.IndexDefContext = IndexDefContext;

entityParser.prototype.indexDef = function() {

    var localctx = new IndexDefContext(this, this._ctx, this.state);
    this.enterRule(localctx, 54, entityParser.RULE_indexDef);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 357;
        this.match(entityParser.T__28);
        this.state = 358;
        this.match(entityParser.T__1);
        this.state = 360; 
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        do {
            this.state = 359;
            this.indexBlock();
            this.state = 362; 
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        } while(_la===entityParser.Varid);
        this.state = 364;
        this.match(entityParser.T__2);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function IndexBlockContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_indexBlock;
    return this;
}

IndexBlockContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
IndexBlockContext.prototype.constructor = IndexBlockContext;

IndexBlockContext.prototype.Varid = function() {
    return this.getToken(entityParser.Varid, 0);
};

IndexBlockContext.prototype.indexField = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(IndexFieldContext);
    } else {
        return this.getTypedRuleContext(IndexFieldContext,i);
    }
};

IndexBlockContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterIndexBlock(this);
	}
};

IndexBlockContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitIndexBlock(this);
	}
};

IndexBlockContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitIndexBlock(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.IndexBlockContext = IndexBlockContext;

entityParser.prototype.indexBlock = function() {

    var localctx = new IndexBlockContext(this, this._ctx, this.state);
    this.enterRule(localctx, 56, entityParser.RULE_indexBlock);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 366;
        this.match(entityParser.Varid);
        this.state = 367;
        this.match(entityParser.T__1);
        this.state = 369; 
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        do {
            this.state = 368;
            this.indexField();
            this.state = 371; 
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        } while(_la===entityParser.Varid);
        this.state = 373;
        this.match(entityParser.T__2);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function IndexFieldContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_indexField;
    return this;
}

IndexFieldContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
IndexFieldContext.prototype.constructor = IndexFieldContext;

IndexFieldContext.prototype.Varid = function() {
    return this.getToken(entityParser.Varid, 0);
};

IndexFieldContext.prototype.QuerySort = function() {
    return this.getToken(entityParser.QuerySort, 0);
};

IndexFieldContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterIndexField(this);
	}
};

IndexFieldContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitIndexField(this);
	}
};

IndexFieldContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitIndexField(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.IndexFieldContext = IndexFieldContext;

entityParser.prototype.indexField = function() {

    var localctx = new IndexFieldContext(this, this._ctx, this.state);
    this.enterRule(localctx, 58, entityParser.RULE_indexField);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 375;
        this.match(entityParser.Varid);
        this.state = 377;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(_la===entityParser.QuerySort) {
            this.state = 376;
            this.match(entityParser.QuerySort);
        }

    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function QueriesDefContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_queriesDef;
    return this;
}

QueriesDefContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
QueriesDefContext.prototype.constructor = QueriesDefContext;

QueriesDefContext.prototype.queryBlock = function() {
    return this.getTypedRuleContext(QueryBlockContext,0);
};

QueriesDefContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterQueriesDef(this);
	}
};

QueriesDefContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitQueriesDef(this);
	}
};

QueriesDefContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitQueriesDef(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.QueriesDefContext = QueriesDefContext;

entityParser.prototype.queriesDef = function() {

    var localctx = new QueriesDefContext(this, this._ctx, this.state);
    this.enterRule(localctx, 60, entityParser.RULE_queriesDef);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 379;
        this.queryBlock();
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function QueryBlockContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_queryBlock;
    return this;
}

QueryBlockContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
QueryBlockContext.prototype.constructor = QueryBlockContext;

QueryBlockContext.prototype.queryAccessControl = function() {
    return this.getTypedRuleContext(QueryAccessControlContext,0);
};

QueryBlockContext.prototype.Varid = function() {
    return this.getToken(entityParser.Varid, 0);
};

QueryBlockContext.prototype.queryOperation = function() {
    return this.getTypedRuleContext(QueryOperationContext,0);
};

QueryBlockContext.prototype.NullableTypeid = function() {
    return this.getToken(entityParser.NullableTypeid, 0);
};

QueryBlockContext.prototype.Typeid = function() {
    return this.getToken(entityParser.Typeid, 0);
};

QueryBlockContext.prototype.CollectionTypeid = function() {
    return this.getToken(entityParser.CollectionTypeid, 0);
};

QueryBlockContext.prototype.binding = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(BindingContext);
    } else {
        return this.getTypedRuleContext(BindingContext,i);
    }
};

QueryBlockContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterQueryBlock(this);
	}
};

QueryBlockContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitQueryBlock(this);
	}
};

QueryBlockContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitQueryBlock(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.QueryBlockContext = QueryBlockContext;

entityParser.prototype.queryBlock = function() {

    var localctx = new QueryBlockContext(this, this._ctx, this.state);
    this.enterRule(localctx, 62, entityParser.RULE_queryBlock);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 381;
        this.queryAccessControl();
        this.state = 382;
        this.match(entityParser.T__29);
        this.state = 383;
        this.match(entityParser.Varid);
        this.state = 384;
        this.match(entityParser.T__30);
        this.state = 393;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(_la===entityParser.Varid) {
            this.state = 385;
            this.binding();
            this.state = 390;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            while(_la===entityParser.T__31) {
                this.state = 386;
                this.match(entityParser.T__31);
                this.state = 387;
                this.binding();
                this.state = 392;
                this._errHandler.sync(this);
                _la = this._input.LA(1);
            }
        }

        this.state = 395;
        this.match(entityParser.T__32);
        this.state = 396;
        this.match(entityParser.T__4);
        this.state = 397;
        _la = this._input.LA(1);
        if(!(((((_la - 81)) & ~0x1f) == 0 && ((1 << (_la - 81)) & ((1 << (entityParser.CollectionTypeid - 81)) | (1 << (entityParser.NullableTypeid - 81)) | (1 << (entityParser.Typeid - 81)))) !== 0))) {
        this._errHandler.recoverInline(this);
        }
        else {
        	this._errHandler.reportMatch(this);
            this.consume();
        }
        this.state = 398;
        this.queryOperation();
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function QueryAccessControlContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_queryAccessControl;
    return this;
}

QueryAccessControlContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
QueryAccessControlContext.prototype.constructor = QueryAccessControlContext;

QueryAccessControlContext.prototype.accessControlType = function() {
    return this.getTypedRuleContext(AccessControlTypeContext,0);
};

QueryAccessControlContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterQueryAccessControl(this);
	}
};

QueryAccessControlContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitQueryAccessControl(this);
	}
};

QueryAccessControlContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitQueryAccessControl(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.QueryAccessControlContext = QueryAccessControlContext;

entityParser.prototype.queryAccessControl = function() {

    var localctx = new QueryAccessControlContext(this, this._ctx, this.state);
    this.enterRule(localctx, 64, entityParser.RULE_queryAccessControl);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 400;
        this.match(entityParser.T__33);
        this.state = 401;
        this.accessControlType();
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function QueryOperationContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_queryOperation;
    return this;
}

QueryOperationContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
QueryOperationContext.prototype.constructor = QueryOperationContext;

QueryOperationContext.prototype.queryOperationBlock = function() {
    return this.getTypedRuleContext(QueryOperationBlockContext,0);
};

QueryOperationContext.prototype.queryOperationRaw = function() {
    return this.getTypedRuleContext(QueryOperationRawContext,0);
};

QueryOperationContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterQueryOperation(this);
	}
};

QueryOperationContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitQueryOperation(this);
	}
};

QueryOperationContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitQueryOperation(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.QueryOperationContext = QueryOperationContext;

entityParser.prototype.queryOperation = function() {

    var localctx = new QueryOperationContext(this, this._ctx, this.state);
    this.enterRule(localctx, 66, entityParser.RULE_queryOperation);
    try {
        this.state = 405;
        this._errHandler.sync(this);
        var la_ = this._interp.adaptivePredict(this._input,27,this._ctx);
        switch(la_) {
        case 1:
            this.enterOuterAlt(localctx, 1);
            this.state = 403;
            this.queryOperationBlock();
            break;

        case 2:
            this.enterOuterAlt(localctx, 2);
            this.state = 404;
            this.queryOperationRaw();
            break;

        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function QueryOperationBlockContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_queryOperationBlock;
    return this;
}

QueryOperationBlockContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
QueryOperationBlockContext.prototype.constructor = QueryOperationBlockContext;

QueryOperationBlockContext.prototype.queryCacheBlock = function() {
    return this.getTypedRuleContext(QueryCacheBlockContext,0);
};

QueryOperationBlockContext.prototype.queryWhereBlock = function() {
    return this.getTypedRuleContext(QueryWhereBlockContext,0);
};

QueryOperationBlockContext.prototype.queryOrderBlock = function() {
    return this.getTypedRuleContext(QueryOrderBlockContext,0);
};

QueryOperationBlockContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterQueryOperationBlock(this);
	}
};

QueryOperationBlockContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitQueryOperationBlock(this);
	}
};

QueryOperationBlockContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitQueryOperationBlock(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.QueryOperationBlockContext = QueryOperationBlockContext;

entityParser.prototype.queryOperationBlock = function() {

    var localctx = new QueryOperationBlockContext(this, this._ctx, this.state);
    this.enterRule(localctx, 68, entityParser.RULE_queryOperationBlock);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 407;
        this.match(entityParser.T__1);
        this.state = 409;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(_la===entityParser.T__34) {
            this.state = 408;
            this.queryCacheBlock();
        }

        this.state = 412;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(_la===entityParser.T__40) {
            this.state = 411;
            this.queryWhereBlock();
        }

        this.state = 415;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(_la===entityParser.T__52) {
            this.state = 414;
            this.queryOrderBlock();
        }

        this.state = 417;
        this.match(entityParser.T__2);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function QueryCacheBlockContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_queryCacheBlock;
    return this;
}

QueryCacheBlockContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
QueryCacheBlockContext.prototype.constructor = QueryCacheBlockContext;

QueryCacheBlockContext.prototype.queryCacheStrategy = function() {
    return this.getTypedRuleContext(QueryCacheStrategyContext,0);
};

QueryCacheBlockContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterQueryCacheBlock(this);
	}
};

QueryCacheBlockContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitQueryCacheBlock(this);
	}
};

QueryCacheBlockContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitQueryCacheBlock(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.QueryCacheBlockContext = QueryCacheBlockContext;

entityParser.prototype.queryCacheBlock = function() {

    var localctx = new QueryCacheBlockContext(this, this._ctx, this.state);
    this.enterRule(localctx, 70, entityParser.RULE_queryCacheBlock);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 419;
        this.match(entityParser.T__34);
        this.state = 420;
        this.match(entityParser.T__1);
        this.state = 421;
        this.queryCacheStrategy();
        this.state = 422;
        this.match(entityParser.T__2);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function QueryCacheStrategyContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_queryCacheStrategy;
    return this;
}

QueryCacheStrategyContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
QueryCacheStrategyContext.prototype.constructor = QueryCacheStrategyContext;

QueryCacheStrategyContext.prototype.queryCacheStrategyTTL = function() {
    return this.getTypedRuleContext(QueryCacheStrategyTTLContext,0);
};

QueryCacheStrategyContext.prototype.queryCacheStrategyNever = function() {
    return this.getTypedRuleContext(QueryCacheStrategyNeverContext,0);
};

QueryCacheStrategyContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterQueryCacheStrategy(this);
	}
};

QueryCacheStrategyContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitQueryCacheStrategy(this);
	}
};

QueryCacheStrategyContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitQueryCacheStrategy(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.QueryCacheStrategyContext = QueryCacheStrategyContext;

entityParser.prototype.queryCacheStrategy = function() {

    var localctx = new QueryCacheStrategyContext(this, this._ctx, this.state);
    this.enterRule(localctx, 72, entityParser.RULE_queryCacheStrategy);
    try {
        this.state = 426;
        this._errHandler.sync(this);
        switch(this._input.LA(1)) {
        case entityParser.T__35:
            this.enterOuterAlt(localctx, 1);
            this.state = 424;
            this.queryCacheStrategyTTL();
            break;
        case entityParser.T__37:
            this.enterOuterAlt(localctx, 2);
            this.state = 425;
            this.queryCacheStrategyNever();
            break;
        default:
            throw new antlr4.error.NoViableAltException(this);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function QueryCacheStrategyTTLContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_queryCacheStrategyTTL;
    return this;
}

QueryCacheStrategyTTLContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
QueryCacheStrategyTTLContext.prototype.constructor = QueryCacheStrategyTTLContext;

QueryCacheStrategyTTLContext.prototype.queryCacheKey = function() {
    return this.getTypedRuleContext(QueryCacheKeyContext,0);
};

QueryCacheStrategyTTLContext.prototype.queryCacheStrategyTTLOption = function() {
    return this.getTypedRuleContext(QueryCacheStrategyTTLOptionContext,0);
};

QueryCacheStrategyTTLContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterQueryCacheStrategyTTL(this);
	}
};

QueryCacheStrategyTTLContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitQueryCacheStrategyTTL(this);
	}
};

QueryCacheStrategyTTLContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitQueryCacheStrategyTTL(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.QueryCacheStrategyTTLContext = QueryCacheStrategyTTLContext;

entityParser.prototype.queryCacheStrategyTTL = function() {

    var localctx = new QueryCacheStrategyTTLContext(this, this._ctx, this.state);
    this.enterRule(localctx, 74, entityParser.RULE_queryCacheStrategyTTL);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 428;
        this.match(entityParser.T__35);
        this.state = 437;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(_la===entityParser.T__1) {
            this.state = 429;
            this.match(entityParser.T__1);
            this.state = 431;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            if(_la===entityParser.T__38) {
                this.state = 430;
                this.queryCacheKey();
            }

            this.state = 434;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            if(_la===entityParser.T__36) {
                this.state = 433;
                this.queryCacheStrategyTTLOption();
            }

            this.state = 436;
            this.match(entityParser.T__2);
        }

    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function QueryCacheStrategyTTLOptionContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_queryCacheStrategyTTLOption;
    return this;
}

QueryCacheStrategyTTLOptionContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
QueryCacheStrategyTTLOptionContext.prototype.constructor = QueryCacheStrategyTTLOptionContext;

QueryCacheStrategyTTLOptionContext.prototype.IntegerLiteral = function() {
    return this.getToken(entityParser.IntegerLiteral, 0);
};

QueryCacheStrategyTTLOptionContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterQueryCacheStrategyTTLOption(this);
	}
};

QueryCacheStrategyTTLOptionContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitQueryCacheStrategyTTLOption(this);
	}
};

QueryCacheStrategyTTLOptionContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitQueryCacheStrategyTTLOption(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.QueryCacheStrategyTTLOptionContext = QueryCacheStrategyTTLOptionContext;

entityParser.prototype.queryCacheStrategyTTLOption = function() {

    var localctx = new QueryCacheStrategyTTLOptionContext(this, this._ctx, this.state);
    this.enterRule(localctx, 76, entityParser.RULE_queryCacheStrategyTTLOption);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 439;
        this.match(entityParser.T__36);
        this.state = 440;
        this.match(entityParser.T__4);
        this.state = 441;
        this.match(entityParser.IntegerLiteral);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function QueryCacheStrategyNeverContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_queryCacheStrategyNever;
    return this;
}

QueryCacheStrategyNeverContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
QueryCacheStrategyNeverContext.prototype.constructor = QueryCacheStrategyNeverContext;


QueryCacheStrategyNeverContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterQueryCacheStrategyNever(this);
	}
};

QueryCacheStrategyNeverContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitQueryCacheStrategyNever(this);
	}
};

QueryCacheStrategyNeverContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitQueryCacheStrategyNever(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.QueryCacheStrategyNeverContext = QueryCacheStrategyNeverContext;

entityParser.prototype.queryCacheStrategyNever = function() {

    var localctx = new QueryCacheStrategyNeverContext(this, this._ctx, this.state);
    this.enterRule(localctx, 78, entityParser.RULE_queryCacheStrategyNever);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 443;
        this.match(entityParser.T__37);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function QueryCacheKeyContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_queryCacheKey;
    return this;
}

QueryCacheKeyContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
QueryCacheKeyContext.prototype.constructor = QueryCacheKeyContext;

QueryCacheKeyContext.prototype.queryCacheKeyValue = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(QueryCacheKeyValueContext);
    } else {
        return this.getTypedRuleContext(QueryCacheKeyValueContext,i);
    }
};

QueryCacheKeyContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterQueryCacheKey(this);
	}
};

QueryCacheKeyContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitQueryCacheKey(this);
	}
};

QueryCacheKeyContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitQueryCacheKey(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.QueryCacheKeyContext = QueryCacheKeyContext;

entityParser.prototype.queryCacheKey = function() {

    var localctx = new QueryCacheKeyContext(this, this._ctx, this.state);
    this.enterRule(localctx, 80, entityParser.RULE_queryCacheKey);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 445;
        this.match(entityParser.T__38);
        this.state = 446;
        this.match(entityParser.T__4);
        this.state = 448; 
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        do {
            this.state = 447;
            this.queryCacheKeyValue();
            this.state = 450; 
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        } while(_la===entityParser.Varid);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function QueryCacheKeyValueContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_queryCacheKeyValue;
    return this;
}

QueryCacheKeyValueContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
QueryCacheKeyValueContext.prototype.constructor = QueryCacheKeyValueContext;

QueryCacheKeyValueContext.prototype.Varid = function() {
    return this.getToken(entityParser.Varid, 0);
};

QueryCacheKeyValueContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterQueryCacheKeyValue(this);
	}
};

QueryCacheKeyValueContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitQueryCacheKeyValue(this);
	}
};

QueryCacheKeyValueContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitQueryCacheKeyValue(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.QueryCacheKeyValueContext = QueryCacheKeyValueContext;

entityParser.prototype.queryCacheKeyValue = function() {

    var localctx = new QueryCacheKeyValueContext(this, this._ctx, this.state);
    this.enterRule(localctx, 82, entityParser.RULE_queryCacheKeyValue);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 452;
        this.match(entityParser.Varid);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function QueryOperationRawContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_queryOperationRaw;
    return this;
}

QueryOperationRawContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
QueryOperationRawContext.prototype.constructor = QueryOperationRawContext;

QueryOperationRawContext.prototype.queryCacheBlock = function() {
    return this.getTypedRuleContext(QueryCacheBlockContext,0);
};

QueryOperationRawContext.prototype.queryOperationRawBlock = function() {
    return this.getTypedRuleContext(QueryOperationRawBlockContext,0);
};

QueryOperationRawContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterQueryOperationRaw(this);
	}
};

QueryOperationRawContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitQueryOperationRaw(this);
	}
};

QueryOperationRawContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitQueryOperationRaw(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.QueryOperationRawContext = QueryOperationRawContext;

entityParser.prototype.queryOperationRaw = function() {

    var localctx = new QueryOperationRawContext(this, this._ctx, this.state);
    this.enterRule(localctx, 84, entityParser.RULE_queryOperationRaw);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 454;
        this.match(entityParser.T__1);
        this.state = 456;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(_la===entityParser.T__34) {
            this.state = 455;
            this.queryCacheBlock();
        }

        this.state = 459;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(_la===entityParser.T__39) {
            this.state = 458;
            this.queryOperationRawBlock();
        }

        this.state = 461;
        this.match(entityParser.T__2);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function QueryOperationRawBlockContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_queryOperationRawBlock;
    return this;
}

QueryOperationRawBlockContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
QueryOperationRawBlockContext.prototype.constructor = QueryOperationRawBlockContext;

QueryOperationRawBlockContext.prototype.queryRawBlock = function() {
    return this.getTypedRuleContext(QueryRawBlockContext,0);
};

QueryOperationRawBlockContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterQueryOperationRawBlock(this);
	}
};

QueryOperationRawBlockContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitQueryOperationRawBlock(this);
	}
};

QueryOperationRawBlockContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitQueryOperationRawBlock(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.QueryOperationRawBlockContext = QueryOperationRawBlockContext;

entityParser.prototype.queryOperationRawBlock = function() {

    var localctx = new QueryOperationRawBlockContext(this, this._ctx, this.state);
    this.enterRule(localctx, 86, entityParser.RULE_queryOperationRawBlock);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 463;
        this.match(entityParser.T__39);
        this.state = 464;
        this.match(entityParser.T__1);
        this.state = 465;
        this.queryRawBlock();
        this.state = 466;
        this.match(entityParser.T__2);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function QueryWhereBlockContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_queryWhereBlock;
    return this;
}

QueryWhereBlockContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
QueryWhereBlockContext.prototype.constructor = QueryWhereBlockContext;

QueryWhereBlockContext.prototype.queryWhereGroup = function() {
    return this.getTypedRuleContext(QueryWhereGroupContext,0);
};

QueryWhereBlockContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterQueryWhereBlock(this);
	}
};

QueryWhereBlockContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitQueryWhereBlock(this);
	}
};

QueryWhereBlockContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitQueryWhereBlock(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.QueryWhereBlockContext = QueryWhereBlockContext;

entityParser.prototype.queryWhereBlock = function() {

    var localctx = new QueryWhereBlockContext(this, this._ctx, this.state);
    this.enterRule(localctx, 88, entityParser.RULE_queryWhereBlock);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 468;
        this.match(entityParser.T__40);
        this.state = 469;
        this.match(entityParser.T__1);
        this.state = 470;
        this.queryWhereGroup();
        this.state = 471;
        this.match(entityParser.T__2);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function QueryWhereGroupContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_queryWhereGroup;
    return this;
}

QueryWhereGroupContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
QueryWhereGroupContext.prototype.constructor = QueryWhereGroupContext;

QueryWhereGroupContext.prototype.queryWhere = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(QueryWhereContext);
    } else {
        return this.getTypedRuleContext(QueryWhereContext,i);
    }
};

QueryWhereGroupContext.prototype.queryWhereJunction = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(QueryWhereJunctionContext);
    } else {
        return this.getTypedRuleContext(QueryWhereJunctionContext,i);
    }
};

QueryWhereGroupContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterQueryWhereGroup(this);
	}
};

QueryWhereGroupContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitQueryWhereGroup(this);
	}
};

QueryWhereGroupContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitQueryWhereGroup(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.QueryWhereGroupContext = QueryWhereGroupContext;

entityParser.prototype.queryWhereGroup = function() {

    var localctx = new QueryWhereGroupContext(this, this._ctx, this.state);
    this.enterRule(localctx, 90, entityParser.RULE_queryWhereGroup);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 473;
        this.queryWhere();
        this.state = 479;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        while(_la===entityParser.T__41 || _la===entityParser.T__42) {
            this.state = 474;
            this.queryWhereJunction();
            this.state = 475;
            this.queryWhere();
            this.state = 481;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function QueryWhereJunctionContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_queryWhereJunction;
    return this;
}

QueryWhereJunctionContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
QueryWhereJunctionContext.prototype.constructor = QueryWhereJunctionContext;


QueryWhereJunctionContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterQueryWhereJunction(this);
	}
};

QueryWhereJunctionContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitQueryWhereJunction(this);
	}
};

QueryWhereJunctionContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitQueryWhereJunction(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.QueryWhereJunctionContext = QueryWhereJunctionContext;

entityParser.prototype.queryWhereJunction = function() {

    var localctx = new QueryWhereJunctionContext(this, this._ctx, this.state);
    this.enterRule(localctx, 92, entityParser.RULE_queryWhereJunction);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 482;
        _la = this._input.LA(1);
        if(!(_la===entityParser.T__41 || _la===entityParser.T__42)) {
        this._errHandler.recoverInline(this);
        }
        else {
        	this._errHandler.reportMatch(this);
            this.consume();
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function QueryWhereContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_queryWhere;
    return this;
}

QueryWhereContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
QueryWhereContext.prototype.constructor = QueryWhereContext;

QueryWhereContext.prototype.queryWhereField = function() {
    return this.getTypedRuleContext(QueryWhereFieldContext,0);
};

QueryWhereContext.prototype.queryOperator = function() {
    return this.getTypedRuleContext(QueryOperatorContext,0);
};

QueryWhereContext.prototype.queryWhereValue = function() {
    return this.getTypedRuleContext(QueryWhereValueContext,0);
};

QueryWhereContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterQueryWhere(this);
	}
};

QueryWhereContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitQueryWhere(this);
	}
};

QueryWhereContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitQueryWhere(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.QueryWhereContext = QueryWhereContext;

entityParser.prototype.queryWhere = function() {

    var localctx = new QueryWhereContext(this, this._ctx, this.state);
    this.enterRule(localctx, 94, entityParser.RULE_queryWhere);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 484;
        this.queryWhereField();
        this.state = 485;
        this.queryOperator();
        this.state = 486;
        this.queryWhereValue();
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function QueryWhereFieldContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_queryWhereField;
    return this;
}

QueryWhereFieldContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
QueryWhereFieldContext.prototype.constructor = QueryWhereFieldContext;

QueryWhereFieldContext.prototype.Varid = function() {
    return this.getToken(entityParser.Varid, 0);
};

QueryWhereFieldContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterQueryWhereField(this);
	}
};

QueryWhereFieldContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitQueryWhereField(this);
	}
};

QueryWhereFieldContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitQueryWhereField(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.QueryWhereFieldContext = QueryWhereFieldContext;

entityParser.prototype.queryWhereField = function() {

    var localctx = new QueryWhereFieldContext(this, this._ctx, this.state);
    this.enterRule(localctx, 96, entityParser.RULE_queryWhereField);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 488;
        this.match(entityParser.Varid);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function QueryWhereValueContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_queryWhereValue;
    return this;
}

QueryWhereValueContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
QueryWhereValueContext.prototype.constructor = QueryWhereValueContext;

QueryWhereValueContext.prototype.literal = function() {
    return this.getTypedRuleContext(LiteralContext,0);
};

QueryWhereValueContext.prototype.Null = function() {
    return this.getToken(entityParser.Null, 0);
};

QueryWhereValueContext.prototype.Varid = function() {
    return this.getToken(entityParser.Varid, 0);
};

QueryWhereValueContext.prototype.Typeid = function() {
    return this.getToken(entityParser.Typeid, 0);
};

QueryWhereValueContext.prototype.qualId = function() {
    return this.getTypedRuleContext(QualIdContext,0);
};

QueryWhereValueContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterQueryWhereValue(this);
	}
};

QueryWhereValueContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitQueryWhereValue(this);
	}
};

QueryWhereValueContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitQueryWhereValue(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.QueryWhereValueContext = QueryWhereValueContext;

entityParser.prototype.queryWhereValue = function() {

    var localctx = new QueryWhereValueContext(this, this._ctx, this.state);
    this.enterRule(localctx, 98, entityParser.RULE_queryWhereValue);
    var _la = 0; // Token type
    try {
        this.state = 499;
        this._errHandler.sync(this);
        switch(this._input.LA(1)) {
        case entityParser.BooleanLiteral:
        case entityParser.StringLiteral:
        case entityParser.MultiLineString:
        case entityParser.IntegerLiteral:
        case entityParser.FloatingPointLiteral:
            this.enterOuterAlt(localctx, 1);
            this.state = 490;
            this.literal();
            break;
        case entityParser.Null:
            this.enterOuterAlt(localctx, 2);
            this.state = 491;
            this.match(entityParser.Null);
            break;
        case entityParser.T__43:
        case entityParser.Varid:
            this.enterOuterAlt(localctx, 3);
            this.state = 493;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            if(_la===entityParser.T__43) {
                this.state = 492;
                this.match(entityParser.T__43);
            }

            this.state = 495;
            this.match(entityParser.Varid);
            break;
        case entityParser.Typeid:
            this.enterOuterAlt(localctx, 4);
            this.state = 496;
            this.match(entityParser.Typeid);
            this.state = 497;
            this.match(entityParser.T__44);
            this.state = 498;
            this.qualId();
            break;
        default:
            throw new antlr4.error.NoViableAltException(this);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function QueryOperatorContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_queryOperator;
    return this;
}

QueryOperatorContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
QueryOperatorContext.prototype.constructor = QueryOperatorContext;


QueryOperatorContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterQueryOperator(this);
	}
};

QueryOperatorContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitQueryOperator(this);
	}
};

QueryOperatorContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitQueryOperator(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.QueryOperatorContext = QueryOperatorContext;

entityParser.prototype.queryOperator = function() {

    var localctx = new QueryOperatorContext(this, this._ctx, this.state);
    this.enterRule(localctx, 100, entityParser.RULE_queryOperator);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 501;
        _la = this._input.LA(1);
        if(!(((((_la - 28)) & ~0x1f) == 0 && ((1 << (_la - 28)) & ((1 << (entityParser.T__27 - 28)) | (1 << (entityParser.T__45 - 28)) | (1 << (entityParser.T__46 - 28)) | (1 << (entityParser.T__47 - 28)) | (1 << (entityParser.T__48 - 28)) | (1 << (entityParser.T__49 - 28)) | (1 << (entityParser.T__50 - 28)))) !== 0))) {
        this._errHandler.recoverInline(this);
        }
        else {
        	this._errHandler.reportMatch(this);
            this.consume();
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function QueryRawBlockContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_queryRawBlock;
    return this;
}

QueryRawBlockContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
QueryRawBlockContext.prototype.constructor = QueryRawBlockContext;

QueryRawBlockContext.prototype.queryRawSqlBlock = function() {
    return this.getTypedRuleContext(QueryRawSqlBlockContext,0);
};

QueryRawBlockContext.prototype.queryRawParamsBlock = function() {
    return this.getTypedRuleContext(QueryRawParamsBlockContext,0);
};

QueryRawBlockContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterQueryRawBlock(this);
	}
};

QueryRawBlockContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitQueryRawBlock(this);
	}
};

QueryRawBlockContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitQueryRawBlock(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.QueryRawBlockContext = QueryRawBlockContext;

entityParser.prototype.queryRawBlock = function() {

    var localctx = new QueryRawBlockContext(this, this._ctx, this.state);
    this.enterRule(localctx, 102, entityParser.RULE_queryRawBlock);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 504;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(_la===entityParser.T__51) {
            this.state = 503;
            this.queryRawParamsBlock();
        }

        this.state = 506;
        this.queryRawSqlBlock();
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function QueryRawParamsBlockContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_queryRawParamsBlock;
    return this;
}

QueryRawParamsBlockContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
QueryRawParamsBlockContext.prototype.constructor = QueryRawParamsBlockContext;

QueryRawParamsBlockContext.prototype.queryRawParamBlock = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(QueryRawParamBlockContext);
    } else {
        return this.getTypedRuleContext(QueryRawParamBlockContext,i);
    }
};

QueryRawParamsBlockContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterQueryRawParamsBlock(this);
	}
};

QueryRawParamsBlockContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitQueryRawParamsBlock(this);
	}
};

QueryRawParamsBlockContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitQueryRawParamsBlock(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.QueryRawParamsBlockContext = QueryRawParamsBlockContext;

entityParser.prototype.queryRawParamsBlock = function() {

    var localctx = new QueryRawParamsBlockContext(this, this._ctx, this.state);
    this.enterRule(localctx, 104, entityParser.RULE_queryRawParamsBlock);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 508;
        this.match(entityParser.T__51);
        this.state = 509;
        this.match(entityParser.T__1);
        this.state = 511; 
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        do {
            this.state = 510;
            this.queryRawParamBlock();
            this.state = 513; 
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        } while(_la===entityParser.Varid);
        this.state = 515;
        this.match(entityParser.T__2);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function QueryRawParamBlockContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_queryRawParamBlock;
    return this;
}

QueryRawParamBlockContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
QueryRawParamBlockContext.prototype.constructor = QueryRawParamBlockContext;

QueryRawParamBlockContext.prototype.Varid = function() {
    return this.getToken(entityParser.Varid, 0);
};

QueryRawParamBlockContext.prototype.queryWhereValue = function() {
    return this.getTypedRuleContext(QueryWhereValueContext,0);
};

QueryRawParamBlockContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterQueryRawParamBlock(this);
	}
};

QueryRawParamBlockContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitQueryRawParamBlock(this);
	}
};

QueryRawParamBlockContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitQueryRawParamBlock(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.QueryRawParamBlockContext = QueryRawParamBlockContext;

entityParser.prototype.queryRawParamBlock = function() {

    var localctx = new QueryRawParamBlockContext(this, this._ctx, this.state);
    this.enterRule(localctx, 106, entityParser.RULE_queryRawParamBlock);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 517;
        this.match(entityParser.Varid);
        this.state = 518;
        this.match(entityParser.T__4);
        this.state = 519;
        this.queryWhereValue();
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function QueryRawSqlBlockContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_queryRawSqlBlock;
    return this;
}

QueryRawSqlBlockContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
QueryRawSqlBlockContext.prototype.constructor = QueryRawSqlBlockContext;

QueryRawSqlBlockContext.prototype.MultiLineString = function() {
    return this.getToken(entityParser.MultiLineString, 0);
};

QueryRawSqlBlockContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterQueryRawSqlBlock(this);
	}
};

QueryRawSqlBlockContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitQueryRawSqlBlock(this);
	}
};

QueryRawSqlBlockContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitQueryRawSqlBlock(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.QueryRawSqlBlockContext = QueryRawSqlBlockContext;

entityParser.prototype.queryRawSqlBlock = function() {

    var localctx = new QueryRawSqlBlockContext(this, this._ctx, this.state);
    this.enterRule(localctx, 108, entityParser.RULE_queryRawSqlBlock);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 521;
        this.match(entityParser.T__29);
        this.state = 522;
        this.match(entityParser.T__1);
        this.state = 523;
        this.match(entityParser.MultiLineString);
        this.state = 524;
        this.match(entityParser.T__2);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function QueryOrderBlockContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_queryOrderBlock;
    return this;
}

QueryOrderBlockContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
QueryOrderBlockContext.prototype.constructor = QueryOrderBlockContext;

QueryOrderBlockContext.prototype.queryFieldSort = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(QueryFieldSortContext);
    } else {
        return this.getTypedRuleContext(QueryFieldSortContext,i);
    }
};

QueryOrderBlockContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterQueryOrderBlock(this);
	}
};

QueryOrderBlockContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitQueryOrderBlock(this);
	}
};

QueryOrderBlockContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitQueryOrderBlock(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.QueryOrderBlockContext = QueryOrderBlockContext;

entityParser.prototype.queryOrderBlock = function() {

    var localctx = new QueryOrderBlockContext(this, this._ctx, this.state);
    this.enterRule(localctx, 110, entityParser.RULE_queryOrderBlock);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 526;
        this.match(entityParser.T__52);
        this.state = 527;
        this.match(entityParser.T__1);
        this.state = 529; 
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        do {
            this.state = 528;
            this.queryFieldSort();
            this.state = 531; 
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        } while(_la===entityParser.Varid);
        this.state = 533;
        this.match(entityParser.T__2);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function QueryFieldSortContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_queryFieldSort;
    return this;
}

QueryFieldSortContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
QueryFieldSortContext.prototype.constructor = QueryFieldSortContext;

QueryFieldSortContext.prototype.Varid = function() {
    return this.getToken(entityParser.Varid, 0);
};

QueryFieldSortContext.prototype.QuerySort = function() {
    return this.getToken(entityParser.QuerySort, 0);
};

QueryFieldSortContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterQueryFieldSort(this);
	}
};

QueryFieldSortContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitQueryFieldSort(this);
	}
};

QueryFieldSortContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitQueryFieldSort(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.QueryFieldSortContext = QueryFieldSortContext;

entityParser.prototype.queryFieldSort = function() {

    var localctx = new QueryFieldSortContext(this, this._ctx, this.state);
    this.enterRule(localctx, 112, entityParser.RULE_queryFieldSort);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 535;
        this.match(entityParser.Varid);
        this.state = 536;
        this.match(entityParser.QuerySort);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function LiteralContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_literal;
    return this;
}

LiteralContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
LiteralContext.prototype.constructor = LiteralContext;

LiteralContext.prototype.BooleanLiteral = function() {
    return this.getToken(entityParser.BooleanLiteral, 0);
};

LiteralContext.prototype.IntegerLiteral = function() {
    return this.getToken(entityParser.IntegerLiteral, 0);
};

LiteralContext.prototype.FloatingPointLiteral = function() {
    return this.getToken(entityParser.FloatingPointLiteral, 0);
};

LiteralContext.prototype.StringLiteral = function() {
    return this.getToken(entityParser.StringLiteral, 0);
};

LiteralContext.prototype.MultiLineString = function() {
    return this.getToken(entityParser.MultiLineString, 0);
};

LiteralContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterLiteral(this);
	}
};

LiteralContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitLiteral(this);
	}
};

LiteralContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitLiteral(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.LiteralContext = LiteralContext;

entityParser.prototype.literal = function() {

    var localctx = new LiteralContext(this, this._ctx, this.state);
    this.enterRule(localctx, 114, entityParser.RULE_literal);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 538;
        _la = this._input.LA(1);
        if(!(((((_la - 76)) & ~0x1f) == 0 && ((1 << (_la - 76)) & ((1 << (entityParser.BooleanLiteral - 76)) | (1 << (entityParser.StringLiteral - 76)) | (1 << (entityParser.MultiLineString - 76)) | (1 << (entityParser.IntegerLiteral - 76)) | (1 << (entityParser.FloatingPointLiteral - 76)))) !== 0))) {
        this._errHandler.recoverInline(this);
        }
        else {
        	this._errHandler.reportMatch(this);
            this.consume();
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function BindingContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_binding;
    return this;
}

BindingContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
BindingContext.prototype.constructor = BindingContext;

BindingContext.prototype.Varid = function() {
    return this.getToken(entityParser.Varid, 0);
};

BindingContext.prototype.Typeid = function() {
    return this.getToken(entityParser.Typeid, 0);
};

BindingContext.prototype.CollectionTypeid = function() {
    return this.getToken(entityParser.CollectionTypeid, 0);
};

BindingContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterBinding(this);
	}
};

BindingContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitBinding(this);
	}
};

BindingContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitBinding(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.BindingContext = BindingContext;

entityParser.prototype.binding = function() {

    var localctx = new BindingContext(this, this._ctx, this.state);
    this.enterRule(localctx, 116, entityParser.RULE_binding);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 540;
        this.match(entityParser.Varid);
        this.state = 541;
        this.match(entityParser.T__4);
        this.state = 542;
        _la = this._input.LA(1);
        if(!(_la===entityParser.CollectionTypeid || _la===entityParser.Typeid)) {
        this._errHandler.recoverInline(this);
        }
        else {
        	this._errHandler.reportMatch(this);
            this.consume();
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function VarIdsContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_varIds;
    return this;
}

VarIdsContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
VarIdsContext.prototype.constructor = VarIdsContext;

VarIdsContext.prototype.Varid = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(entityParser.Varid);
    } else {
        return this.getToken(entityParser.Varid, i);
    }
};


VarIdsContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterVarIds(this);
	}
};

VarIdsContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitVarIds(this);
	}
};

VarIdsContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitVarIds(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.VarIdsContext = VarIdsContext;

entityParser.prototype.varIds = function() {

    var localctx = new VarIdsContext(this, this._ctx, this.state);
    this.enterRule(localctx, 118, entityParser.RULE_varIds);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 544;
        this.match(entityParser.Varid);
        this.state = 549;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        while(_la===entityParser.T__31) {
            this.state = 545;
            this.match(entityParser.T__31);
            this.state = 546;
            this.match(entityParser.Varid);
            this.state = 551;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function QualIdContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_qualId;
    return this;
}

QualIdContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
QualIdContext.prototype.constructor = QualIdContext;

QualIdContext.prototype.Varid = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(entityParser.Varid);
    } else {
        return this.getToken(entityParser.Varid, i);
    }
};


QualIdContext.prototype.Self = function() {
    return this.getToken(entityParser.Self, 0);
};

QualIdContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterQualId(this);
	}
};

QualIdContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitQualId(this);
	}
};

QualIdContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitQualId(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.QualIdContext = QualIdContext;

entityParser.prototype.qualId = function() {

    var localctx = new QualIdContext(this, this._ctx, this.state);
    this.enterRule(localctx, 120, entityParser.RULE_qualId);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 552;
        _la = this._input.LA(1);
        if(!(_la===entityParser.Self || _la===entityParser.Varid)) {
        this._errHandler.recoverInline(this);
        }
        else {
        	this._errHandler.reportMatch(this);
            this.consume();
        }
        this.state = 557;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        while(_la===entityParser.T__44) {
            this.state = 553;
            this.match(entityParser.T__44);
            this.state = 554;
            this.match(entityParser.Varid);
            this.state = 559;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function QualIdsContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_qualIds;
    return this;
}

QualIdsContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
QualIdsContext.prototype.constructor = QualIdsContext;

QualIdsContext.prototype.qualId = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(QualIdContext);
    } else {
        return this.getTypedRuleContext(QualIdContext,i);
    }
};

QualIdsContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterQualIds(this);
	}
};

QualIdsContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitQualIds(this);
	}
};

QualIdsContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitQualIds(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.QualIdsContext = QualIdsContext;

entityParser.prototype.qualIds = function() {

    var localctx = new QualIdsContext(this, this._ctx, this.state);
    this.enterRule(localctx, 122, entityParser.RULE_qualIds);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 560;
        this.qualId();
        this.state = 565;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        while(_la===entityParser.T__31) {
            this.state = 561;
            this.match(entityParser.T__31);
            this.state = 562;
            this.qualId();
            this.state = 567;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function ExprsContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_exprs;
    return this;
}

ExprsContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
ExprsContext.prototype.constructor = ExprsContext;

ExprsContext.prototype.expr = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(ExprContext);
    } else {
        return this.getTypedRuleContext(ExprContext,i);
    }
};

ExprsContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterExprs(this);
	}
};

ExprsContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitExprs(this);
	}
};

ExprsContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitExprs(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.ExprsContext = ExprsContext;

entityParser.prototype.exprs = function() {

    var localctx = new ExprsContext(this, this._ctx, this.state);
    this.enterRule(localctx, 124, entityParser.RULE_exprs);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 571;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        while(((((_la - 31)) & ~0x1f) == 0 && ((1 << (_la - 31)) & ((1 << (entityParser.T__30 - 31)) | (1 << (entityParser.T__53 - 31)) | (1 << (entityParser.T__58 - 31)) | (1 << (entityParser.T__59 - 31)))) !== 0) || ((((_la - 75)) & ~0x1f) == 0 && ((1 << (_la - 75)) & ((1 << (entityParser.Self - 75)) | (1 << (entityParser.BooleanLiteral - 75)) | (1 << (entityParser.StringLiteral - 75)) | (1 << (entityParser.MultiLineString - 75)) | (1 << (entityParser.IntegerLiteral - 75)) | (1 << (entityParser.FloatingPointLiteral - 75)) | (1 << (entityParser.Varid - 75)))) !== 0)) {
            this.state = 568;
            this.expr();
            this.state = 573;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function ExprContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_expr;
    return this;
}

ExprContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
ExprContext.prototype.constructor = ExprContext;

ExprContext.prototype.variableDeclExpr = function() {
    return this.getTypedRuleContext(VariableDeclExprContext,0);
};

ExprContext.prototype.conditionalExpr = function() {
    return this.getTypedRuleContext(ConditionalExprContext,0);
};

ExprContext.prototype.simpleExpr = function() {
    return this.getTypedRuleContext(SimpleExprContext,0);
};

ExprContext.prototype.lambdaExpr = function() {
    return this.getTypedRuleContext(LambdaExprContext,0);
};

ExprContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterExpr(this);
	}
};

ExprContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitExpr(this);
	}
};

ExprContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitExpr(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.ExprContext = ExprContext;

entityParser.prototype.expr = function() {

    var localctx = new ExprContext(this, this._ctx, this.state);
    this.enterRule(localctx, 126, entityParser.RULE_expr);
    try {
        this.state = 578;
        this._errHandler.sync(this);
        var la_ = this._interp.adaptivePredict(this._input,48,this._ctx);
        switch(la_) {
        case 1:
            this.enterOuterAlt(localctx, 1);
            this.state = 574;
            this.variableDeclExpr();
            break;

        case 2:
            this.enterOuterAlt(localctx, 2);
            this.state = 575;
            this.conditionalExpr();
            break;

        case 3:
            this.enterOuterAlt(localctx, 3);
            this.state = 576;
            this.simpleExpr();
            break;

        case 4:
            this.enterOuterAlt(localctx, 4);
            this.state = 577;
            this.lambdaExpr();
            break;

        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function LambdaExprContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_lambdaExpr;
    return this;
}

LambdaExprContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
LambdaExprContext.prototype.constructor = LambdaExprContext;

LambdaExprContext.prototype.Varid = function() {
    return this.getToken(entityParser.Varid, 0);
};

LambdaExprContext.prototype.lambdaArgs = function() {
    return this.getTypedRuleContext(LambdaArgsContext,0);
};

LambdaExprContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterLambdaExpr(this);
	}
};

LambdaExprContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitLambdaExpr(this);
	}
};

LambdaExprContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitLambdaExpr(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.LambdaExprContext = LambdaExprContext;

entityParser.prototype.lambdaExpr = function() {

    var localctx = new LambdaExprContext(this, this._ctx, this.state);
    this.enterRule(localctx, 128, entityParser.RULE_lambdaExpr);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 580;
        this.match(entityParser.T__53);
        this.state = 581;
        this.match(entityParser.Varid);
        this.state = 582;
        this.match(entityParser.T__30);
        this.state = 584;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(((((_la - 55)) & ~0x1f) == 0 && ((1 << (_la - 55)) & ((1 << (entityParser.T__54 - 55)) | (1 << (entityParser.T__55 - 55)) | (1 << (entityParser.T__56 - 55)) | (1 << (entityParser.T__57 - 55)) | (1 << (entityParser.Self - 55)) | (1 << (entityParser.BooleanLiteral - 55)) | (1 << (entityParser.StringLiteral - 55)) | (1 << (entityParser.MultiLineString - 55)) | (1 << (entityParser.IntegerLiteral - 55)) | (1 << (entityParser.FloatingPointLiteral - 55)) | (1 << (entityParser.Varid - 55)))) !== 0)) {
            this.state = 583;
            this.lambdaArgs();
        }

        this.state = 586;
        this.match(entityParser.T__32);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function LambdaArgsContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_lambdaArgs;
    return this;
}

LambdaArgsContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
LambdaArgsContext.prototype.constructor = LambdaArgsContext;

LambdaArgsContext.prototype.lambdaArg = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(LambdaArgContext);
    } else {
        return this.getTypedRuleContext(LambdaArgContext,i);
    }
};

LambdaArgsContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterLambdaArgs(this);
	}
};

LambdaArgsContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitLambdaArgs(this);
	}
};

LambdaArgsContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitLambdaArgs(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.LambdaArgsContext = LambdaArgsContext;

entityParser.prototype.lambdaArgs = function() {

    var localctx = new LambdaArgsContext(this, this._ctx, this.state);
    this.enterRule(localctx, 130, entityParser.RULE_lambdaArgs);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 588;
        this.lambdaArg();
        this.state = 593;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        while(_la===entityParser.T__31) {
            this.state = 589;
            this.match(entityParser.T__31);
            this.state = 590;
            this.lambdaArg();
            this.state = 595;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function LambdaArgContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_lambdaArg;
    return this;
}

LambdaArgContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
LambdaArgContext.prototype.constructor = LambdaArgContext;

LambdaArgContext.prototype.qualId = function() {
    return this.getTypedRuleContext(QualIdContext,0);
};

LambdaArgContext.prototype.lambdaArgPrefix = function() {
    return this.getTypedRuleContext(LambdaArgPrefixContext,0);
};

LambdaArgContext.prototype.literal = function() {
    return this.getTypedRuleContext(LiteralContext,0);
};

LambdaArgContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterLambdaArg(this);
	}
};

LambdaArgContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitLambdaArg(this);
	}
};

LambdaArgContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitLambdaArg(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.LambdaArgContext = LambdaArgContext;

entityParser.prototype.lambdaArg = function() {

    var localctx = new LambdaArgContext(this, this._ctx, this.state);
    this.enterRule(localctx, 132, entityParser.RULE_lambdaArg);
    var _la = 0; // Token type
    try {
        this.state = 604;
        this._errHandler.sync(this);
        var la_ = this._interp.adaptivePredict(this._input,52,this._ctx);
        switch(la_) {
        case 1:
            this.enterOuterAlt(localctx, 1);
            this.state = 599;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            if(((((_la - 55)) & ~0x1f) == 0 && ((1 << (_la - 55)) & ((1 << (entityParser.T__54 - 55)) | (1 << (entityParser.T__55 - 55)) | (1 << (entityParser.T__56 - 55)) | (1 << (entityParser.T__57 - 55)))) !== 0)) {
                this.state = 596;
                this.lambdaArgPrefix();
                this.state = 597;
                this.match(entityParser.T__44);
            }

            this.state = 601;
            this.qualId();
            break;

        case 2:
            this.enterOuterAlt(localctx, 2);
            this.state = 602;
            this.literal();
            break;

        case 3:
            this.enterOuterAlt(localctx, 3);
            this.state = 603;
            this.qualId();
            break;

        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function LambdaArgPrefixContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_lambdaArgPrefix;
    return this;
}

LambdaArgPrefixContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
LambdaArgPrefixContext.prototype.constructor = LambdaArgPrefixContext;


LambdaArgPrefixContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterLambdaArgPrefix(this);
	}
};

LambdaArgPrefixContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitLambdaArgPrefix(this);
	}
};

LambdaArgPrefixContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitLambdaArgPrefix(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.LambdaArgPrefixContext = LambdaArgPrefixContext;

entityParser.prototype.lambdaArgPrefix = function() {

    var localctx = new LambdaArgPrefixContext(this, this._ctx, this.state);
    this.enterRule(localctx, 134, entityParser.RULE_lambdaArgPrefix);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 606;
        _la = this._input.LA(1);
        if(!(((((_la - 55)) & ~0x1f) == 0 && ((1 << (_la - 55)) & ((1 << (entityParser.T__54 - 55)) | (1 << (entityParser.T__55 - 55)) | (1 << (entityParser.T__56 - 55)) | (1 << (entityParser.T__57 - 55)))) !== 0))) {
        this._errHandler.recoverInline(this);
        }
        else {
        	this._errHandler.reportMatch(this);
            this.consume();
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function VariableDeclExprContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_variableDeclExpr;
    return this;
}

VariableDeclExprContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
VariableDeclExprContext.prototype.constructor = VariableDeclExprContext;

VariableDeclExprContext.prototype.Varid = function() {
    return this.getToken(entityParser.Varid, 0);
};

VariableDeclExprContext.prototype.Typeid = function() {
    return this.getToken(entityParser.Typeid, 0);
};

VariableDeclExprContext.prototype.CollectionTypeid = function() {
    return this.getToken(entityParser.CollectionTypeid, 0);
};

VariableDeclExprContext.prototype.simpleExpr = function() {
    return this.getTypedRuleContext(SimpleExprContext,0);
};

VariableDeclExprContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterVariableDeclExpr(this);
	}
};

VariableDeclExprContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitVariableDeclExpr(this);
	}
};

VariableDeclExprContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitVariableDeclExpr(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.VariableDeclExprContext = VariableDeclExprContext;

entityParser.prototype.variableDeclExpr = function() {

    var localctx = new VariableDeclExprContext(this, this._ctx, this.state);
    this.enterRule(localctx, 136, entityParser.RULE_variableDeclExpr);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 608;
        this.match(entityParser.T__58);
        this.state = 609;
        this.match(entityParser.Varid);
        this.state = 610;
        this.match(entityParser.T__4);
        this.state = 611;
        _la = this._input.LA(1);
        if(!(_la===entityParser.CollectionTypeid || _la===entityParser.Typeid)) {
        this._errHandler.recoverInline(this);
        }
        else {
        	this._errHandler.reportMatch(this);
            this.consume();
        }
        this.state = 614;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(_la===entityParser.T__27) {
            this.state = 612;
            this.match(entityParser.T__27);
            this.state = 613;
            this.simpleExpr();
        }

    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function ConditionalExprContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_conditionalExpr;
    return this;
}

ConditionalExprContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
ConditionalExprContext.prototype.constructor = ConditionalExprContext;

ConditionalExprContext.prototype.ifExpr = function() {
    return this.getTypedRuleContext(IfExprContext,0);
};

ConditionalExprContext.prototype.matchExpr = function() {
    return this.getTypedRuleContext(MatchExprContext,0);
};

ConditionalExprContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterConditionalExpr(this);
	}
};

ConditionalExprContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitConditionalExpr(this);
	}
};

ConditionalExprContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitConditionalExpr(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.ConditionalExprContext = ConditionalExprContext;

entityParser.prototype.conditionalExpr = function() {

    var localctx = new ConditionalExprContext(this, this._ctx, this.state);
    this.enterRule(localctx, 138, entityParser.RULE_conditionalExpr);
    try {
        this.state = 618;
        this._errHandler.sync(this);
        switch(this._input.LA(1)) {
        case entityParser.T__59:
            this.enterOuterAlt(localctx, 1);
            this.state = 616;
            this.ifExpr();
            break;
        case entityParser.Self:
        case entityParser.Varid:
            this.enterOuterAlt(localctx, 2);
            this.state = 617;
            this.matchExpr();
            break;
        default:
            throw new antlr4.error.NoViableAltException(this);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function IfExprContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_ifExpr;
    return this;
}

IfExprContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
IfExprContext.prototype.constructor = IfExprContext;

IfExprContext.prototype.logicExpr = function() {
    return this.getTypedRuleContext(LogicExprContext,0);
};

IfExprContext.prototype.exprs = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(ExprsContext);
    } else {
        return this.getTypedRuleContext(ExprsContext,i);
    }
};

IfExprContext.prototype.ifExpr = function() {
    return this.getTypedRuleContext(IfExprContext,0);
};

IfExprContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterIfExpr(this);
	}
};

IfExprContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitIfExpr(this);
	}
};

IfExprContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitIfExpr(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.IfExprContext = IfExprContext;

entityParser.prototype.ifExpr = function() {

    var localctx = new IfExprContext(this, this._ctx, this.state);
    this.enterRule(localctx, 140, entityParser.RULE_ifExpr);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 620;
        this.match(entityParser.T__59);
        this.state = 621;
        this.match(entityParser.T__30);
        this.state = 622;
        this.logicExpr();
        this.state = 623;
        this.match(entityParser.T__32);
        this.state = 624;
        this.match(entityParser.T__1);
        this.state = 625;
        this.exprs();
        this.state = 626;
        this.match(entityParser.T__2);
        this.state = 635;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(_la===entityParser.T__60) {
            this.state = 627;
            this.match(entityParser.T__60);
            this.state = 629;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            if(_la===entityParser.T__59) {
                this.state = 628;
                this.ifExpr();
            }

            this.state = 631;
            this.match(entityParser.T__1);
            this.state = 632;
            this.exprs();
            this.state = 633;
            this.match(entityParser.T__2);
        }

    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function MatchExprContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_matchExpr;
    return this;
}

MatchExprContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
MatchExprContext.prototype.constructor = MatchExprContext;

MatchExprContext.prototype.qualId = function() {
    return this.getTypedRuleContext(QualIdContext,0);
};

MatchExprContext.prototype.caseClauses = function() {
    return this.getTypedRuleContext(CaseClausesContext,0);
};

MatchExprContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterMatchExpr(this);
	}
};

MatchExprContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitMatchExpr(this);
	}
};

MatchExprContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitMatchExpr(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.MatchExprContext = MatchExprContext;

entityParser.prototype.matchExpr = function() {

    var localctx = new MatchExprContext(this, this._ctx, this.state);
    this.enterRule(localctx, 142, entityParser.RULE_matchExpr);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 637;
        this.qualId();
        this.state = 638;
        this.match(entityParser.T__61);
        this.state = 639;
        this.match(entityParser.T__1);
        this.state = 640;
        this.caseClauses();
        this.state = 641;
        this.match(entityParser.T__2);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function LogicExprContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_logicExpr;
    return this;
}

LogicExprContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
LogicExprContext.prototype.constructor = LogicExprContext;

LogicExprContext.prototype.qualId = function() {
    return this.getTypedRuleContext(QualIdContext,0);
};

LogicExprContext.prototype.literal = function() {
    return this.getTypedRuleContext(LiteralContext,0);
};

LogicExprContext.prototype.logicOperator = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(LogicOperatorContext);
    } else {
        return this.getTypedRuleContext(LogicOperatorContext,i);
    }
};

LogicExprContext.prototype.logicExpr = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(LogicExprContext);
    } else {
        return this.getTypedRuleContext(LogicExprContext,i);
    }
};

LogicExprContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterLogicExpr(this);
	}
};

LogicExprContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitLogicExpr(this);
	}
};

LogicExprContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitLogicExpr(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.LogicExprContext = LogicExprContext;

entityParser.prototype.logicExpr = function() {

    var localctx = new LogicExprContext(this, this._ctx, this.state);
    this.enterRule(localctx, 144, entityParser.RULE_logicExpr);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 645;
        this._errHandler.sync(this);
        switch(this._input.LA(1)) {
        case entityParser.Self:
        case entityParser.Varid:
            this.state = 643;
            this.qualId();
            break;
        case entityParser.BooleanLiteral:
        case entityParser.StringLiteral:
        case entityParser.MultiLineString:
        case entityParser.IntegerLiteral:
        case entityParser.FloatingPointLiteral:
            this.state = 644;
            this.literal();
            break;
        default:
            throw new antlr4.error.NoViableAltException(this);
        }
        this.state = 652;
        this._errHandler.sync(this);
        var _alt = this._interp.adaptivePredict(this._input,58,this._ctx)
        while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
            if(_alt===1) {
                this.state = 647;
                this.logicOperator();
                this.state = 648;
                this.logicExpr(); 
            }
            this.state = 654;
            this._errHandler.sync(this);
            _alt = this._interp.adaptivePredict(this._input,58,this._ctx);
        }

    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function ArithmeticExprContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_arithmeticExpr;
    return this;
}

ArithmeticExprContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
ArithmeticExprContext.prototype.constructor = ArithmeticExprContext;

ArithmeticExprContext.prototype.arithmeticTerm = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(ArithmeticTermContext);
    } else {
        return this.getTypedRuleContext(ArithmeticTermContext,i);
    }
};

ArithmeticExprContext.prototype.arithmeticExprOperator = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(ArithmeticExprOperatorContext);
    } else {
        return this.getTypedRuleContext(ArithmeticExprOperatorContext,i);
    }
};

ArithmeticExprContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterArithmeticExpr(this);
	}
};

ArithmeticExprContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitArithmeticExpr(this);
	}
};

ArithmeticExprContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitArithmeticExpr(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.ArithmeticExprContext = ArithmeticExprContext;

entityParser.prototype.arithmeticExpr = function() {

    var localctx = new ArithmeticExprContext(this, this._ctx, this.state);
    this.enterRule(localctx, 146, entityParser.RULE_arithmeticExpr);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 655;
        this.arithmeticTerm();
        this.state = 661;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        while(_la===entityParser.T__62 || _la===entityParser.T__63) {
            this.state = 656;
            this.arithmeticExprOperator();
            this.state = 657;
            this.arithmeticTerm();
            this.state = 663;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function ArithmeticExprOperatorContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_arithmeticExprOperator;
    return this;
}

ArithmeticExprOperatorContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
ArithmeticExprOperatorContext.prototype.constructor = ArithmeticExprOperatorContext;


ArithmeticExprOperatorContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterArithmeticExprOperator(this);
	}
};

ArithmeticExprOperatorContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitArithmeticExprOperator(this);
	}
};

ArithmeticExprOperatorContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitArithmeticExprOperator(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.ArithmeticExprOperatorContext = ArithmeticExprOperatorContext;

entityParser.prototype.arithmeticExprOperator = function() {

    var localctx = new ArithmeticExprOperatorContext(this, this._ctx, this.state);
    this.enterRule(localctx, 148, entityParser.RULE_arithmeticExprOperator);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 664;
        _la = this._input.LA(1);
        if(!(_la===entityParser.T__62 || _la===entityParser.T__63)) {
        this._errHandler.recoverInline(this);
        }
        else {
        	this._errHandler.reportMatch(this);
            this.consume();
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function ArithmeticTermContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_arithmeticTerm;
    return this;
}

ArithmeticTermContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
ArithmeticTermContext.prototype.constructor = ArithmeticTermContext;

ArithmeticTermContext.prototype.arithmeticFactor = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(ArithmeticFactorContext);
    } else {
        return this.getTypedRuleContext(ArithmeticFactorContext,i);
    }
};

ArithmeticTermContext.prototype.arithmeticTermOperator = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(ArithmeticTermOperatorContext);
    } else {
        return this.getTypedRuleContext(ArithmeticTermOperatorContext,i);
    }
};

ArithmeticTermContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterArithmeticTerm(this);
	}
};

ArithmeticTermContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitArithmeticTerm(this);
	}
};

ArithmeticTermContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitArithmeticTerm(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.ArithmeticTermContext = ArithmeticTermContext;

entityParser.prototype.arithmeticTerm = function() {

    var localctx = new ArithmeticTermContext(this, this._ctx, this.state);
    this.enterRule(localctx, 150, entityParser.RULE_arithmeticTerm);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 666;
        this.arithmeticFactor();
        this.state = 672;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        while(((((_la - 65)) & ~0x1f) == 0 && ((1 << (_la - 65)) & ((1 << (entityParser.T__64 - 65)) | (1 << (entityParser.T__65 - 65)) | (1 << (entityParser.T__66 - 65)))) !== 0)) {
            this.state = 667;
            this.arithmeticTermOperator();
            this.state = 668;
            this.arithmeticFactor();
            this.state = 674;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function ArithmeticTermOperatorContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_arithmeticTermOperator;
    return this;
}

ArithmeticTermOperatorContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
ArithmeticTermOperatorContext.prototype.constructor = ArithmeticTermOperatorContext;


ArithmeticTermOperatorContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterArithmeticTermOperator(this);
	}
};

ArithmeticTermOperatorContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitArithmeticTermOperator(this);
	}
};

ArithmeticTermOperatorContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitArithmeticTermOperator(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.ArithmeticTermOperatorContext = ArithmeticTermOperatorContext;

entityParser.prototype.arithmeticTermOperator = function() {

    var localctx = new ArithmeticTermOperatorContext(this, this._ctx, this.state);
    this.enterRule(localctx, 152, entityParser.RULE_arithmeticTermOperator);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 675;
        _la = this._input.LA(1);
        if(!(((((_la - 65)) & ~0x1f) == 0 && ((1 << (_la - 65)) & ((1 << (entityParser.T__64 - 65)) | (1 << (entityParser.T__65 - 65)) | (1 << (entityParser.T__66 - 65)))) !== 0))) {
        this._errHandler.recoverInline(this);
        }
        else {
        	this._errHandler.reportMatch(this);
            this.consume();
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function ArithmeticFactorContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_arithmeticFactor;
    return this;
}

ArithmeticFactorContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
ArithmeticFactorContext.prototype.constructor = ArithmeticFactorContext;

ArithmeticFactorContext.prototype.qualId = function() {
    return this.getTypedRuleContext(QualIdContext,0);
};

ArithmeticFactorContext.prototype.IntegerLiteral = function() {
    return this.getToken(entityParser.IntegerLiteral, 0);
};

ArithmeticFactorContext.prototype.FloatingPointLiteral = function() {
    return this.getToken(entityParser.FloatingPointLiteral, 0);
};

ArithmeticFactorContext.prototype.arithmeticExpr = function() {
    return this.getTypedRuleContext(ArithmeticExprContext,0);
};

ArithmeticFactorContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterArithmeticFactor(this);
	}
};

ArithmeticFactorContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitArithmeticFactor(this);
	}
};

ArithmeticFactorContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitArithmeticFactor(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.ArithmeticFactorContext = ArithmeticFactorContext;

entityParser.prototype.arithmeticFactor = function() {

    var localctx = new ArithmeticFactorContext(this, this._ctx, this.state);
    this.enterRule(localctx, 154, entityParser.RULE_arithmeticFactor);
    try {
        this.state = 684;
        this._errHandler.sync(this);
        switch(this._input.LA(1)) {
        case entityParser.Self:
        case entityParser.Varid:
            this.enterOuterAlt(localctx, 1);
            this.state = 677;
            this.qualId();
            break;
        case entityParser.IntegerLiteral:
            this.enterOuterAlt(localctx, 2);
            this.state = 678;
            this.match(entityParser.IntegerLiteral);
            break;
        case entityParser.FloatingPointLiteral:
            this.enterOuterAlt(localctx, 3);
            this.state = 679;
            this.match(entityParser.FloatingPointLiteral);
            break;
        case entityParser.T__30:
            this.enterOuterAlt(localctx, 4);
            this.state = 680;
            this.match(entityParser.T__30);
            this.state = 681;
            this.arithmeticExpr();
            this.state = 682;
            this.match(entityParser.T__32);
            break;
        default:
            throw new antlr4.error.NoViableAltException(this);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function SimpleExprContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_simpleExpr;
    return this;
}

SimpleExprContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
SimpleExprContext.prototype.constructor = SimpleExprContext;

SimpleExprContext.prototype.literal = function() {
    return this.getTypedRuleContext(LiteralContext,0);
};

SimpleExprContext.prototype.arithmeticExpr = function() {
    return this.getTypedRuleContext(ArithmeticExprContext,0);
};

SimpleExprContext.prototype.assignExpr = function() {
    return this.getTypedRuleContext(AssignExprContext,0);
};

SimpleExprContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterSimpleExpr(this);
	}
};

SimpleExprContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitSimpleExpr(this);
	}
};

SimpleExprContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitSimpleExpr(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.SimpleExprContext = SimpleExprContext;

entityParser.prototype.simpleExpr = function() {

    var localctx = new SimpleExprContext(this, this._ctx, this.state);
    this.enterRule(localctx, 156, entityParser.RULE_simpleExpr);
    try {
        this.state = 689;
        this._errHandler.sync(this);
        var la_ = this._interp.adaptivePredict(this._input,62,this._ctx);
        switch(la_) {
        case 1:
            this.enterOuterAlt(localctx, 1);
            this.state = 686;
            this.literal();
            break;

        case 2:
            this.enterOuterAlt(localctx, 2);
            this.state = 687;
            this.arithmeticExpr();
            break;

        case 3:
            this.enterOuterAlt(localctx, 3);
            this.state = 688;
            this.assignExpr();
            break;

        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function AssignExprContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_assignExpr;
    return this;
}

AssignExprContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
AssignExprContext.prototype.constructor = AssignExprContext;

AssignExprContext.prototype.qualId = function() {
    return this.getTypedRuleContext(QualIdContext,0);
};

AssignExprContext.prototype.assignValueExpr = function() {
    return this.getTypedRuleContext(AssignValueExprContext,0);
};

AssignExprContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterAssignExpr(this);
	}
};

AssignExprContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitAssignExpr(this);
	}
};

AssignExprContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitAssignExpr(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.AssignExprContext = AssignExprContext;

entityParser.prototype.assignExpr = function() {

    var localctx = new AssignExprContext(this, this._ctx, this.state);
    this.enterRule(localctx, 158, entityParser.RULE_assignExpr);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 691;
        this.qualId();
        this.state = 692;
        this.match(entityParser.T__27);
        this.state = 693;
        this.assignValueExpr();
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function AssignValueExprContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_assignValueExpr;
    return this;
}

AssignValueExprContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
AssignValueExprContext.prototype.constructor = AssignValueExprContext;

AssignValueExprContext.prototype.qualId = function() {
    return this.getTypedRuleContext(QualIdContext,0);
};

AssignValueExprContext.prototype.literal = function() {
    return this.getTypedRuleContext(LiteralContext,0);
};

AssignValueExprContext.prototype.arithmeticExpr = function() {
    return this.getTypedRuleContext(ArithmeticExprContext,0);
};

AssignValueExprContext.prototype.Typeid = function() {
    return this.getToken(entityParser.Typeid, 0);
};

AssignValueExprContext.prototype.CollectionTypeid = function() {
    return this.getToken(entityParser.CollectionTypeid, 0);
};

AssignValueExprContext.prototype.qualIds = function() {
    return this.getTypedRuleContext(QualIdsContext,0);
};

AssignValueExprContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterAssignValueExpr(this);
	}
};

AssignValueExprContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitAssignValueExpr(this);
	}
};

AssignValueExprContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitAssignValueExpr(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.AssignValueExprContext = AssignValueExprContext;

entityParser.prototype.assignValueExpr = function() {

    var localctx = new AssignValueExprContext(this, this._ctx, this.state);
    this.enterRule(localctx, 160, entityParser.RULE_assignValueExpr);
    var _la = 0; // Token type
    try {
        this.state = 704;
        this._errHandler.sync(this);
        var la_ = this._interp.adaptivePredict(this._input,64,this._ctx);
        switch(la_) {
        case 1:
            this.enterOuterAlt(localctx, 1);
            this.state = 695;
            this.qualId();
            break;

        case 2:
            this.enterOuterAlt(localctx, 2);
            this.state = 696;
            this.literal();
            break;

        case 3:
            this.enterOuterAlt(localctx, 3);
            this.state = 697;
            this.arithmeticExpr();
            break;

        case 4:
            this.enterOuterAlt(localctx, 4);
            this.state = 698;
            _la = this._input.LA(1);
            if(!(_la===entityParser.CollectionTypeid || _la===entityParser.Typeid)) {
            this._errHandler.recoverInline(this);
            }
            else {
            	this._errHandler.reportMatch(this);
                this.consume();
            }
            this.state = 699;
            this.match(entityParser.T__30);
            this.state = 701;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            if(_la===entityParser.Self || _la===entityParser.Varid) {
                this.state = 700;
                this.qualIds();
            }

            this.state = 703;
            this.match(entityParser.T__32);
            break;

        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function BlockExprContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_blockExpr;
    return this;
}

BlockExprContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
BlockExprContext.prototype.constructor = BlockExprContext;

BlockExprContext.prototype.caseClauses = function() {
    return this.getTypedRuleContext(CaseClausesContext,0);
};

BlockExprContext.prototype.block = function() {
    return this.getTypedRuleContext(BlockContext,0);
};

BlockExprContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterBlockExpr(this);
	}
};

BlockExprContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitBlockExpr(this);
	}
};

BlockExprContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitBlockExpr(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.BlockExprContext = BlockExprContext;

entityParser.prototype.blockExpr = function() {

    var localctx = new BlockExprContext(this, this._ctx, this.state);
    this.enterRule(localctx, 162, entityParser.RULE_blockExpr);
    try {
        this.state = 714;
        this._errHandler.sync(this);
        var la_ = this._interp.adaptivePredict(this._input,65,this._ctx);
        switch(la_) {
        case 1:
            this.enterOuterAlt(localctx, 1);
            this.state = 706;
            this.match(entityParser.T__1);
            this.state = 707;
            this.caseClauses();
            this.state = 708;
            this.match(entityParser.T__2);
            break;

        case 2:
            this.enterOuterAlt(localctx, 2);
            this.state = 710;
            this.match(entityParser.T__1);
            this.state = 711;
            this.block();
            this.state = 712;
            this.match(entityParser.T__2);
            break;

        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function BlockContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_block;
    return this;
}

BlockContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
BlockContext.prototype.constructor = BlockContext;

BlockContext.prototype.exprs = function() {
    return this.getTypedRuleContext(ExprsContext,0);
};

BlockContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterBlock(this);
	}
};

BlockContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitBlock(this);
	}
};

BlockContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitBlock(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.BlockContext = BlockContext;

entityParser.prototype.block = function() {

    var localctx = new BlockContext(this, this._ctx, this.state);
    this.enterRule(localctx, 164, entityParser.RULE_block);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 716;
        this.exprs();
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function CaseClausesContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_caseClauses;
    return this;
}

CaseClausesContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
CaseClausesContext.prototype.constructor = CaseClausesContext;

CaseClausesContext.prototype.caseClause = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(CaseClauseContext);
    } else {
        return this.getTypedRuleContext(CaseClauseContext,i);
    }
};

CaseClausesContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterCaseClauses(this);
	}
};

CaseClausesContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitCaseClauses(this);
	}
};

CaseClausesContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitCaseClauses(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.CaseClausesContext = CaseClausesContext;

entityParser.prototype.caseClauses = function() {

    var localctx = new CaseClausesContext(this, this._ctx, this.state);
    this.enterRule(localctx, 166, entityParser.RULE_caseClauses);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 719; 
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        do {
            this.state = 718;
            this.caseClause();
            this.state = 721; 
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        } while(_la===entityParser.T__67);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function CaseClauseContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_caseClause;
    return this;
}

CaseClauseContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
CaseClauseContext.prototype.constructor = CaseClauseContext;

CaseClauseContext.prototype.patternExpr = function() {
    return this.getTypedRuleContext(PatternExprContext,0);
};

CaseClauseContext.prototype.block = function() {
    return this.getTypedRuleContext(BlockContext,0);
};

CaseClauseContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterCaseClause(this);
	}
};

CaseClauseContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitCaseClause(this);
	}
};

CaseClauseContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitCaseClause(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.CaseClauseContext = CaseClauseContext;

entityParser.prototype.caseClause = function() {

    var localctx = new CaseClauseContext(this, this._ctx, this.state);
    this.enterRule(localctx, 168, entityParser.RULE_caseClause);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 723;
        this.match(entityParser.T__67);
        this.state = 724;
        this.patternExpr();
        this.state = 725;
        this.match(entityParser.T__68);
        this.state = 726;
        this.block();
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function PatternExprContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_patternExpr;
    return this;
}

PatternExprContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
PatternExprContext.prototype.constructor = PatternExprContext;

PatternExprContext.prototype.pattern = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(PatternContext);
    } else {
        return this.getTypedRuleContext(PatternContext,i);
    }
};

PatternExprContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterPatternExpr(this);
	}
};

PatternExprContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitPatternExpr(this);
	}
};

PatternExprContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitPatternExpr(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.PatternExprContext = PatternExprContext;

entityParser.prototype.patternExpr = function() {

    var localctx = new PatternExprContext(this, this._ctx, this.state);
    this.enterRule(localctx, 170, entityParser.RULE_patternExpr);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 728;
        this.pattern();
        this.state = 733;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        while(_la===entityParser.T__69) {
            this.state = 729;
            this.match(entityParser.T__69);
            this.state = 730;
            this.pattern();
            this.state = 735;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function PatternContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_pattern;
    return this;
}

PatternContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
PatternContext.prototype.constructor = PatternContext;

PatternContext.prototype.literal = function() {
    return this.getTypedRuleContext(LiteralContext,0);
};

PatternContext.prototype.Typeid = function() {
    return this.getToken(entityParser.Typeid, 0);
};

PatternContext.prototype.qualId = function() {
    return this.getTypedRuleContext(QualIdContext,0);
};

PatternContext.prototype.Varid = function() {
    return this.getToken(entityParser.Varid, 0);
};

PatternContext.prototype.CollectionTypeid = function() {
    return this.getToken(entityParser.CollectionTypeid, 0);
};

PatternContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterPattern(this);
	}
};

PatternContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitPattern(this);
	}
};

PatternContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitPattern(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.PatternContext = PatternContext;

entityParser.prototype.pattern = function() {

    var localctx = new PatternContext(this, this._ctx, this.state);
    this.enterRule(localctx, 172, entityParser.RULE_pattern);
    var _la = 0; // Token type
    try {
        this.state = 748;
        this._errHandler.sync(this);
        switch(this._input.LA(1)) {
        case entityParser.BooleanLiteral:
        case entityParser.StringLiteral:
        case entityParser.MultiLineString:
        case entityParser.IntegerLiteral:
        case entityParser.FloatingPointLiteral:
            this.enterOuterAlt(localctx, 1);
            this.state = 736;
            this.literal();
            break;
        case entityParser.Typeid:
            this.enterOuterAlt(localctx, 2);
            this.state = 737;
            this.match(entityParser.Typeid);
            this.state = 743;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            if(_la===entityParser.T__30) {
                this.state = 738;
                this.match(entityParser.T__30);
                this.state = 740;
                this._errHandler.sync(this);
                _la = this._input.LA(1);
                if(_la===entityParser.Self || _la===entityParser.Varid) {
                    this.state = 739;
                    this.qualId();
                }

                this.state = 742;
                this.match(entityParser.T__32);
            }

            break;
        case entityParser.Varid:
            this.enterOuterAlt(localctx, 3);
            this.state = 745;
            this.match(entityParser.Varid);
            this.state = 746;
            this.match(entityParser.T__4);
            this.state = 747;
            _la = this._input.LA(1);
            if(!(_la===entityParser.CollectionTypeid || _la===entityParser.Typeid)) {
            this._errHandler.recoverInline(this);
            }
            else {
            	this._errHandler.reportMatch(this);
                this.consume();
            }
            break;
        default:
            throw new antlr4.error.NoViableAltException(this);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function ArithmeticOperatorContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_arithmeticOperator;
    return this;
}

ArithmeticOperatorContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
ArithmeticOperatorContext.prototype.constructor = ArithmeticOperatorContext;


ArithmeticOperatorContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterArithmeticOperator(this);
	}
};

ArithmeticOperatorContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitArithmeticOperator(this);
	}
};

ArithmeticOperatorContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitArithmeticOperator(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.ArithmeticOperatorContext = ArithmeticOperatorContext;

entityParser.prototype.arithmeticOperator = function() {

    var localctx = new ArithmeticOperatorContext(this, this._ctx, this.state);
    this.enterRule(localctx, 174, entityParser.RULE_arithmeticOperator);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 750;
        _la = this._input.LA(1);
        if(!(((((_la - 63)) & ~0x1f) == 0 && ((1 << (_la - 63)) & ((1 << (entityParser.T__62 - 63)) | (1 << (entityParser.T__63 - 63)) | (1 << (entityParser.T__64 - 63)) | (1 << (entityParser.T__65 - 63)) | (1 << (entityParser.T__66 - 63)) | (1 << (entityParser.T__70 - 63)))) !== 0))) {
        this._errHandler.recoverInline(this);
        }
        else {
        	this._errHandler.reportMatch(this);
            this.consume();
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function LogicOperatorContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = entityParser.RULE_logicOperator;
    return this;
}

LogicOperatorContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
LogicOperatorContext.prototype.constructor = LogicOperatorContext;


LogicOperatorContext.prototype.enterRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.enterLogicOperator(this);
	}
};

LogicOperatorContext.prototype.exitRule = function(listener) {
    if(listener instanceof entityListener ) {
        listener.exitLogicOperator(this);
	}
};

LogicOperatorContext.prototype.accept = function(visitor) {
    if ( visitor instanceof entityVisitor ) {
        return visitor.visitLogicOperator(this);
    } else {
        return visitor.visitChildren(this);
    }
};




entityParser.LogicOperatorContext = LogicOperatorContext;

entityParser.prototype.logicOperator = function() {

    var localctx = new LogicOperatorContext(this, this._ctx, this.state);
    this.enterRule(localctx, 176, entityParser.RULE_logicOperator);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 752;
        _la = this._input.LA(1);
        if(!(((((_la - 42)) & ~0x1f) == 0 && ((1 << (_la - 42)) & ((1 << (entityParser.T__41 - 42)) | (1 << (entityParser.T__42 - 42)) | (1 << (entityParser.T__45 - 42)) | (1 << (entityParser.T__46 - 42)) | (1 << (entityParser.T__47 - 42)) | (1 << (entityParser.T__48 - 42)) | (1 << (entityParser.T__71 - 42)))) !== 0))) {
        this._errHandler.recoverInline(this);
        }
        else {
        	this._errHandler.reportMatch(this);
            this.consume();
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


exports.entityParser = entityParser;
