/* @flow */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import momentjs from 'moment'
import { Mutation } from 'react-apollo'
import { mutations } from '../graphql'

type Props = {
  branches: PropTypes.array.isRequired,
  onClosed: PropTypes.func.isRequired
}
export default class BranchList extends Component<Props> {
  constructor(props) {
    super(props)
    this.state = {
      branchOnClosed: false
    }
  }
  async handleClosedBranch(mutation, data) {
    if (!confirm('Are you sure you want to close this branch?')) {
      return
    }
    const input = {
        "author": data.author,
        "closed": true,
        "hash": data.hash,
        "merged": data.merged,
        "message": data.message,
        "originCommitHash": data.originCommitHash,
        "id": data.id
      }
    await mutation({ variables: { input } })
    let setItemDisable = {}
    setItemDisable[data.id.toString()] = true
    this.setState(setItemDisable)
  }

  render() {
    const { branches, onClosed } = this.props
    return (
      <List>
        {branches.map(s =>
          <ListItem
            key={s.id.toString()}
            button
            >
            <Link to={`/schema/${s.hash}/edit`} style={{ textDecoration: 'none' }}>
              <ListItemText
                title={momentjs(new Date(s.createdAt)).format('llll')}
                primary={`${s.hash.slice(0, 6)} ${s.message} ${s.merged ? '- (merged)' : ''} ${(s.closed || this.state[s.id.toString()]) ? '- (closed)' : ''}`}
                secondary={`by ${s.author} ${momentjs(new Date(s.createdAt)).fromNow()}`}
              />
            </Link>

            {s.closed||s.merged ? null : 
            <Mutation mutation={mutations.setClosedBranch} /*onCompleted={}*/>
              {(setClosedBranch, { data }) => (
                <ListItemSecondaryAction>
                  <IconButton aria-label="Closed Branch" onClick={_ => this.handleClosedBranch(setClosedBranch, s)} disabled={(s.closed || this.state[s.id.toString()]) ? true : false}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </Mutation>
            }
          </ListItem>
        )}
      </List>
    )
  }
}
