import * as R from 'ramda'
import StringBuilder from 'string-builder'

const UNARY_FILTERS = new Set([
  'isEmpty',
  'notEmpty'
])

const formatAccessControlToDSL = (sb, tabPos) => (acl) => {
  const formatAclLambda = (sb, tabPos) => config => {
    sb.appendLine()
    R.forEach(_ => sb.append(` `))(R.range(0, tabPos))
    sb.append(`~${config.name}(`)
    config.inputs.forEach((input, idx) => {
      if(idx > 0) {
        sb.append(", ")
      }
      sb.append(`${input.source ? input.source + '.' : ''}${input.value}`)
    })
    sb.append(`) withError "${config.errorMessage}"`)
    sb.appendLine()
  }
  R.forEach(_ => sb.append(` `))(R.range(0, tabPos))
  sb.append(acl.name)
  const isEmployee = (lambdas) => {
    if(lambdas.length === 1 && lambdas[0].lambda === 'includes' && lambdas[0].inputs.length === 2) {
      const timasClientID = (lambdas[0].inputs||[]).find(_ => _.source === "ViewerContext" && _.value === 'timasClientID');
      const laskarAppPattern = (lambdas[0].inputs||[]).find(_ => _.source === null && _.value === 'laskar-app-');
      return timasClientID && laskarAppPattern
    }
    return false
  }
  if(acl.lambdas && acl.lambdas.length > 0) {
    if(isEmployee(acl.lambdas)) {
      sb.append(" employee")
    } else {
      sb.append(" {")
      R.forEach(formatAclLambda(sb, tabPos + 2), acl.lambdas)
      R.forEach(_ => sb.append(` `))(R.range(0, tabPos))
      sb.append("}")
    }
  } else if(acl.authorization === true) {
    sb.append(" always")
  } else if(acl.authorization === false) {
    sb.append(" forbidden")
  }
  sb.appendLine()
}

const formatStoredProperty = (entityObject) => {
  const dsl = R.map(({name, type, meta}) => `${name} : ${type}`, R.values(entityObject.body.storedProps))
  return `storedProps {\n    ${dsl.join("\n    ")}\n  }`
}

const formatEntityStoredPropMetaToDSL = (sb, tabPos) => (value, name) => {
  R.forEach(_ => sb.append(' '))(R.range(0, tabPos))
  sb.append(`${name}`)
  switch(name) {
    case "searchable":
    case "searchableKeyword":
    case "unique":
    case "identity":
      break;
    default:
      if(typeof value === 'object') {
        sb.append(` {`).appendLine()
        R.forEachObjIndexed(formatEntityStoredPropMetaToDSL(sb, tabPos + 2), value)
        R.forEach(_ => sb.append(' '))(R.range(0, tabPos))
        sb.append('}')
      } else if(value.toString().startsWith('/')) {
        sb.append(` : "${value}"`)
      } else {
        sb.append(` : ${value}`)
      }
  }
  sb.appendLine()
}

const formatEntityIndexToDSL = (sb, tabPos) => (index, name) => {
  R.forEach(_ => sb.append(' '))(R.range(0, tabPos))
  sb.append(`${name} {`).appendLine()
  R.forEach(indexField => {
    R.forEach(_ => sb.append(' '))(R.range(0, tabPos + 2))
    sb.append(`${indexField.property} ${indexField.sort}`)
    sb.appendLine()
  }, index)
  R.forEach(_ => sb.append(' '))(R.range(0, tabPos))
  sb.append(`}`).appendLine()
}

const formatEntityStoredPropToDSL = (sb, tabPos) => (storedProp, name) => {
  R.forEach(_ => sb.append(' '))(R.range(0, tabPos))
  sb.append(`${name}: ${storedProp.type}`)
  const validMeta = R.filter(prop => !!prop, storedProp.meta)
  if (!R.isEmpty(validMeta)) {
    sb.append(' {').appendLine()
    R.forEachObjIndexed(formatEntityStoredPropMetaToDSL(sb, 6), validMeta)
    R.forEach(_ => sb.append(' '))(R.range(0, tabPos))
    sb.append('}')
  }
  sb.appendLine()
}

const formatEntityComputedPropToDSL = (sb, tabPos) => (computedProp, name) => {
  let propType = computedProp.type
  if (computedProp.isCollection) {
     propType = `[]${computedProp.type}`
  }
  if (computedProp.isNullable) {
     propType = `${propType}?`
  }
  R.forEach(_ => sb.append(' '))(R.range(0, tabPos))
  sb.append(`${name}: ${propType}`)
  sb.append(' {').appendLine()
  R.forEachObjIndexed(formatQueryMetaToDSL(sb, 6), computedProp.meta)
  R.forEach(_ => sb.append(' '))(R.range(0, tabPos))
  sb.append(`}`).appendLine()
}

const formatEntityToDSL = (sb) => (entityObject) => {
  sb.append(`entity ${entityObject.name} {`).appendLine()
  // 1. Stringify acl
  // 2. Stringify storedProps with attributes
  // 3. Stringify computedProps with attributes
  sb.append(`  accessControl {`).appendLine()
  R.forEach(prop => {
    formatAccessControlToDSL(sb, 4)(entityObject.body.accessControl[prop])
  }, ['list', 'read', 'create', 'update', 'delete'])
  sb.append(`  }`).appendLine()
  sb.append(`  storedProps {`).appendLine()
  R.forEachObjIndexed(formatEntityStoredPropToDSL(sb, 4), entityObject.body.storedProps);
  sb.append(`  }`).appendLine()
  if(entityObject.body.indexes && Object.keys(entityObject.body.indexes)) {
    sb.append(`  index {`).appendLine()
    R.forEachObjIndexed(formatEntityIndexToDSL(sb, 4), entityObject.body.indexes)
    sb.append(`  }`).appendLine()
  }
  if(Object.keys(entityObject.body.computedProps).length > 0) {
    sb.append(`  computedProps {`).appendLine()
    R.forEachObjIndexed(formatEntityComputedPropToDSL(sb, 4), entityObject.body.computedProps)
    sb.append(`  }`).appendLine()
  }
  sb.append(`}`).appendLine()
  return sb
}

const formatField = (prop) => {
  return prop.isRelative ? `self.${prop.field}` : prop.field;
}

const formatOperator = (operator) => {
  switch(operator) {
    case "equals":
      return '=';
    case "eq":
      return '=';
    case "lt":
      return '<';
    case "lessThan":
      return '<';
    case 'lte':
      return '<=';
    case 'lessThanEquals':
      return '<=';
    case 'gt':
      return '>';
    case 'greaterThan':
      return '>';
    case 'gte':
      return '>=';
    case 'greaterThanEqual':
      return '>=';
    case 'like':
      return 'like';
    case 'isEmpty':
      return 'IS NULL';
    case 'notEmpty':
      return 'IS NOT NULL';
  }
}

const formatValue = (prop) => {
  if (prop.isNull) {
    return "null"
  } else if(prop.isLiteral){
    return prop.raw
  } else if(prop.isRelative){
    return `self.${prop.raw}`;
  } else {
    return prop.raw;
  }
}

const formatQueryMetaFilterToDSL = (sb, tabPos) => (prop) => {
  switch(prop.__type) {
    case 'OPERAND':
      sb.append(`${formatValue(prop)}`);
      break;
    case 'OPERATOR':
      sb.append(` ${formatOperator(prop.name)}`)
      if(!UNARY_FILTERS.has(prop.name)) {
        sb.append(' ')
      }
      break;
    default:
      if(prop && prop.raw) {
        sb.appendLine()
        R.forEach(_ => sb.append(` `))(R.range(0, tabPos))
        sb.append(`${prop.raw} `)
      }
  }
}

const formatQueryMetaSortToDSL = (sb, tabPos) => prop => {
  sb.append(`${prop.name} ${prop.sort.toUpperCase()}`)
  sb.appendLine()
}

const formatQueryRawToDsl = (sb, tabPos) => (value) => {
  // Params
  R.forEach(_ => sb.append(' '))(R.range(0, tabPos + 2))
  sb.append(`params {`).appendLine()
  R.forEachObjIndexed((param, name) => {
    R.forEach(_ => sb.append(' '))(R.range(0, tabPos + 4))
    sb.append(`${name} : ${param.type ? param.type + '.' : ''}${formatValue(param)}`).appendLine()
  }, value.params)
  R.forEach(_ => sb.append(' '))(R.range(0, tabPos + 2))
  sb.append(`}`).appendLine()
  // Query
  R.forEach(_ => sb.append(' '))(R.range(0, tabPos + 2))
  sb.append(`query {`).appendLine()
  R.forEach(_ => sb.append(' '))(R.range(0, tabPos + 4))
  sb.append(`"""`).appendLine()
  value.query.split("\n").forEach(line => {
    if(line.length === 0) return
    R.forEach(_ => sb.append(' '))(R.range(0, tabPos + 4))
    sb.append(`${line}`).appendLine()
  })
  R.forEach(_ => sb.append(' '))(R.range(0, tabPos + 4))
  sb.append(`"""`).appendLine()
  R.forEach(_ => sb.append(' '))(R.range(0, tabPos + 2))
  sb.append(`}`).appendLine()
}

const formatQueryCacheToDsl = (sb, tabPos) => (value) => {
  // Params
  switch(value.strategy) {
    case 'TTL':
      R.forEach(_ => sb.append(' '))(R.range(0, tabPos))
      sb.append(`ttl {`).appendLine()
      R.forEach(_ => sb.append(' '))(R.range(0, tabPos + 2))
      sb.append(`key :`)
      R.forEach((key) => sb.append(` ${key.name}`), value.key)
      sb.appendLine()
      R.forEach(_ => sb.append(' '))(R.range(0, tabPos + 2))
      sb.append(`seconds : ${value.options.ttlSecond}`)
      sb.appendLine()
      R.forEach(_ => sb.append(' '))(R.range(0, tabPos))
      sb.append('}').appendLine()
      break;
    case 'NEVER':
      R.forEach(_ => sb.append(' '))(R.range(0, tabPos))
      sb.append('never').appendLine()
      break;
  }
}

const formatQueryMetaToDSL = (sb, tabPos) => (value, name) => {
  if(!value) {
    return
  } else if (name === 'rawQuery' && value && value.query) {
    R.forEach(_ => sb.append(' '))(R.range(0, tabPos))
    sb.append('rawQuery {').appendLine()
    formatQueryRawToDsl(sb, tabPos)(value)
    R.forEach(_ => sb.append(' '))(R.range(0, tabPos))
    sb.append('}').appendLine()
    return
  } else if (name === 'cache') {
    R.forEach(_ => sb.append(' '))(R.range(0, tabPos))
    sb.append('cache {').appendLine()
    formatQueryCacheToDsl(sb, tabPos + 2)(value)
    R.forEach(_ => sb.append(' '))(R.range(0, tabPos))
    sb.append('}').appendLine()
    return
  } else if (Array.isArray(value) && value.length === 0) {
    return
  }
  R.forEach(_ => sb.append(' '))(R.range(0, tabPos))
  sb.append(`${name}`)
  sb.append(` {`).appendLine()
  R.forEach(_ => sb.append(` `))(R.range(0, tabPos + 2))
  R.forEach(value => {
    switch(value.__type) {
      case "SQL":
        sb.append(`"""`).append(value)
        R.forEach(_ => sb.append(' '))(R.range(0, tabPos))
        sb.append(`"""`)
        break;
      case "FILTER":
        R.forEach(formatQueryMetaFilterToDSL(sb, tabPos + 2), value.data)
        sb.appendLine()
        break;
      case "ORDER":
        R.addIndex(R.forEach)(formatQueryMetaSortToDSL(sb, tabPos + 2), value.data)
        break;
    }
  }, value)
  R.forEach(_ => sb.append(' '))(R.range(0, tabPos))
  sb.append('}')
  sb.appendLine()
}

const formatEnumToDSL = (sb) => (objectSchema) => {
  sb.append(`enum ${objectSchema.name} {`).appendLine()
  R.forEach(value => sb.append(`  ${value.value} ${value.description === null ? '' : `: "${value.description}"`}`).appendLine(), objectSchema.body.values)
  sb.append(`}`)
  return sb
}

const formatArgs = (args = []) => args.reduce((res, arg) => res += `${arg.name} : ${arg.type},`,"").replace(/,$/,'')

const formatQueryToDSL = (sb) => (objectSchema) => {
  const acl = objectSchema.body.acl || 'read';
  const type = objectSchema.body.isCollection ? `[]${objectSchema.body.type}` : objectSchema.body.type
  sb.append(`acl:${acl}`).appendLine();
  sb.append(`query ${objectSchema.name}(${formatArgs(objectSchema.body.args)}) : ${type} {`).appendLine()
  R.forEachObjIndexed(formatQueryMetaToDSL(sb, 2), objectSchema.body.meta)
  sb.append(`}`)
  return sb
}

export const format = (objectSchema) => {
  if(!objectSchema) return ''
  const sb = new StringBuilder()
  if (objectSchema.__type === "Enum") {
    return formatEnumToDSL(sb)(objectSchema).toString();
  } else if (objectSchema.__type === "Entity" ) {
    return formatEntityToDSL(sb)(objectSchema).toString()
  } else if(objectSchema.__type === "Query") {
    return formatQueryToDSL(sb)(objectSchema).toString()
  }
}
