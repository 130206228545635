// Generated from ./grammar/entity.g4 by ANTLR 4.7.1
// jshint ignore: start
var antlr4 = require('antlr4/index');

// This class defines a complete listener for a parse tree produced by entityParser.
function entityListener() {
	antlr4.tree.ParseTreeListener.call(this);
	return this;
}

entityListener.prototype = Object.create(antlr4.tree.ParseTreeListener.prototype);
entityListener.prototype.constructor = entityListener;

// Enter a parse tree produced by entityParser#main.
entityListener.prototype.enterMain = function(ctx) {
};

// Exit a parse tree produced by entityParser#main.
entityListener.prototype.exitMain = function(ctx) {
};


// Enter a parse tree produced by entityParser#entityDef.
entityListener.prototype.enterEntityDef = function(ctx) {
};

// Exit a parse tree produced by entityParser#entityDef.
entityListener.prototype.exitEntityDef = function(ctx) {
};


// Enter a parse tree produced by entityParser#entityBlock.
entityListener.prototype.enterEntityBlock = function(ctx) {
};

// Exit a parse tree produced by entityParser#entityBlock.
entityListener.prototype.exitEntityBlock = function(ctx) {
};


// Enter a parse tree produced by entityParser#enumerationDef.
entityListener.prototype.enterEnumerationDef = function(ctx) {
};

// Exit a parse tree produced by entityParser#enumerationDef.
entityListener.prototype.exitEnumerationDef = function(ctx) {
};


// Enter a parse tree produced by entityParser#enumerationValue.
entityListener.prototype.enterEnumerationValue = function(ctx) {
};

// Exit a parse tree produced by entityParser#enumerationValue.
entityListener.prototype.exitEnumerationValue = function(ctx) {
};


// Enter a parse tree produced by entityParser#accessControlDef.
entityListener.prototype.enterAccessControlDef = function(ctx) {
};

// Exit a parse tree produced by entityParser#accessControlDef.
entityListener.prototype.exitAccessControlDef = function(ctx) {
};


// Enter a parse tree produced by entityParser#accessControl.
entityListener.prototype.enterAccessControl = function(ctx) {
};

// Exit a parse tree produced by entityParser#accessControl.
entityListener.prototype.exitAccessControl = function(ctx) {
};


// Enter a parse tree produced by entityParser#accessControlValue.
entityListener.prototype.enterAccessControlValue = function(ctx) {
};

// Exit a parse tree produced by entityParser#accessControlValue.
entityListener.prototype.exitAccessControlValue = function(ctx) {
};


// Enter a parse tree produced by entityParser#accessControlType.
entityListener.prototype.enterAccessControlType = function(ctx) {
};

// Exit a parse tree produced by entityParser#accessControlType.
entityListener.prototype.exitAccessControlType = function(ctx) {
};


// Enter a parse tree produced by entityParser#accessControlBlock.
entityListener.prototype.enterAccessControlBlock = function(ctx) {
};

// Exit a parse tree produced by entityParser#accessControlBlock.
entityListener.prototype.exitAccessControlBlock = function(ctx) {
};


// Enter a parse tree produced by entityParser#accessControlSuccess.
entityListener.prototype.enterAccessControlSuccess = function(ctx) {
};

// Exit a parse tree produced by entityParser#accessControlSuccess.
entityListener.prototype.exitAccessControlSuccess = function(ctx) {
};


// Enter a parse tree produced by entityParser#accessControlFailure.
entityListener.prototype.enterAccessControlFailure = function(ctx) {
};

// Exit a parse tree produced by entityParser#accessControlFailure.
entityListener.prototype.exitAccessControlFailure = function(ctx) {
};


// Enter a parse tree produced by entityParser#accessControlAuthorization.
entityListener.prototype.enterAccessControlAuthorization = function(ctx) {
};

// Exit a parse tree produced by entityParser#accessControlAuthorization.
entityListener.prototype.exitAccessControlAuthorization = function(ctx) {
};


// Enter a parse tree produced by entityParser#accessControlLambda.
entityListener.prototype.enterAccessControlLambda = function(ctx) {
};

// Exit a parse tree produced by entityParser#accessControlLambda.
entityListener.prototype.exitAccessControlLambda = function(ctx) {
};


// Enter a parse tree produced by entityParser#accessControlAuthorizationValue.
entityListener.prototype.enterAccessControlAuthorizationValue = function(ctx) {
};

// Exit a parse tree produced by entityParser#accessControlAuthorizationValue.
entityListener.prototype.exitAccessControlAuthorizationValue = function(ctx) {
};


// Enter a parse tree produced by entityParser#propsDef.
entityListener.prototype.enterPropsDef = function(ctx) {
};

// Exit a parse tree produced by entityParser#propsDef.
entityListener.prototype.exitPropsDef = function(ctx) {
};


// Enter a parse tree produced by entityParser#storedProp.
entityListener.prototype.enterStoredProp = function(ctx) {
};

// Exit a parse tree produced by entityParser#storedProp.
entityListener.prototype.exitStoredProp = function(ctx) {
};


// Enter a parse tree produced by entityParser#metaPropertyDef.
entityListener.prototype.enterMetaPropertyDef = function(ctx) {
};

// Exit a parse tree produced by entityParser#metaPropertyDef.
entityListener.prototype.exitMetaPropertyDef = function(ctx) {
};


// Enter a parse tree produced by entityParser#metaPropertyValidationDef.
entityListener.prototype.enterMetaPropertyValidationDef = function(ctx) {
};

// Exit a parse tree produced by entityParser#metaPropertyValidationDef.
entityListener.prototype.exitMetaPropertyValidationDef = function(ctx) {
};


// Enter a parse tree produced by entityParser#metaPropertyValidation.
entityListener.prototype.enterMetaPropertyValidation = function(ctx) {
};

// Exit a parse tree produced by entityParser#metaPropertyValidation.
entityListener.prototype.exitMetaPropertyValidation = function(ctx) {
};


// Enter a parse tree produced by entityParser#metaPropertyValidationRegexp.
entityListener.prototype.enterMetaPropertyValidationRegexp = function(ctx) {
};

// Exit a parse tree produced by entityParser#metaPropertyValidationRegexp.
entityListener.prototype.exitMetaPropertyValidationRegexp = function(ctx) {
};


// Enter a parse tree produced by entityParser#metaPropertyValidationFailure.
entityListener.prototype.enterMetaPropertyValidationFailure = function(ctx) {
};

// Exit a parse tree produced by entityParser#metaPropertyValidationFailure.
entityListener.prototype.exitMetaPropertyValidationFailure = function(ctx) {
};


// Enter a parse tree produced by entityParser#computedDef.
entityListener.prototype.enterComputedDef = function(ctx) {
};

// Exit a parse tree produced by entityParser#computedDef.
entityListener.prototype.exitComputedDef = function(ctx) {
};


// Enter a parse tree produced by entityParser#computedProp.
entityListener.prototype.enterComputedProp = function(ctx) {
};

// Exit a parse tree produced by entityParser#computedProp.
entityListener.prototype.exitComputedProp = function(ctx) {
};


// Enter a parse tree produced by entityParser#computedPropType.
entityListener.prototype.enterComputedPropType = function(ctx) {
};

// Exit a parse tree produced by entityParser#computedPropType.
entityListener.prototype.exitComputedPropType = function(ctx) {
};


// Enter a parse tree produced by entityParser#metaComputedProp.
entityListener.prototype.enterMetaComputedProp = function(ctx) {
};

// Exit a parse tree produced by entityParser#metaComputedProp.
entityListener.prototype.exitMetaComputedProp = function(ctx) {
};


// Enter a parse tree produced by entityParser#computedPropRel.
entityListener.prototype.enterComputedPropRel = function(ctx) {
};

// Exit a parse tree produced by entityParser#computedPropRel.
entityListener.prototype.exitComputedPropRel = function(ctx) {
};


// Enter a parse tree produced by entityParser#indexDef.
entityListener.prototype.enterIndexDef = function(ctx) {
};

// Exit a parse tree produced by entityParser#indexDef.
entityListener.prototype.exitIndexDef = function(ctx) {
};


// Enter a parse tree produced by entityParser#indexBlock.
entityListener.prototype.enterIndexBlock = function(ctx) {
};

// Exit a parse tree produced by entityParser#indexBlock.
entityListener.prototype.exitIndexBlock = function(ctx) {
};


// Enter a parse tree produced by entityParser#indexField.
entityListener.prototype.enterIndexField = function(ctx) {
};

// Exit a parse tree produced by entityParser#indexField.
entityListener.prototype.exitIndexField = function(ctx) {
};


// Enter a parse tree produced by entityParser#queriesDef.
entityListener.prototype.enterQueriesDef = function(ctx) {
};

// Exit a parse tree produced by entityParser#queriesDef.
entityListener.prototype.exitQueriesDef = function(ctx) {
};


// Enter a parse tree produced by entityParser#queryBlock.
entityListener.prototype.enterQueryBlock = function(ctx) {
};

// Exit a parse tree produced by entityParser#queryBlock.
entityListener.prototype.exitQueryBlock = function(ctx) {
};


// Enter a parse tree produced by entityParser#queryAccessControl.
entityListener.prototype.enterQueryAccessControl = function(ctx) {
};

// Exit a parse tree produced by entityParser#queryAccessControl.
entityListener.prototype.exitQueryAccessControl = function(ctx) {
};


// Enter a parse tree produced by entityParser#queryOperation.
entityListener.prototype.enterQueryOperation = function(ctx) {
};

// Exit a parse tree produced by entityParser#queryOperation.
entityListener.prototype.exitQueryOperation = function(ctx) {
};


// Enter a parse tree produced by entityParser#queryOperationBlock.
entityListener.prototype.enterQueryOperationBlock = function(ctx) {
};

// Exit a parse tree produced by entityParser#queryOperationBlock.
entityListener.prototype.exitQueryOperationBlock = function(ctx) {
};


// Enter a parse tree produced by entityParser#queryCacheBlock.
entityListener.prototype.enterQueryCacheBlock = function(ctx) {
};

// Exit a parse tree produced by entityParser#queryCacheBlock.
entityListener.prototype.exitQueryCacheBlock = function(ctx) {
};


// Enter a parse tree produced by entityParser#queryCacheStrategy.
entityListener.prototype.enterQueryCacheStrategy = function(ctx) {
};

// Exit a parse tree produced by entityParser#queryCacheStrategy.
entityListener.prototype.exitQueryCacheStrategy = function(ctx) {
};


// Enter a parse tree produced by entityParser#queryCacheStrategyTTL.
entityListener.prototype.enterQueryCacheStrategyTTL = function(ctx) {
};

// Exit a parse tree produced by entityParser#queryCacheStrategyTTL.
entityListener.prototype.exitQueryCacheStrategyTTL = function(ctx) {
};


// Enter a parse tree produced by entityParser#queryCacheStrategyTTLOption.
entityListener.prototype.enterQueryCacheStrategyTTLOption = function(ctx) {
};

// Exit a parse tree produced by entityParser#queryCacheStrategyTTLOption.
entityListener.prototype.exitQueryCacheStrategyTTLOption = function(ctx) {
};


// Enter a parse tree produced by entityParser#queryCacheStrategyNever.
entityListener.prototype.enterQueryCacheStrategyNever = function(ctx) {
};

// Exit a parse tree produced by entityParser#queryCacheStrategyNever.
entityListener.prototype.exitQueryCacheStrategyNever = function(ctx) {
};


// Enter a parse tree produced by entityParser#queryCacheKey.
entityListener.prototype.enterQueryCacheKey = function(ctx) {
};

// Exit a parse tree produced by entityParser#queryCacheKey.
entityListener.prototype.exitQueryCacheKey = function(ctx) {
};


// Enter a parse tree produced by entityParser#queryCacheKeyValue.
entityListener.prototype.enterQueryCacheKeyValue = function(ctx) {
};

// Exit a parse tree produced by entityParser#queryCacheKeyValue.
entityListener.prototype.exitQueryCacheKeyValue = function(ctx) {
};


// Enter a parse tree produced by entityParser#queryOperationRaw.
entityListener.prototype.enterQueryOperationRaw = function(ctx) {
};

// Exit a parse tree produced by entityParser#queryOperationRaw.
entityListener.prototype.exitQueryOperationRaw = function(ctx) {
};


// Enter a parse tree produced by entityParser#queryOperationRawBlock.
entityListener.prototype.enterQueryOperationRawBlock = function(ctx) {
};

// Exit a parse tree produced by entityParser#queryOperationRawBlock.
entityListener.prototype.exitQueryOperationRawBlock = function(ctx) {
};


// Enter a parse tree produced by entityParser#queryWhereBlock.
entityListener.prototype.enterQueryWhereBlock = function(ctx) {
};

// Exit a parse tree produced by entityParser#queryWhereBlock.
entityListener.prototype.exitQueryWhereBlock = function(ctx) {
};


// Enter a parse tree produced by entityParser#queryWhereGroup.
entityListener.prototype.enterQueryWhereGroup = function(ctx) {
};

// Exit a parse tree produced by entityParser#queryWhereGroup.
entityListener.prototype.exitQueryWhereGroup = function(ctx) {
};


// Enter a parse tree produced by entityParser#queryWhereJunction.
entityListener.prototype.enterQueryWhereJunction = function(ctx) {
};

// Exit a parse tree produced by entityParser#queryWhereJunction.
entityListener.prototype.exitQueryWhereJunction = function(ctx) {
};


// Enter a parse tree produced by entityParser#queryWhere.
entityListener.prototype.enterQueryWhere = function(ctx) {
};

// Exit a parse tree produced by entityParser#queryWhere.
entityListener.prototype.exitQueryWhere = function(ctx) {
};


// Enter a parse tree produced by entityParser#queryWhereField.
entityListener.prototype.enterQueryWhereField = function(ctx) {
};

// Exit a parse tree produced by entityParser#queryWhereField.
entityListener.prototype.exitQueryWhereField = function(ctx) {
};


// Enter a parse tree produced by entityParser#queryWhereValue.
entityListener.prototype.enterQueryWhereValue = function(ctx) {
};

// Exit a parse tree produced by entityParser#queryWhereValue.
entityListener.prototype.exitQueryWhereValue = function(ctx) {
};


// Enter a parse tree produced by entityParser#queryOperator.
entityListener.prototype.enterQueryOperator = function(ctx) {
};

// Exit a parse tree produced by entityParser#queryOperator.
entityListener.prototype.exitQueryOperator = function(ctx) {
};


// Enter a parse tree produced by entityParser#queryRawBlock.
entityListener.prototype.enterQueryRawBlock = function(ctx) {
};

// Exit a parse tree produced by entityParser#queryRawBlock.
entityListener.prototype.exitQueryRawBlock = function(ctx) {
};


// Enter a parse tree produced by entityParser#queryRawParamsBlock.
entityListener.prototype.enterQueryRawParamsBlock = function(ctx) {
};

// Exit a parse tree produced by entityParser#queryRawParamsBlock.
entityListener.prototype.exitQueryRawParamsBlock = function(ctx) {
};


// Enter a parse tree produced by entityParser#queryRawParamBlock.
entityListener.prototype.enterQueryRawParamBlock = function(ctx) {
};

// Exit a parse tree produced by entityParser#queryRawParamBlock.
entityListener.prototype.exitQueryRawParamBlock = function(ctx) {
};


// Enter a parse tree produced by entityParser#queryRawSqlBlock.
entityListener.prototype.enterQueryRawSqlBlock = function(ctx) {
};

// Exit a parse tree produced by entityParser#queryRawSqlBlock.
entityListener.prototype.exitQueryRawSqlBlock = function(ctx) {
};


// Enter a parse tree produced by entityParser#queryOrderBlock.
entityListener.prototype.enterQueryOrderBlock = function(ctx) {
};

// Exit a parse tree produced by entityParser#queryOrderBlock.
entityListener.prototype.exitQueryOrderBlock = function(ctx) {
};


// Enter a parse tree produced by entityParser#queryFieldSort.
entityListener.prototype.enterQueryFieldSort = function(ctx) {
};

// Exit a parse tree produced by entityParser#queryFieldSort.
entityListener.prototype.exitQueryFieldSort = function(ctx) {
};


// Enter a parse tree produced by entityParser#literal.
entityListener.prototype.enterLiteral = function(ctx) {
};

// Exit a parse tree produced by entityParser#literal.
entityListener.prototype.exitLiteral = function(ctx) {
};


// Enter a parse tree produced by entityParser#binding.
entityListener.prototype.enterBinding = function(ctx) {
};

// Exit a parse tree produced by entityParser#binding.
entityListener.prototype.exitBinding = function(ctx) {
};


// Enter a parse tree produced by entityParser#varIds.
entityListener.prototype.enterVarIds = function(ctx) {
};

// Exit a parse tree produced by entityParser#varIds.
entityListener.prototype.exitVarIds = function(ctx) {
};


// Enter a parse tree produced by entityParser#qualId.
entityListener.prototype.enterQualId = function(ctx) {
};

// Exit a parse tree produced by entityParser#qualId.
entityListener.prototype.exitQualId = function(ctx) {
};


// Enter a parse tree produced by entityParser#qualIds.
entityListener.prototype.enterQualIds = function(ctx) {
};

// Exit a parse tree produced by entityParser#qualIds.
entityListener.prototype.exitQualIds = function(ctx) {
};


// Enter a parse tree produced by entityParser#exprs.
entityListener.prototype.enterExprs = function(ctx) {
};

// Exit a parse tree produced by entityParser#exprs.
entityListener.prototype.exitExprs = function(ctx) {
};


// Enter a parse tree produced by entityParser#expr.
entityListener.prototype.enterExpr = function(ctx) {
};

// Exit a parse tree produced by entityParser#expr.
entityListener.prototype.exitExpr = function(ctx) {
};


// Enter a parse tree produced by entityParser#lambdaExpr.
entityListener.prototype.enterLambdaExpr = function(ctx) {
};

// Exit a parse tree produced by entityParser#lambdaExpr.
entityListener.prototype.exitLambdaExpr = function(ctx) {
};


// Enter a parse tree produced by entityParser#lambdaArgs.
entityListener.prototype.enterLambdaArgs = function(ctx) {
};

// Exit a parse tree produced by entityParser#lambdaArgs.
entityListener.prototype.exitLambdaArgs = function(ctx) {
};


// Enter a parse tree produced by entityParser#lambdaArg.
entityListener.prototype.enterLambdaArg = function(ctx) {
};

// Exit a parse tree produced by entityParser#lambdaArg.
entityListener.prototype.exitLambdaArg = function(ctx) {
};


// Enter a parse tree produced by entityParser#lambdaArgPrefix.
entityListener.prototype.enterLambdaArgPrefix = function(ctx) {
};

// Exit a parse tree produced by entityParser#lambdaArgPrefix.
entityListener.prototype.exitLambdaArgPrefix = function(ctx) {
};


// Enter a parse tree produced by entityParser#variableDeclExpr.
entityListener.prototype.enterVariableDeclExpr = function(ctx) {
};

// Exit a parse tree produced by entityParser#variableDeclExpr.
entityListener.prototype.exitVariableDeclExpr = function(ctx) {
};


// Enter a parse tree produced by entityParser#conditionalExpr.
entityListener.prototype.enterConditionalExpr = function(ctx) {
};

// Exit a parse tree produced by entityParser#conditionalExpr.
entityListener.prototype.exitConditionalExpr = function(ctx) {
};


// Enter a parse tree produced by entityParser#ifExpr.
entityListener.prototype.enterIfExpr = function(ctx) {
};

// Exit a parse tree produced by entityParser#ifExpr.
entityListener.prototype.exitIfExpr = function(ctx) {
};


// Enter a parse tree produced by entityParser#matchExpr.
entityListener.prototype.enterMatchExpr = function(ctx) {
};

// Exit a parse tree produced by entityParser#matchExpr.
entityListener.prototype.exitMatchExpr = function(ctx) {
};


// Enter a parse tree produced by entityParser#logicExpr.
entityListener.prototype.enterLogicExpr = function(ctx) {
};

// Exit a parse tree produced by entityParser#logicExpr.
entityListener.prototype.exitLogicExpr = function(ctx) {
};


// Enter a parse tree produced by entityParser#arithmeticExpr.
entityListener.prototype.enterArithmeticExpr = function(ctx) {
};

// Exit a parse tree produced by entityParser#arithmeticExpr.
entityListener.prototype.exitArithmeticExpr = function(ctx) {
};


// Enter a parse tree produced by entityParser#arithmeticExprOperator.
entityListener.prototype.enterArithmeticExprOperator = function(ctx) {
};

// Exit a parse tree produced by entityParser#arithmeticExprOperator.
entityListener.prototype.exitArithmeticExprOperator = function(ctx) {
};


// Enter a parse tree produced by entityParser#arithmeticTerm.
entityListener.prototype.enterArithmeticTerm = function(ctx) {
};

// Exit a parse tree produced by entityParser#arithmeticTerm.
entityListener.prototype.exitArithmeticTerm = function(ctx) {
};


// Enter a parse tree produced by entityParser#arithmeticTermOperator.
entityListener.prototype.enterArithmeticTermOperator = function(ctx) {
};

// Exit a parse tree produced by entityParser#arithmeticTermOperator.
entityListener.prototype.exitArithmeticTermOperator = function(ctx) {
};


// Enter a parse tree produced by entityParser#arithmeticFactor.
entityListener.prototype.enterArithmeticFactor = function(ctx) {
};

// Exit a parse tree produced by entityParser#arithmeticFactor.
entityListener.prototype.exitArithmeticFactor = function(ctx) {
};


// Enter a parse tree produced by entityParser#simpleExpr.
entityListener.prototype.enterSimpleExpr = function(ctx) {
};

// Exit a parse tree produced by entityParser#simpleExpr.
entityListener.prototype.exitSimpleExpr = function(ctx) {
};


// Enter a parse tree produced by entityParser#assignExpr.
entityListener.prototype.enterAssignExpr = function(ctx) {
};

// Exit a parse tree produced by entityParser#assignExpr.
entityListener.prototype.exitAssignExpr = function(ctx) {
};


// Enter a parse tree produced by entityParser#assignValueExpr.
entityListener.prototype.enterAssignValueExpr = function(ctx) {
};

// Exit a parse tree produced by entityParser#assignValueExpr.
entityListener.prototype.exitAssignValueExpr = function(ctx) {
};


// Enter a parse tree produced by entityParser#blockExpr.
entityListener.prototype.enterBlockExpr = function(ctx) {
};

// Exit a parse tree produced by entityParser#blockExpr.
entityListener.prototype.exitBlockExpr = function(ctx) {
};


// Enter a parse tree produced by entityParser#block.
entityListener.prototype.enterBlock = function(ctx) {
};

// Exit a parse tree produced by entityParser#block.
entityListener.prototype.exitBlock = function(ctx) {
};


// Enter a parse tree produced by entityParser#caseClauses.
entityListener.prototype.enterCaseClauses = function(ctx) {
};

// Exit a parse tree produced by entityParser#caseClauses.
entityListener.prototype.exitCaseClauses = function(ctx) {
};


// Enter a parse tree produced by entityParser#caseClause.
entityListener.prototype.enterCaseClause = function(ctx) {
};

// Exit a parse tree produced by entityParser#caseClause.
entityListener.prototype.exitCaseClause = function(ctx) {
};


// Enter a parse tree produced by entityParser#patternExpr.
entityListener.prototype.enterPatternExpr = function(ctx) {
};

// Exit a parse tree produced by entityParser#patternExpr.
entityListener.prototype.exitPatternExpr = function(ctx) {
};


// Enter a parse tree produced by entityParser#pattern.
entityListener.prototype.enterPattern = function(ctx) {
};

// Exit a parse tree produced by entityParser#pattern.
entityListener.prototype.exitPattern = function(ctx) {
};


// Enter a parse tree produced by entityParser#arithmeticOperator.
entityListener.prototype.enterArithmeticOperator = function(ctx) {
};

// Exit a parse tree produced by entityParser#arithmeticOperator.
entityListener.prototype.exitArithmeticOperator = function(ctx) {
};


// Enter a parse tree produced by entityParser#logicOperator.
entityListener.prototype.enterLogicOperator = function(ctx) {
};

// Exit a parse tree produced by entityParser#logicOperator.
entityListener.prototype.exitLogicOperator = function(ctx) {
};



exports.entityListener = entityListener;