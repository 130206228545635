import antlr4, { InputStream, CommonTokenStream } from 'antlr4'
import {ErrorListener} from 'antlr4/error'
import * as R from 'ramda'
import {
  EntitySymbol,
  EnumerationSymbol,
  StoredPropertySymbol,
  ComputedPropertySymbol,
  AccessControlSymbol,
  QuerySymbol
} from '../symbol/Type'
import { entityLexer } from '../entity/entityLexer'
import { entityParser } from '../entity/entityParser'
import { entityResult } from '../entity/entityResult'
import { RootTable, ValueTable } from '../symbol/Table'

const fs = require('fs')

const SCALAR_TYPES = new Set(['Int', 'String', 'Boolean', 'UUID', 'Float']);
const isScalarType = type => SCALAR_TYPES.has(type)

const convertToArgSchema = argString => {
  console.log("argString", argString)
  if(!argString) {
    return undefined
  } else if(isScalarType(argString.type)) {
    return { type: 'STATIC', value: argString.value }
  } else if(argString.type === 'Timestamp' && argString.value === 'now') {
    return { type: 'DYNAMIC', value: 'NOW' }
  } else if(argString.type === 'ViewerContext') {
    return { type: 'VIEWER_CONTEXT', value: argString.name }
  } else if (argString.value.indexOf('$') === 0) {
    return { type: 'PARAM', value: argString.name }
  }
}

const processArgs = argList => {
  const args = R.reduce((result, arg) => {
    if(arg.field) {
      result[arg.field] = convertToArgSchema(arg.value)
    }
    return result
  })({}, argList)

  const dependencies = R.map((prop) => (prop.type === 'SCOPE' && prop.value != 'id') ? prop.value : null)(args)
  const dependencyProps = R.values(R.filter(_ => _ !== null)(dependencies))

  return { args, dependencyProps }
}

const createStoredPropertySchema = R.curry((entityName, idName, symbol) => ({
  __type: 'StoredProperty',
  name: symbol.name,
  type: symbol.type,
  meta: {
    identity: idName === symbol.name,
    searchable: symbol.searchable,
    searchableKeyword: symbol.searchableKeyword,
    unique: symbol.unique,
    validation: symbol.validator
  }
}))

const createComputedPropertySchema = R.curry((entityName, schema, symbol) => ({
  __type: 'ComputedProperty',
  type: symbol.type,
  name: symbol.name,
  isCollection: symbol.isCollection,
  isNullable: symbol.isNullable,
  meta: {
    where: symbol.where,
    orderBy: symbol.orderBy,
    acl: symbol.acl,
    rawQuery: symbol.rawQuery
  }
}))

const resolveAccessAuthorizationParam = R.curry((entityName, entitySchema, accessControlSymbol) => {
  const getParameters = lambda => {
    if (!lambda || !lambda.args) return []
    return R.filter(arg => arg.source === 'Query' || arg.source === 'Input', lambda.args||[])
  }
  const getLambdaParameters = lambdas => R.map(getParameters)(lambdas)
  const getAllLambdaParameters = lambdas => R.filter(_ => _.length > 0)(getLambdaParameters(lambdas))
  return R.map(param => {
    return {
      name: param.value,
      type: entitySchema.props[param.value].type
    }
  })(getAllLambdaParameters(accessControlSymbol))
})

const createAccessControlSchema = R.curry((entityName, symbol) => {
  return {
    __type: 'AccessControl',
    name: symbol.name,
    success: symbol.success,
    failure: symbol.failure,
    authorization: symbol.authorization,
    lambdas: symbol.lambdas
  }
})

const createEntitySchema = (symbol) => {
  const entityName = symbol.name
  // Process stored properties
  const definedId = R.find(prop => prop.identity, symbol.storedProps)
  const storedProps = R.reduce((props, symbol) => {
    props[symbol.name] = createStoredPropertySchema(entityName, definedId ? definedId.name : 'id', symbol)
    return props
  })({}, symbol.storedProps)
  if(!definedId) {
    storedProps['id'] = { __type: 'StoredProperty', name: 'id', type: 'UUID', meta: { identity: true } }
  }
  const computedProps = R.reduce((props, symbol) => {
    props[symbol.name] = createComputedPropertySchema(entityName, symbol)
    return props
  })({}, symbol.computedProps)
  // VALIDATE: indexes
  R.forEachObjIndexed((index, name) => {
    const unknownProps = index.filter(({property, sort}) => !storedProps[property])
    if(unknownProps.length) {
      const main = unknownProps.splice(0,1)
      const properties = unknownProps.reduce((props, prop) => `${props},'${prop.property}'`, `'${main[0].property}'`)
      throw new Error(`Invalid property ${properties} on index '${name}'`)
    }
  })(symbol.indexes)
  return {
     __type: 'Entity',
    name: symbol.name,
    body: {
      storedProps,
      computedProps,
      indexes: symbol.indexes,
    }
  }
}

const createQuerySchema = R.curry((querySymbol, schema, authParams) => ({
    __type: 'Query',
    name: querySymbol.name,
    body: {
      args: querySymbol.args,
      type: querySymbol.type,
      isCollection: querySymbol.isCollection,
      acl: querySymbol.acl,
      meta: {
        cache: querySymbol.cache,
        where: querySymbol.where,
        orderBy: querySymbol.orderBy,
        rawQuery: querySymbol.sql
      }
    },
}))

const createEnumerationSchema = (symbol) => ({
  __type: 'Enum',
  name: symbol.name,
  body: {
    values: symbol.values
  }
})

export const parseAST = (str) => {
  const chars = new InputStream(str);
  const lexer = new entityLexer(chars);
  const tokens  = new CommonTokenStream(lexer);
  const parser = new entityParser(tokens);
  const valueTable = new ValueTable()
  const interpreter = new entityResult(RootTable, valueTable);

  parser.buildParseTrees = true;

  const tree = parser.exprs();

  interpreter.visit(tree);

  return interpreter.symbolTable
}

class LaskarErrorListener extends ErrorListener {
  constructor(annotations = []) {
    super()
    this.annotations = annotations
    this.syntaxError = this.syntaxError.bind(this)
    this.typeError = this.typeError.bind(this)
  }

  syntaxError(recognizer, offendingSymbol, line, column, msg, e) {
    this.annotations.push({
      row: line - 1,
      column: column,
      text: msg,
      type: "error"
    });
  }

  typeError(e, ctx) {
    this.syntaxError(null, ctx.getText(), ctx.start.line, ctx.start.column, e.message, e)
  }
}

const logOnError = (errors) => console.log(errors)

export const parse = (str, lex, schema, onParse = logOnError) => {
  const chars = new InputStream(str);
  const lexer = new entityLexer(chars);
  const tokens  = new CommonTokenStream(lexer);
  const parser = new entityParser(tokens);
  const valueTable = new ValueTable();
  const rootTable = new RootTable(schema);
  const errors = [];
  const errorListener = new LaskarErrorListener(errors);
  const interpreter = new entityResult(schema, rootTable, valueTable, errorListener);

  parser.buildParseTrees = true;
  parser.removeErrorListeners();
  parser.addErrorListener(errorListener);

  const tree = parser[lex]();

  interpreter.visit(tree);
  onParse(errors)

  // Group symbols together
  if(errors.length) {
    return schema
  }
  const entities = R.filter(symbol => symbol instanceof EntitySymbol, interpreter.symbolTable.symbols)
  const enumerations = R.filter(symbol => symbol instanceof EnumerationSymbol, interpreter.symbolTable.symbols)
  const queries = R.filter(symbol => symbol instanceof QuerySymbol, interpreter.symbolTable.symbols)
  // Symbol group registration functions
  const registerEnumerations = (schema) => (enumSymbol) => {
    schema.objects[enumSymbol.name] = createEnumerationSchema(enumSymbol)
  }
  const registerQueries = (schema) => (querySymbol) => {
    if(schema.objects[querySymbol.type] && schema.objects[querySymbol.type].body){
      const authParams = schema.objects[querySymbol.type].body.accessControl
      schema.objects[querySymbol.name] = createQuerySchema(querySymbol, schema, authParams)
    }
  }
  const registerEntities = (schema) => (entitySymbol) => {
    schema.objects[`${entitySymbol.name}`] = createEntitySchema(entitySymbol)
  }
  const registerEntityAccessControls = (schema) => (entitySymbol) => {
    const definedAcl = R.reduce((result, acl) => {
      const definition = createAccessControlSchema(entitySymbol.name, acl)
      result[definition.name] = definition
      return result
    }, {}, entitySymbol.accessControl)
    schema.objects[`${entitySymbol.name}`].body.accessControl = definedAcl
  }
  const registerEntityComputedProperties = (schema) => (entitySymbol) => {
    const entityName = entitySymbol.name
    const computedProperties = R.reduce((props, symbol) => {
      props[symbol.name] = createComputedPropertySchema(entityName, schema, symbol)
      return props
    })({}, entitySymbol.computedProps)
    schema.objects[entityName].body.computedProps = computedProperties
  }
  const registerLambdas = (schema) => (lambda) => {
    schema.objects[lambda.name] = {
      lambda: lambda.name,
      __type: 'Lambda',
      batchable: lambda.batchable,
      collection: lambda.collection,
    }
  }
  R.forEach(registerEnumerations(schema))(enumerations)
  R.forEach(registerEntities(schema))(entities)
  R.forEach(registerEntityComputedProperties(schema))(entities)
  R.forEach(registerEntityAccessControls(schema))(entities)
  R.forEach(registerQueries(schema))(queries)

  return schema
}
