import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react'
import 'brace'
import 'brace/theme/textmate'
import Autocomplete from 'react-autocomplete'
import * as R from 'ramda'

import { useViewedObject, useViewedSchema, useObjectByName, useBaseObject, useBranch } from './Contexts';

function ObjectAutocomplete(props) {
  let { onObjectSelected, types } = props;
  let schema = useViewedSchema();
  let schemaEntityAutocompleteValues = useMemo(() => {

    let entries = [];

    types.forEach(type => {

      if (type === 'PRIMITIVE') {
        entries = entries.concat(['String', 'Boolean', 'Int', 'Float', 'Timestamp', 'UUID']);
      } else if (type === 'ENUM') {
        entries = entries.concat(Object
          .keys(schema.objects)
          .filter(key => schema.objects[key].__type === "Enum"));
      } else if (type === 'ENTITY') {
        entries = entries.concat(Object
          .keys(schema.objects)
          .filter(key => schema.objects[key].__type === "Entity"));
      } else {
        throw new Error('Unsupported type at ObjectAutocomplete ' + type);
      }
    });

    return entries
      .map(key => ({label: key, lowercaseLabel: key.toLowerCase()}));

  }, [schema]);

  let [autocompleteValue, setAutocompleteValue] = useState('');

  return (
    <Autocomplete
      getItemValue={(item) => item.label}
      items={schemaEntityAutocompleteValues}
      renderItem={(item, isHighlighted) =>
        <div key={item.label} style={{background: isHighlighted ? '#eee' : '#fff', padding: "7 3"}}>
          <span style={styles.typeLabel}>{item.label}</span>
        </div>
      } 
      shouldItemRender={(item, value) => {
        return value.length > 0 && item.lowercaseLabel.indexOf(value.toLowerCase()) === 0;
      }}
      value={autocompleteValue}
      onChange={(e, val) => setAutocompleteValue(val)}
      onSelect={(val) => onObjectSelected(val)}
    />
  );
}

function EntityComputedPropForm(props) {
  let { onSubmit, onCancel } = props;
  let firstInputRef = useRef();
  let [name, setName] = useState('');
  let [type, setType] = useState(null);
  let [isCollection, setIsCollection] = useState(false);
  let [viewedObject, isEdited] = useViewedObject();

  let onSetName = useCallback((e) => {
    setName(e.target.value);
  });

  useEffect(() => {
    firstInputRef.current.focus();
  }, []);

  let onCreateClick = useCallback(() => {
    onSubmit({
      __type: "ComputedProperty",
      acl: "read",
      args: [],
      isCollection: isCollection,
      isNullable: false,
      meta: {
        rawQuery: null,
        orderBy: [],
        where: []
      },
      name: name, 
      type: type
    });
  });

  let nameValid = !viewedObject.body.storedProps[name];
  let createReady = !!name && !!type && nameValid;

  return (
    <div style={{padding: 5, background: "#eee"}}>
      <div style={{fontSize: 13, color: "#444"}}>Name</div>
      <input type="text" ref={firstInputRef} value={name} onChange={onSetName}/>
      {!nameValid && <div style={{fontSize: 10, color: "red"}}>The name is taken or not valid.</div>}
      <br/>
      <div style={{fontSize: 13, color: "#444"}}>Type</div>
      {type ? <span style={styles.typeLabel}>{type}</span> : <ObjectAutocomplete types={['ENTITY']} onObjectSelected={setType} />}
      <div style={{fontSize: 13, color: "#444"}}>Is Collection</div>
      <input type="checkbox" checked={isCollection} onChange={(e) => setIsCollection(e.target.checked)} />
      <div style={{fontSize: 10, color: "#888"}}>Note: This information is final and will not be editable afterwards.</div>
      <div style={{marginTop: 5}}>
        <button disabled={!createReady} onClick={onCreateClick}>Create</button>
        <button onClick={onCancel}>Cancel</button>
      </div>
    </div>
  );
}

function EntityStoredPropForm(props) {
  let { onSubmit, onCancel } = props;
  let firstInputRef = useRef();
  let [name, setName] = useState('');
  let [type, setType] = useState(null);
  let [viewedObject, isEdited] = useViewedObject();

  let onSetName = useCallback((e) => {
    setName(e.target.value);
  });

  useEffect(() => {
    firstInputRef.current.focus();
  }, []);

  let onCreateClick = useCallback(() => {
    onSubmit({
      __type: "StoredProperty",
      meta: {
        identity: false,
        searchable: false,
        searchableKeyword: false,
        unique: false,
        validation: null
      },
      name: name, 
      type: type
    });
  });

  let nameValid = !viewedObject.body.computedProps[name];
  let createReady = !!name && !!type && nameValid;

  return (
    <div style={{padding: 5, background: "#eee"}}>
      <div style={{fontSize: 13, color: "#444"}}>Name</div>
      <input type="text" ref={firstInputRef} value={name} onChange={onSetName}/>
      {!nameValid && <div style={{fontSize: 10, color: "red"}}>The name is taken or not valid.</div>}
      <br/>
      <div style={{fontSize: 13, color: "#444"}}>Type</div>
      {type ? <span style={styles.typeLabel}>{type}</span> : <ObjectAutocomplete types={['PRIMITIVE', 'ENUM']} onObjectSelected={setType} />}
      <div style={{fontSize: 10, color: "#888"}}>Note: This information is final and will not be editable afterwards.</div>
      <div style={{marginTop: 5}}>
        <button disabled={!createReady} onClick={onCreateClick}>Create</button>
        <button onClick={onCancel}>Cancel</button>
      </div>
    </div>
  );
}

function ObjectLabel(props) {
  let { name } = props;
  return <span style={styles.typeLabel}>{name}</span>
}

let styles = {
  typeLabel: {background: "#eaf4ff", color: "#1486f3", padding: "2 5", borderRadius: 3, fontFamily: "monospace", fontSize: 12},
  propNameLabel: {fontSize: 13, fontFamily: "monospace"},
  propOptionsSwitcher: {color: "#666", fontSize: 11, marginTop: 7}
}

function StoredPropValidationEditor(props) {
  let { propName } = props;
  let [object] = useViewedObject();
  let validations = (object.body.storedProps[propName].meta || {}).validation;

  if (!validations) {
    return <div style={{marginTop: 5, fontSize: 11, color: "#888"}}>No validation yet. Feature to add validation incoming!</div>;
  }

  return (
    <div style={{marginTop: 5}}>
      {
        Object.keys(validations).map(name => {

          let { failure, regexp } = validations[name];

          return (
            <div style={{backgroundColor: "#eee", color: "#444", fontFamily: "monospace", padding: 10}}>
              <div>Name: {name}</div>
              <div>Regex Check: {regexp}</div>
              <div>Failure Message: {failure}</div>
            </div>
          );
        })
      }
    </div>
  );
}

function EntityStoredPropEditor(props) {

  let { name, onDelete, onChange } = props;
  let [object] = useViewedObject();
  let storedProp = object.body.storedProps[name];
  let { meta } = storedProp;
  let branch = useBranch();
  let readOnly = branch.closed || branch.merged;

  let baseObject = useBaseObject(object.name);
  let isNew = !baseObject || !baseObject.body.storedProps.hasOwnProperty(name);

  let [shouldShowOptions, setShouldShowOptions] = useState(false);
  let [shouldShowValidation, setShouldShowValidation] = useState(false);

  let backgroundColor = isNew ? "#fff" : "#fff";

  function onCheckboxChange(e) {
    let checked = e.target.checked
    let checkboxName = e.target.name;
    onChange(R.assocPath(['meta', checkboxName], checked, storedProp));
  }

  return (
    <div style={{borderBottom: "1px solid #ddd", padding: "10 0", backgroundColor: backgroundColor}}>
      <div>
        <ObjectLabel name={storedProp.type}/>
        <span style={styles.propNameLabel}> {storedProp.name}</span>
      </div>
      <div style={styles.propOptionsSwitcher}>
        <span style={{cursor: "pointer"}} onClick={() => setShouldShowValidation(!shouldShowValidation)}>
          Validation {shouldShowValidation ? <span>&#9650;</span> : <span>&#9660; </span>}
        </span>
        <span style={{cursor: "pointer"}} onClick={() => setShouldShowOptions(!shouldShowOptions)}>
          Options {shouldShowOptions ? <span>&#9650;</span> : <span>&#9660;</span>}
        </span>
      </div>
      {
        shouldShowValidation && <StoredPropValidationEditor propName={name}/>
      }
      {shouldShowOptions && 
        <div style={{fontSize: 11, marginTop: 10, color: "#888"}}>
          <input type="checkbox" name={"unique"} checked={meta.unique} onChange={onCheckboxChange} disabled={readOnly}/>
          <label>Unique</label>
          <input type="checkbox" name={"searchable"} checked={meta.searchable} onChange={onCheckboxChange} disabled={readOnly}/>
          <label>Searchable</label>
          <input type="checkbox" name={"searchableKeyword"} checked={meta.searchableKeyword} onChange={onCheckboxChange} disabled={readOnly}/>
          <label>Searchable Keyword</label>
        </div>
      }
      <div>
        {isNew && (
          <div style={{paddingTop: 5}}>
            <span style={{fontSize: 11, color: "green"}}>New Property &bull; </span>
            <button onClick={onDelete}>Cancel Creation</button>
          </div>
        )}
      </div>
    </div>
  );
}

function getObjectStoredProperties(object) {
  return Object.keys(object.body.storedProps)
    .map(key => object.body.storedProps[key]);
}

function createOptionElement(oo, index) {
  return <option key={index} selected={oo.selected} disabled={oo.disabled} value={oo.index}>{oo.htmlText}</option>;
}

let OPERATOR_VALUES = {
  EQUALS: {__type: "OPERATOR", name: "equals", raw: "eq"},
  LIKE: {__type: "OPERATOR", name: "equal", raw: "like"},
  GREATER_THAN: {__type: "OPERATOR", name: "greaterThan", raw: ">" },
  GREATER_THAN_EQUAL: {__type: "OPERATOR", name: "greaterThanEqual", raw: ">="},
  LESS_THAN: {__type: "OPERATOR", name: "lessThan", raw: "<"},
  LESS_THAN_EQUAL: {__type: "OPERATOR", name: "lessThanEqual", raw: "<="},
  IS_NULL: {__type: "OPERATOR", name: "isEmpty", raw: "isEmpty"},
  IS_NOT_NULL: {__type: "OPERATOR", name: "notEmpty", raw: "notEmpty"}
};

function FilterClauseViewer(props) {
  let branch = useBranch();
  let readOnly = branch.closed || branch.merged;
  let { filterNode, filteredType, onChange, onDelete } = props;
  let filterData = filterNode.data;
  let filteredEntityName = filteredType;
  let [filteredObject, _] = useObjectByName(filteredType);
  let [viewedObject] = useViewedObject();

  function onNodeChanged(index, value) {
    onChange({__type: "FILTER", data: R.update(index, value, filterData)});
  }

  function renderNode(node, index) {
    // on left operand
    if (index === 0) {

      let optionObjects = getObjectStoredProperties(filteredObject)
        .map((prop, index) => ({
          value: {__type: "OPERAND", isLiteral: false, isNull: false, isRelative: false, name: prop.name, raw: prop.name},
          htmlText: prop.name,
          disabled: false,
          index: index,
          selected: node.name === prop.name
        }));

      return (
        <select onChange={(e) => onNodeChanged(index, optionObjects[e.target.value].value)} disabled={readOnly}>
          <optgroup label={"Filter by " + filteredEntityName + " prop:"}>
            {
              optionObjects.map(createOptionElement)
            }
          </optgroup>
        </select>
      );

    // on middle operator
    } else if (index === 1) {
      // Operand Options
      let optionObjects = [
        {value: OPERATOR_VALUES.EQUALS, htmlText: "="},
        {value: OPERATOR_VALUES.LIKE, htmlText: "LIKE"},
        {value: OPERATOR_VALUES.IS_NOT_NULL, htmlText: "IS NOT NULL"},
        {value: OPERATOR_VALUES.IS_NULL, htmlText: "IS NULL"},
        {value: OPERATOR_VALUES.LESS_THAN, htmlText: "<"},
        {value: OPERATOR_VALUES.LESS_THAN_EQUAL, htmlText: "<="},
        {value: OPERATOR_VALUES.GREATER_THAN, htmlText: ">"},
        {value: OPERATOR_VALUES.GREATER_THAN_EQUAL, htmlText: ">="},
      ];

      optionObjects.forEach((oo, index) => {
        oo.index = index;
        oo.selected = node.name === oo.value.name;
      });

      return (
        <select onChange={(e) => onNodeChanged(index, optionObjects[e.target.value].value)} disabled={readOnly}>
          {optionObjects.map(createOptionElement)}
        </select>
      );

    // on right operand
    } else if (index === 2) {

      let props = getObjectStoredProperties(viewedObject);
      let filteringObjectStoredPropType = filteredObject.body.storedProps[filterData[0].name].type;

      let aggregateOptionObjects = [];
      let aggregateIndex = 0;

      let storedPropOptionObjects = props.map((prop) => (
        {
          value: {__type: "OPERAND", isLiteral: false, isNull: false, isRelative: true, name: prop.name, raw: prop.name},
          htmlText: "self." + prop.name,
          disabled: false, //prop.type !== filteringObjectStoredPropType,
          index: aggregateIndex++,
          selected: node.isRelative && node.name === prop.name
        }
      ));

      aggregateOptionObjects.push.apply(aggregateOptionObjects, storedPropOptionObjects);

      let isBoolean = filteringObjectStoredPropType === "Boolean";
      let booleanOptionObjects = [];

      if (isBoolean) {
        booleanOptionObjects = [true, false].map((value) => (
          {
            value: {__type: "OPERAND", isLiteral: true, isNull: false, isRelative: false, name: value, raw: value},
            htmlText: value.toString().toUpperCase(),
            disabled: false,
            index: aggregateIndex++,
            selected: node.isLiteral && node.name === value
          }
        ))

        aggregateOptionObjects.push.apply(aggregateOptionObjects, booleanOptionObjects);
      }

      return (
        <select onChange={(e) => onNodeChanged(index, aggregateOptionObjects[e.target.value].value)} disabled={readOnly}>
          <optgroup label="Stored Properties">
            {storedPropOptionObjects.map(createOptionElement)}
          </optgroup>

          {isBoolean &&
            <optgroup label="Boolean Values">
              {booleanOptionObjects.map(createOptionElement)}
            </optgroup>
          }
        </select>
      );
    }
  }

  return (
    <div style={{paddingTop: 5}}>
      {
        filterData.map((node, index) => <div key={index}>{renderNode(node, index)}</div>)
      }
      { readOnly
        ? null
        : <button onClick={onDelete}>Delete Filter</button>
      }
    </div>
  );
}

function OrderByClauseEditor(props) {
  let branch = useBranch();
  let readOnly = branch.closed || branch.merged;
  let { filteredType, orderByData, onChange } = props;
  let [filteredObject] = useObjectByName(filteredType);

  function onAddOrderClick() {
    let initialPropToFilter = Object.keys(filteredObject.body.storedProps)[0];
    onChange([{__type: "ORDER", data: [{name: initialPropToFilter, sort: "DESC"}]}]);
  }

  if (orderByData && orderByData.length === 0) {
    return readOnly ? null : <button onClick={onAddOrderClick}>Add Ordering</button>;
  }
  // Legacy from IDE -- the data is enclosed in an array.
  let _orderByData = orderByData[0].data[0];

  let orderingFieldOptionObjects = getObjectStoredProperties(filteredObject)
    .map((prop, index) => ({
      value: prop.name,
      htmlText: prop.name,
      disabled: false,
      index: index,
      selected: _orderByData.name === prop.name
    }));

  let sortDirectionOptionObjects = ["ASC", "DESC"].map((sortDirection, index) => ({
    value: sortDirection,
    htmlText: sortDirection,
    disabled: false,
    index: index,
    selected: _orderByData.sort === sortDirection
  }));

  return (
    <div>
      <div>
        <select onChange={(e) => onNodeChanged(index, aggregateOptionObjects[e.target.value].value)} disabled={readOnly}>
          {orderingFieldOptionObjects.map(createOptionElement)}
        </select>
        <select disabled={readOnly}>
          {sortDirectionOptionObjects.map(createOptionElement)}
        </select>
      </div>
      { readOnly
        ? null
        : <button onClick={() => onChange([])}>Remove Ordering</button>
      }
    </div>
  );
}

let CONJUNCTION_VALUES = {
  AND: {__type: "CONJUNCTION", name: "AND", raw: "and"},
  OR: {__type: "CONJUNCTION", name: "OR", raw: "or"},
};

function ConjunctionClauseViewer(props) {
  let branch = useBranch();
  let readOnly = branch.closed || branch.merged;
  let { node, onChange } = props;

  let optionObjects = Object
    .keys(CONJUNCTION_VALUES)
    .map((key, index) => ({
      value: CONJUNCTION_VALUES[key],
      htmlText: CONJUNCTION_VALUES[key].name,
      disabled: false,
      index: index,
      selected: node.name === CONJUNCTION_VALUES[key].name
    }));

  return (
    <select style={{marginTop: 5}} onChange={(e) => onChange(optionObjects[e.target.value].value)} disabled={readOnly}>
      {
        optionObjects.map(createOptionElement)
      }
    </select>
  )
}

function WhereClauseEditor(props) {
  let branch = useBranch();
  let readOnly = branch.closed || branch.merged;
  let { filteredType, whereData, onChange } = props;

  let [filteredObject] = useObjectByName(filteredType);
  let [object] = useViewedObject();

  function onNodeChange(index, value) {
    onChange(R.update(index, value, whereData));
  }

  function onAddFilterClick() {
    let initialPropToFilter = Object.keys(filteredObject.body.storedProps)[0];
    let initialFilteringProp = Object.keys(object.body.storedProps)[0];
    let filterNode = {
      __type: "FILTER",
      data: [
        {__type: "OPERAND", isLiteral: false, isNull: false, isRelative: false, name: initialPropToFilter, raw: initialPropToFilter},
        OPERATOR_VALUES.EQUALS,
        {__type: "OPERAND", isLiteral: false, isNull: false, isRelative: true, name: initialFilteringProp, raw: initialFilteringProp}
      ]
    };

    let newConcattedWhereData = whereData.length > 0 ? [CONJUNCTION_VALUES.AND, filterNode] : [filterNode];

    onChange(R.concat(whereData, newConcattedWhereData));
  }

  function onFilterClauseDeleted(index) {
    let isLastFilterClause = index === whereData.length - 1;
    let newWhereData = isLastFilterClause ? 
      R.splitAt(index - 1, whereData)[0] : 
      // This line is a little bit magic but basically we're trying to split the whereData list
      // in two, where the current deleted node is not included, and then merge them back again.
      R.concat(R.splitAt(index, whereData)[0], R.splitAt(index + 2, whereData)[1]);

    onChange(newWhereData);
  }

  function renderNode(node, index) {
    if (node.__type === "FILTER") {
      return <FilterClauseViewer
        key={index}
        filteredType={filteredType}
        filterNode={node}
        onDelete={() => onFilterClauseDeleted(index)}
        onChange={(newNode) => onNodeChange(index, newNode)}
        />
    } else if (node.__type === "CONJUNCTION") {
      return <ConjunctionClauseViewer key={index} node={node} onChange={newNode => onNodeChange(index, newNode)} />
    } else {
      return <div>??</div>
    }
  }

  return (
    <div>
      <div>
        {whereData.map(renderNode)}
      </div>
      { readOnly
        ? null
        : <div>
          <button onClick={onAddFilterClick}>Add Filter</button>
        </div>
      }
    </div>
  );
}

let DataSourceEditorMode = {
  FILTERS: 0,
  RAW: 1
};

function EntityComputedPropEditor(props) {
  let branch = useBranch();
  let readOnly = branch.closed || branch.merged;
  let { name, onDelete, onChange } = props;
  let [object] = useViewedObject();
  let computedProp = object.body.computedProps[name];
  let { meta } = computedProp;

  let [shouldShowDataSource, setShouldShowDataSource] = useState(false);
  let [shouldShowOptions, setShouldShowOptions] = useState(false);
  let [dataSourceMode, setDataSourceMode] = useState(DataSourceEditorMode.FILTERS);

  let baseObject = useBaseObject(object.name);
  let isNew = !baseObject || !baseObject.body.computedProps.hasOwnProperty(name);

  let onPathValueChange = R.curry((path, value) => {
    onChange(R.assocPath(path, value, computedProp));
  });
  let onWhereClauseChange = onPathValueChange(['meta', 'where']);
  let onNullableChange = onPathValueChange(['isNullable']);
  let onOrderByClauseChange = onPathValueChange(['meta', 'orderBy']);

  return (
    <div style={{padding: "10 0", borderBottom: "1px solid #ddd"}}>
      <div>
        <span style={styles.typeLabel}>{(computedProp.isCollection ? '[]' : '') + computedProp.type}</span>
        <span style={styles.propNameLabel}> {computedProp.name}</span>
      </div>
      <div style={styles.propOptionsSwitcher}>
      <span style={{cursor: "pointer"}} onClick={() => setShouldShowDataSource(!shouldShowDataSource)}>
          Data Source {shouldShowDataSource ? <span>&#9650;</span> : <span>&#9660;</span>}
        </span>
        <span> </span>
        <span style={{cursor: "pointer"}} onClick={() => setShouldShowOptions(!shouldShowOptions)}>
          Options {shouldShowOptions ? <span>&#9650;</span> : <span>&#9660;</span>}
        </span>
      </div>
      {shouldShowDataSource &&
        <div style={{background: "#eee", minWidth: 300, marginTop: 5, fontFamily: "monospace"}}>
          <div style={{background: "#ddd", padding: 5}}>
            <select disabled={readOnly}>
              <option selected={dataSourceMode === DataSourceEditorMode.FILTERS}>Filters</option>
              <option selected={dataSourceMode === DataSourceEditorMode.RAW}>Raw</option>
            </select>
          </div>
          <div style={{padding: 5}}>
            <div style={{marginBottom: 5}}>WHERE</div>
            {meta.where && <WhereClauseEditor
              filteredType={computedProp.type}
              whereData={meta.where}
              onChange={onWhereClauseChange} />}
            <div style={{marginTop: 5, marginBottom: 5}}>ORDER BY</div>
            <OrderByClauseEditor filteredType={computedProp.type} orderByData={meta.orderBy} onChange={onOrderByClauseChange} />
          </div>
        </div>
      }
      {shouldShowOptions &&
        <div style={{fontSize: 11, padding: "5 0", color: "#888"}}>
          <label>Nullable</label>
          <input type="checkbox" checked={computedProp.isNullable} onChange={(e) => onNullableChange(e.target.checked)} disabled={readOnly}/>
        </div>
      }
      {isNew && (
        <div style={{paddingTop: 5}}>
          <span style={{fontSize: 11, color: "green"}}>New Property &bull; </span>
          <button onClick={onDelete}>Cancel Creation</button>
        </div>
      )}
    </div>
  );
}

let ACCESS_CONTROL_VALUES = {
  EMPLOYEE: {
    __type: "AccessControl", 
    authorization: null, 
    lambdas: [{
      errorMessage: "Authorized SaleStock Employee Only",
      inputs: [{source: "ViewerContext", value: "timasClientID"}, {source: null, value: "laskar-app-"}], 
      lambda: "includes", 
      name: "includes"
    }]
  },
  ALWAYS: {__type: "AccessControl", authorization: true, failure: null, success: null, lambdas: []},
  FORBIDDEN: {__type: "AccessControl", authorization: false, lambdas: []}
};

function SelectElement(props) {
  let branch = useBranch();
  let readOnly = branch.closed || branch.merged;
  let { optionObjects, isOptionSelected, onSelected } = props;
  let selectedIndex = 0;

  for (; selectedIndex < optionObjects.length; selectedIndex++) {
    if (isOptionSelected(optionObjects[selectedIndex].value)) {
      break;
    }
  }

  return (
    <select defaultValue={selectedIndex} onChange={(e) => onSelected(optionObjects[e.target.value].value)} disabled={readOnly}>
      {optionObjects.map((oo, index) =>{
        return <option key={index} disabled={oo.disabled} value={index}>{oo.htmlText}</option>;
      })}
    </select>
  )
}

let aclKeys = ['list' , 'read', 'create', 'update', 'delete'];

let createOptionObjectsForACLKey = (key) => {
  return [
    {value: R.assocPath(['name'], key, ACCESS_CONTROL_VALUES.ALWAYS), htmlText: "Always", disabled: false},
    {value: R.assocPath(['name'], key, ACCESS_CONTROL_VALUES.EMPLOYEE), htmlText: "Employee", disabled: false},
    {value: R.assocPath(['name'], key, ACCESS_CONTROL_VALUES.FORBIDDEN), htmlText: "Forbidden", disabled: false}
  ]
}

let accessControlEquals = (accessControl1, accessControl2) => {
  return R.equals(
    R.pick(['lambda', 'authorization'], accessControl1), 
    R.pick(['lambda', 'authorization'], accessControl2)
  );
}

function EntityACLEditor(props) {
  let branch = useBranch();
  let readOnly = branch.closed || branch.merged;
  let { onChange } = props;
  let [object] = useViewedObject();
  let accessControl = object.body.accessControl;
  
  let onSelected = (aclKey, value) => {
    onChange(R.assocPath([aclKey], value, accessControl));
  }

  return (
    <div>
      {
        aclKeys.map(aclKey => {
          return (
            <div key={aclKey} style={{marginTop: 10 }}>
              <div style={{marginBottom: 5, fontSize: 12, color: "#666", textTransform: "capitalize"}}>{aclKey}</div>
              <SelectElement
                isOptionSelected={optionValue => accessControlEquals(accessControl[aclKey], optionValue)}
                optionObjects={createOptionObjectsForACLKey(aclKey)} 
                onSelected={(value) => onSelected(aclKey, value)} />
            </div>
          );
        })
      }
    </div>
  )
}

export default function EntityEditorForm(props) {
  let branch = useBranch();
  let readOnly = branch.closed || branch.merged;
  let { onEdited } = props;
  let [object] = useViewedObject();
  let objectBody = object.body;

  console.log(object);

  let [shouldShowSPForm, setShouldShowSPForm] = useState(false);
  let [shouldShowCPForm, setShouldShowCPForm] = useState(false);

  function onStoredPropChange(newStoredProp) {
    onEdited(R.assocPath(['body', 'storedProps', newStoredProp.name], newStoredProp, object));
    setShouldShowSPForm(false);
  }

  function onStoredPropDelete(propName) {
    onEdited(R.dissocPath(['body', 'storedProps', propName], object));
  }

  function onComputedPropChange(newComputedProp) {
    onEdited(R.assocPath(['body', 'computedProps', newComputedProp.name], newComputedProp, object));
    setShouldShowCPForm(false);
  }

  function onComputedPropDelete(propName) {
    onEdited(R.dissocPath(['body', 'computedProps', propName], object));
  }

  function onACLChange(newAcl) {
    onEdited(R.assocPath(['body', 'accessControl'], newAcl, object));
  }

  return (
    <div style={{padding: 10}}>
      <div>
        <span style={{fontSize: 11, fontFamily: "monospace", padding: "2 5", background: "#eee", color: "#888"}}>Entity</span>
        <div style={{fontSize: 18, fontFamily: "monospace", marginTop: 5}}>{object.name}</div>
      </div>
      <hr/>
      <div>
        <div style={{fontSize: 15, fontWeight: "bold", marginBottom: 5}}>Stored Properties</div>
        <div>
          {Object.keys(objectBody.storedProps).map((name) => {
            return <EntityStoredPropEditor key={name} onDelete={() => onStoredPropDelete(name)} onChange={onStoredPropChange} name={name} />;
          })}
        </div>
        { readOnly
            ? null
            : shouldShowSPForm
              ? <EntityStoredPropForm onSubmit={onStoredPropChange} onCancel={() => setShouldShowSPForm(false)} />
              : <button onClick={() => setShouldShowSPForm(true)} style={{marginBottom: 5, marginTop: 10}}>Add Stored Property</button>
        }
      </div>
      <hr/>
      <div>
        <div style={{fontSize: 15, fontWeight: "bold", marginBottom: 5}}>Computed Properties</div>
        <div>
          {Object.keys(objectBody.computedProps).map((name) => {
            return <EntityComputedPropEditor key={name} name={name} onDelete={() => onComputedPropDelete(name)} onChange={onComputedPropChange} />;
          })}
        </div>
        { shouldShowCPForm
           ? <EntityComputedPropForm onSubmit={onComputedPropChange} onCancel={() => setShouldShowCPForm(false)} />
           : readOnly
             ? null
             : <button onClick={() => setShouldShowCPForm(true)} style={{marginBottom: 5, marginTop: 10}}>Add Computed Property</button>
        }
      </div>
      <hr/>
      <div>
        <div style={{fontSize: 15, fontWeight: "bold", marginBottom: 5}}>Access Control</div>
        <EntityACLEditor onChange={onACLChange} />
      </div>
    </div>
  );
}
