// Generated from ./grammar/entity.g4 by ANTLR 4.7.1
// jshint ignore: start
var antlr4 = require('antlr4/index');

// This class defines a complete generic visitor for a parse tree produced by entityParser.

function entityVisitor() {
	antlr4.tree.ParseTreeVisitor.call(this);
	return this;
}

entityVisitor.prototype = Object.create(antlr4.tree.ParseTreeVisitor.prototype);
entityVisitor.prototype.constructor = entityVisitor;

// Visit a parse tree produced by entityParser#main.
entityVisitor.prototype.visitMain = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#entityDef.
entityVisitor.prototype.visitEntityDef = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#entityBlock.
entityVisitor.prototype.visitEntityBlock = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#enumerationDef.
entityVisitor.prototype.visitEnumerationDef = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#enumerationValue.
entityVisitor.prototype.visitEnumerationValue = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#accessControlDef.
entityVisitor.prototype.visitAccessControlDef = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#accessControl.
entityVisitor.prototype.visitAccessControl = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#accessControlValue.
entityVisitor.prototype.visitAccessControlValue = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#accessControlType.
entityVisitor.prototype.visitAccessControlType = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#accessControlBlock.
entityVisitor.prototype.visitAccessControlBlock = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#accessControlSuccess.
entityVisitor.prototype.visitAccessControlSuccess = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#accessControlFailure.
entityVisitor.prototype.visitAccessControlFailure = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#accessControlAuthorization.
entityVisitor.prototype.visitAccessControlAuthorization = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#accessControlLambda.
entityVisitor.prototype.visitAccessControlLambda = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#accessControlAuthorizationValue.
entityVisitor.prototype.visitAccessControlAuthorizationValue = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#propsDef.
entityVisitor.prototype.visitPropsDef = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#storedProp.
entityVisitor.prototype.visitStoredProp = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#metaPropertyDef.
entityVisitor.prototype.visitMetaPropertyDef = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#metaPropertyValidationDef.
entityVisitor.prototype.visitMetaPropertyValidationDef = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#metaPropertyValidation.
entityVisitor.prototype.visitMetaPropertyValidation = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#metaPropertyValidationRegexp.
entityVisitor.prototype.visitMetaPropertyValidationRegexp = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#metaPropertyValidationFailure.
entityVisitor.prototype.visitMetaPropertyValidationFailure = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#computedDef.
entityVisitor.prototype.visitComputedDef = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#computedProp.
entityVisitor.prototype.visitComputedProp = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#computedPropType.
entityVisitor.prototype.visitComputedPropType = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#metaComputedProp.
entityVisitor.prototype.visitMetaComputedProp = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#computedPropRel.
entityVisitor.prototype.visitComputedPropRel = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#indexDef.
entityVisitor.prototype.visitIndexDef = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#indexBlock.
entityVisitor.prototype.visitIndexBlock = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#indexField.
entityVisitor.prototype.visitIndexField = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#queriesDef.
entityVisitor.prototype.visitQueriesDef = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#queryBlock.
entityVisitor.prototype.visitQueryBlock = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#queryAccessControl.
entityVisitor.prototype.visitQueryAccessControl = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#queryOperation.
entityVisitor.prototype.visitQueryOperation = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#queryOperationBlock.
entityVisitor.prototype.visitQueryOperationBlock = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#queryCacheBlock.
entityVisitor.prototype.visitQueryCacheBlock = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#queryCacheStrategy.
entityVisitor.prototype.visitQueryCacheStrategy = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#queryCacheStrategyTTL.
entityVisitor.prototype.visitQueryCacheStrategyTTL = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#queryCacheStrategyTTLOption.
entityVisitor.prototype.visitQueryCacheStrategyTTLOption = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#queryCacheStrategyNever.
entityVisitor.prototype.visitQueryCacheStrategyNever = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#queryCacheKey.
entityVisitor.prototype.visitQueryCacheKey = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#queryCacheKeyValue.
entityVisitor.prototype.visitQueryCacheKeyValue = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#queryOperationRaw.
entityVisitor.prototype.visitQueryOperationRaw = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#queryOperationRawBlock.
entityVisitor.prototype.visitQueryOperationRawBlock = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#queryWhereBlock.
entityVisitor.prototype.visitQueryWhereBlock = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#queryWhereGroup.
entityVisitor.prototype.visitQueryWhereGroup = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#queryWhereJunction.
entityVisitor.prototype.visitQueryWhereJunction = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#queryWhere.
entityVisitor.prototype.visitQueryWhere = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#queryWhereField.
entityVisitor.prototype.visitQueryWhereField = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#queryWhereValue.
entityVisitor.prototype.visitQueryWhereValue = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#queryOperator.
entityVisitor.prototype.visitQueryOperator = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#queryRawBlock.
entityVisitor.prototype.visitQueryRawBlock = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#queryRawParamsBlock.
entityVisitor.prototype.visitQueryRawParamsBlock = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#queryRawParamBlock.
entityVisitor.prototype.visitQueryRawParamBlock = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#queryRawSqlBlock.
entityVisitor.prototype.visitQueryRawSqlBlock = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#queryOrderBlock.
entityVisitor.prototype.visitQueryOrderBlock = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#queryFieldSort.
entityVisitor.prototype.visitQueryFieldSort = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#literal.
entityVisitor.prototype.visitLiteral = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#binding.
entityVisitor.prototype.visitBinding = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#varIds.
entityVisitor.prototype.visitVarIds = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#qualId.
entityVisitor.prototype.visitQualId = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#qualIds.
entityVisitor.prototype.visitQualIds = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#exprs.
entityVisitor.prototype.visitExprs = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#expr.
entityVisitor.prototype.visitExpr = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#lambdaExpr.
entityVisitor.prototype.visitLambdaExpr = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#lambdaArgs.
entityVisitor.prototype.visitLambdaArgs = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#lambdaArg.
entityVisitor.prototype.visitLambdaArg = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#lambdaArgPrefix.
entityVisitor.prototype.visitLambdaArgPrefix = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#variableDeclExpr.
entityVisitor.prototype.visitVariableDeclExpr = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#conditionalExpr.
entityVisitor.prototype.visitConditionalExpr = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#ifExpr.
entityVisitor.prototype.visitIfExpr = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#matchExpr.
entityVisitor.prototype.visitMatchExpr = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#logicExpr.
entityVisitor.prototype.visitLogicExpr = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#arithmeticExpr.
entityVisitor.prototype.visitArithmeticExpr = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#arithmeticExprOperator.
entityVisitor.prototype.visitArithmeticExprOperator = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#arithmeticTerm.
entityVisitor.prototype.visitArithmeticTerm = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#arithmeticTermOperator.
entityVisitor.prototype.visitArithmeticTermOperator = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#arithmeticFactor.
entityVisitor.prototype.visitArithmeticFactor = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#simpleExpr.
entityVisitor.prototype.visitSimpleExpr = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#assignExpr.
entityVisitor.prototype.visitAssignExpr = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#assignValueExpr.
entityVisitor.prototype.visitAssignValueExpr = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#blockExpr.
entityVisitor.prototype.visitBlockExpr = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#block.
entityVisitor.prototype.visitBlock = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#caseClauses.
entityVisitor.prototype.visitCaseClauses = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#caseClause.
entityVisitor.prototype.visitCaseClause = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#patternExpr.
entityVisitor.prototype.visitPatternExpr = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#pattern.
entityVisitor.prototype.visitPattern = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#arithmeticOperator.
entityVisitor.prototype.visitArithmeticOperator = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#logicOperator.
entityVisitor.prototype.visitLogicOperator = function(ctx) {
  return this.visitChildren(ctx);
};



exports.entityVisitor = entityVisitor;