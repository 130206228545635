import React, { useRef, useState, useEffect, useCallback, useContext } from 'react'
import 'brace'
import 'brace/theme/textmate'
import AceEditor from 'react-ace'
import LaskarMode from '../../ace/syntax'
import { parse } from '../../parser'
import { format } from '../../formatter'
import { mergeDeepRight } from 'ramda'
import GraphiQL from 'graphiql'
import * as R from 'ramda'

import { ViewedObjectNameContext, EditMapContext, useViewedObject, useViewedSchema, useBranch } from './Contexts';

import EntityEditorForm from './EntityEditorForm';
import EnumEditorForm from './EnumEditorForm';
import NewObjectCreationForm from './NewObjectCreationForm';

const ViewMode = {
  EDITOR: 'EDITOR',
  FORM: 'FORM',
  GRAPHIQL: 'GRAPHIQL'
}

const laskarMode = new LaskarMode();

function GraphiQLWrapper(props) {
  let branch = useBranch();

  async function fetcher(graphQLParams) {
    const hash = branch.histories.length ? branch.histories[0].hash : branch.hash;
    const res = await fetch(window.location.origin + `/graphiql/${hash}`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(graphQLParams),
    })
    return res.json()
  }
  return <GraphiQL fetcher={fetcher} />
}

function TabView(props) {
  let { object, isViewed, isDirty, onClick, onClose, width } = props;
  let [isHovered, setIsHovered] = useState(false);

  return (  
    <div 
      style={{position: "relative"}} 
      onMouseOver={() => setIsHovered(true)} 
      onMouseLeave={() => setIsHovered(false)}>
      <div style={{
        padding: "8 0", //"8 24 8 8",
        textAlign: "center",
        width: width,
        textOverflow: "ellipsis",
        overflow: "hidden",
        fontSize: 13,
        cursor: "pointer",
        fontFamily: "monospace",
        background: isViewed ? "#ddd": "#eee",
        borderRight: "1px solid #ccc"
      }} onClick={onClick}>
        {object.name}
        {isDirty ? " *" : null}
      </div>

      {isHovered &&
      <img
        onClick={onClose}
        src="https://cdn3.iconfinder.com/data/icons/interface/100/close_button_2-512.png"
        style={{top: 4, right: 4, width: 15, height: 15, padding: 2, background: "#ddd", position: "absolute"}}/>}
    </div>
  );
}

function getTabWidth(width, tabCount) {
  const maxTabWidth = 120;
  return (width > (tabCount * (maxTabWidth - 1)) ? maxTabWidth : (width / tabCount)) - 2;
}

function TabViews(props) {
  let { viewedObjectName, tabObjectNames, objectBufferMap, width, onCreateClick, onObjectClosed, onObjectSelected } = props;
  let schema = useViewedSchema();
  let editMap = useContext(EditMapContext);
  let tabCount = tabObjectNames.length;

  // We need to "freeze" the tab count so tab width calculation is consistent  
  // before you move your mouse away from the tab close button -- this makes
  // sure the button doesn't move away under your cursor as you delete tabs. 
  // This helps the developer close a large amount of tabs in one go.
  let [frozenTabCount, setFrozenTabCount] = useState(0);
  let tabWidth = getTabWidth(width, frozenTabCount > 0 ? frozenTabCount : tabCount);

  return <div 
    onMouseEnter={() => setFrozenTabCount(tabCount)}
    onMouseLeave={() => setFrozenTabCount(0)}
    style={{display: 'flex', background: "#f7f7f7", flex: '1 1 28px'}}>
    {tabObjectNames.map((objectName) => {
      const object = schema.objects[objectName];
      const isContentDirty = objectBufferMap[objectName] != null || editMap[objectName] != null;

      return (
        <TabView
          width={tabWidth}
          key={`${object.name}`}
          object={object}
          isDirty={isContentDirty}
          isViewed={objectName === viewedObjectName}
          onClick={() => onObjectSelected(objectName)}
          onClose={() => onObjectClosed(objectName)}
        />
      )
    })}

    <button onClick={onCreateClick}>+</button>
  </div>
}

function ObjectEditorForm(props) {
  let { onEdited, onSaved } = props;
  let [object, isEdited] = useViewedObject();
  let branch = useBranch();
  let readOnly = branch.closed || branch.merged;

  if (object.__type === "Entity") {
    return <EntityEditorForm onEdited={onEdited} onSaved={onSaved} />;
  } else if (object.__type === "Enum") {
    return <EnumEditorForm onEdited={onEdited} onSaved={onSaved} />;
  } else {
    return <div>Unsupported object type</div>;
  }
}

function DSLEditor(props) {
  let { viewedObjectName, objectBufferMap, objectSavingStateMap, schema, onChange, onError } = props;
  let aceEditor = useRef(null);
  let [ errors, setErrors ] = useState([]);
  let [ dsl, setDsl ] = useState("");
  let [ initialFormat, setInitialFormat ] = useState(false);
  let isSaving = objectSavingStateMap[viewedObjectName];
  let isContentDirty = objectBufferMap[viewedObjectName] != null;
  let branch = useBranch();
  let readOnly = branch.closed || branch.merged;

  function onTextChange(value) {
    setInitialFormat(false);
    setDsl(value);
  }

  useEffect(() => {
    const value = isContentDirty ? objectBufferMap[viewedObjectName] : format(schema.objects[viewedObjectName])
    if(isContentDirty) {
      setInitialFormat(false);
    } else {
      setInitialFormat(true);
    }
    setDsl(value)
  }, [viewedObjectName])

  useEffect(_ => {
    if(dsl) {
      let parsedSchema = mergeDeepRight({objects: {}}, schema)
      parse(dsl, 'main', parsedSchema, setErrors)
      if(!initialFormat) {
        onChange(parsedSchema);
      }
    }
  }, [dsl]);

  useEffect(_ => {
    if (aceEditor.current) {
      const editorSession =  aceEditor.current.editor.getSession();
      editorSession.setMode(laskarMode);
      editorSession.setOption("useWorker", false);
      editorSession.setAnnotations(errors);
    }
    onError(errors)
  }, [errors])

  return (
    <AceEditor
      key={viewedObjectName}
      ref={aceEditor}
      mode="text"
      theme="textmate"
      width="100%"
      height="600"
      onChange={onTextChange}
      readOnly={readOnly || isSaving}
      value={dsl}
      tabSize={2}
      editorProps={{
        $blockScrolling: Infinity
      }}
    />
  );
}

export default function SchemaCodeEditor(props) {

  let { width, tabObjectNames, viewedObjectName,
    objectBufferMap, objectSavingStateMap,
    onObjectClosed, onObjectSelected, onObjectCreated } = props;

  let branch = useBranch();
  let readOnly = branch.closed || branch.merged;
  let schema = useViewedSchema();
  let [viewMode, setViewMode] = useState(ViewMode.FORM);
  let [errors, setErrors] = useState([]);
  let [editedSchema, setEditedSchema] = useState({objects: {}});
  let [editMap, setEditMap] = useState({});
  let [createFormActive, setCreateFormActive] = useState(false);
  
  function onChange(editedSchema) {
    props.onObjectEdited(props.viewedObjectName, editedSchema.objects[props.viewedObjectName]);
    setEditedSchema(editedSchema);
  }

  useEffect(() => {
    // Hide create form when a different object name is selected.
    setCreateFormActive(false);
  }, [viewedObjectName]);

  async function onSaveButtonClick() {
    // TODO: Consider just passing the object up to the onObjectSaved -- vs merging the schema here

    // isContentDirty on new editMap
    if (editMap[viewedObjectName] != null) {
      let newSchema = R.assocPath(['objects', viewedObjectName], editMap[viewedObjectName], schema);
      await props.onObjectSaved(viewedObjectName, newSchema);
      setEditMap(R.omit([viewedObjectName], editMap));
    
      // isContentDirty on the old objectBufferMap
    } else if (objectBufferMap[viewedObjectName] != null) {
      let mergedSchema = Object.keys(editedSchema.objects).reduce((mergedSchema, objectName) => {
        if (editedSchema.objects[objectName]) {
          mergedSchema.objects[objectName] = editedSchema.objects[objectName];
        }
        return mergedSchema;
      }, schema);

      props.onObjectSaved(viewedObjectName, mergedSchema);
    }
  }

  function onClearChangeButtonClick() {
    setEditMap(R.omit([viewedObjectName], editMap)); 
  }

  let onObjectEdited = useCallback(function(newObject) {
    let savedObject = schema.objects[viewedObjectName];
    let isNewObjectDifferent = !savedObject || !R.equals(savedObject, newObject);

    if (isNewObjectDifferent) {
      setEditMap(R.assocPath([viewedObjectName], newObject, editMap));
    } else {
      setEditMap(R.dissocPath([viewedObjectName], editMap));
    }
  }, [viewedObjectName, editMap]);

  function getViewer() {
    let viewer;

    if (viewMode === ViewMode.EDITOR) {
      console.log(schema);
      viewer = <DSLEditor {...props} onChange={onChange} onError={setErrors} />;
    } else if (viewMode === ViewMode.FORM) {
      viewer = <ObjectEditorForm key={viewedObjectName} onEdited={onObjectEdited} />;
    } else {
      viewer = <GraphiQLWrapper {...props} />;
    }

    return viewer;
  }

  function getTopBar() {
    let isContentDirty = objectBufferMap[viewedObjectName] != null || editMap[viewedObjectName] != null;
    let isSaving = objectSavingStateMap[viewedObjectName];
    let isError = errors.length > 0;
  
    let viewedObject = schema.objects[viewedObjectName];
    let editorModeSupported = viewedObject.__type !== "Application";
    let formModeSupported = ["Entity", "Application", "Enum", "Component"].indexOf(viewedObject.__type) > -1;
  
    // Reset viewMode for object types that are not supported by Form mode yet.
    if (!formModeSupported && viewMode === ViewMode.FORM) {
      viewMode = ViewMode.EDITOR;
    } else if (!editorModeSupported) {
      viewMode = ViewMode.FORM;
    }
  
    let saveButtonText = isSaving ? "Saving..." : (isContentDirty ? "Save" : "Saved");
    let disableSaveButton = isError || isSaving || !isContentDirty; 
  
    return (
      <div style={{padding: 5, background: "#ddd", borderBottom: "1px solid #ccc", flex: 1}}>
        <button onClick={onSaveButtonClick} disabled={disableSaveButton}>{saveButtonText}</button>
        {(formModeSupported && viewMode === ViewMode.FORM) && <button onClick={onClearChangeButtonClick} disabled={!isContentDirty}>Clear Changes</button>}
        <div style={{float: 'right'}}>
          <button onClick={() => setViewMode(ViewMode.GRAPHIQL)} disabled={viewMode === ViewMode.GRAPHIQL}>GraphiQL</button>
          { editorModeSupported && <button onClick={() => setViewMode(ViewMode.EDITOR)} disabled={viewMode === ViewMode.EDITOR}>DSL</button> } 
          { formModeSupported && <button onClick={() => setViewMode(ViewMode.FORM)} disabled={viewMode === ViewMode.FORM}>Form</button> }
        </div>
      </div>
    );
  }


  if (!viewedObjectName && !createFormActive) {
    return readOnly
      ? null
      : (<div style={{padding: 5, fontSize: 13}}>
        Please select an object to edit or
        <button onClick={() => setCreateFormActive(true)}>Create Object</button>
      </div>)
    ;
  }
 
  return (
    <ViewedObjectNameContext.Provider value={viewedObjectName}>
      <EditMapContext.Provider value={editMap}>
        <TabViews 
          width={width}
          viewedObjectName={viewedObjectName}
          objectBufferMap={objectBufferMap}
          tabObjectNames={tabObjectNames}
          onCreateClick={() => setCreateFormActive(true)} 
          onObjectClosed={onObjectClosed} 
          onObjectSelected={onObjectSelected} />

          <div style={{display: 'flex', flex: '1 1 100%', overflow: 'scroll'}}>
            <div style={{position: 'relative', width: '100%', display: 'flex', flexDirection: 'column'}}>

            {createFormActive ? 
              <NewObjectCreationForm onObjectCreated={onObjectCreated} />
              :
              <div>
                { getTopBar() }
                <div style={{flex: 50}}>
                  {getViewer()}
                </div>
              </div>
            }
            </div>
          </div>
      </EditMapContext.Provider>
    </ViewedObjectNameContext.Provider>
  );
}
