import gql from "graphql-tag"

export const queries = {
  schemaList: gql`query getBranchList(
    $commitPage:Int!,
    $commitPageSize:Int!,
    $commitSortField:CommitSortField!,
    $commitSortDirection:SortDirection!,
    $commitSearch:CommitSearchInput,
    $branchPage:Int!,
    $branchPageSize:Int!,
    $branchSortField:BranchSortField!,
    $branchSortDirection:SortDirection!,
    $branchTitle:BranchSearchInput,
    $branchClosed:BranchSearchInput) {
    getCommitPage(page:$commitPage,pageSize:$commitPageSize,searchAny:$commitSearch,sortField:$commitSortField,sortDirection:$commitSortDirection) {
      page
      total
      items {
        id
        hash
        committer
        message
        active
        commitDate
      }
    }
    getBranchPage(page:$branchPage,pageSize:$branchPageSize,searchAny:$branchSearch,search:$branchClosed,sortField:$branchSortField,sortDirection:$branchSortDirection) {
      page
      total
      items {
        id
        hash
        message
        author
        merged
        closed
        originCommitHash
        createdAt
      }
    }
  }`,
  schemaDetail: gql`query getBranchByHash($hash:String!) {
    getBranchByHash(hash:$hash) {
      id
      hash
      author
      merged
      closed
      message
      origin {
        hash
      }
      histories {
        id
        hash
        author
        message
        createdAt
      }
    }
    getActiveCommit {
      id
      hash
    }
  }`,
  branchList: gql`query getBranchList {
    getBranchList {
      hash
    }
  }`,
  getActiveCommit: gql`query getActiveCommit {
    getActiveCommit {
      id
      hash
      message
      active
    }
  }`
}

export const mutations = {
  forkCommit: gql`mutation createBranch($input:BranchInput!) {
    insertBranch(input:$input) {
      id
      hash
      author
      originCommitHash
    }
  }`,
    rebaseBranch: gql`mutation rebaseBranch($input: BranchInput, $history: BranchHistoryInput) {
    editBranch(input: $input) {
      id
      hash
      originCommitHash
    }
    insertBranchHistory(input: $history)
  }`,
  insertBranchHistory: gql`mutation insertBranchHistory($input: BranchHistoryInput) {
    insertBranchHistory(input:$input) {
      id
    }
  }`,
  commitBranch: gql`mutation mergeBranch($input: BranchInput, $commit: CommitInput) {
    editBranch(input: $input) {
      merged
    }
    insertCommit(input: $commit) {
      id
      hash
      active
      commitDate
    }
  }`,
  activateCommit: gql`mutation activateCommit($input: CommitInput) {
    editCommit(input: $input) {
      id
      active
    }
  }`,
  deactivateCommit: gql`mutation deactivateCommit($input: CommitInput) {
    editCommit(input: $input) {
      id
      active
    }
  }`,
  setClosedBranch: gql`mutation closedBranch($input: BranchInput) {
    editBranch(input: $input) {
      hash
    }
  }`
}
