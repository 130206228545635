import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import LinearProgress from '@material-ui/core/LinearProgress'

type Props = {
  title: string,
  message: string,
  closesable: bool,
  closeLabel: ?string,
  showProgress: bool,
  open: bool,
  onClose: ?Function
}
export default function ProgressModal(props: Props) {
  const { title, message, closeable, closeLabel, onClose, showProgress, open, children } = props

  const handleClose = () => {
    if(onClose) onClose()
  }

  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {message}
        <br/>
        <br/>
        {showProgress ? <LinearProgress/> : null}
      </DialogContent>
      { closeable
        ? (<DialogActions><Button onClick={handleClose} color="primary">{closeLabel||"Close"}</Button></DialogActions>)
        : null
      }
    </Dialog>
  )
}
