import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

type Props = {
  open: PropTypes.boolean.isRequired,
  onConfirmed: PropTypes.func.isRequired,
  onCancelled: PropTypes.func
}
export default class CommitModal extends Component<Props> {
  constructor(props) {
    super(props)
    this.handleCancel = this.handleCancel.bind(this)
  }

  handleCancel() {
    if(typeof this.props.onCancelled === 'function') {
      this.props.onCancelled()
    }
  }

  render() {
    const { statements, onConfirmed, onCancelled, open } = this.props
    return (
      <Dialog open={open} onClose={onCancelled}>
        <DialogTitle>Are you sure you want to commit this branch?</DialogTitle>
        <DialogActions>
          <Button onClick={this.handleCancel} color="primary" autoFocus>Cancel</Button>
          <Button onClick={onConfirmed} color="primary">OK</Button>
        </DialogActions>
      </Dialog>
    )
  }
}
