import ace from 'brace'

const patterns = {
  variable: /[$]?\b[a-zA-Z0-9_]+\b/,
  type: /\b[A-Z][a-zA-Z0-9_]+\b/
}

const withNext = next => fn => fn(next)

let rules = {
  "start": [
    { token: 'empty_line', regex: /^$/ },
    { token: 'keyword',    regex: /^acl/,    next: 'queryAcl' },
    { token: 'keyword',    regex: /^entity/, next: 'entity' },
    { token: 'keyword',    regex: /^query/,  next: 'query' },
    { token: 'keyword',    regex: /^enum/,  next: 'enum' }
  ]
}

const variableRule = (next) => [{token: 'support.type', regex: patterns.variable, next}]

const typeRule = (next) => [{token: 'variable.language', regex: patterns.type, next}]

const commonRule = (next) => typeRule(next).concat(variableRule(next))

const quoteRule = (next) => [{token: 'string', regex: /'/, next}, {defaultToken: 'string'}]

const dquoteRule = (next) => [{token: 'string', regex: /"/, next}, {defaultToken: 'string'}]

const multilineRule = (next) => [
  { token: 'string', regex: /"""/, next },
  { defaultToken: 'string' }
]

// Compose raw query rule, to be re-used by computedProps meta block
const queryRule = (prefix = '', additionalRules = [], next = null) => {
  const QUERY = `${prefix}query`
  const QUERY_BLOCK = `${prefix}query.block`
  const QUERY_BLOCK_CACHE = `${prefix}query.block.cache`
  const QUERY_BLOCK_CACHE_TTL = `${prefix}query.block.cache.ttl`
  const QUERY_BLOCK_RAW = `${prefix}query.block.raw`
  const QUERY_BLOCK_RAW_PARAMS = `${prefix}query.block.raw.params`
  const QUERY_BLOCK_RAW_STATEMENT = `${prefix}query.block.raw.statement`
  const QUERY_BLOCK_RAW_STATEMENT_SQL = `${prefix}query.block.raw.statement.sql`
  const QUERY_BLOCK_WHERE = `${prefix}query.block.where`
  const QUERY_BLOCK_ORDER = `${prefix}query.block.order`
  const rules = {}
  rules[QUERY] = [
    { token: 'keyword', regex: /^query/ },
    { token: 'text',    regex: /[a-zA-Z0-9_]+[\s]*(?:[(])/ },
    { token: 'text',    regex: /[{]/, next: QUERY_BLOCK}
  ].concat(commonRule(QUERY))
  rules[QUERY_BLOCK] = additionalRules.concat([
      { token: 'keyword', regex: /\brawQuery\b/, next: QUERY_BLOCK_RAW },
      { token: 'keyword', regex: /\bcache\b/, next: QUERY_BLOCK_CACHE },
      { token: 'keyword', regex: /\bwhere\b/, next: QUERY_BLOCK_WHERE },
      { token: 'keyword', regex: /\borderBy\b/, next: QUERY_BLOCK_ORDER },
      { token: 'text', regex: /[}]/, next: next ? next : QUERY },
      { defaultToken: 'text' }
    ])
  rules[QUERY_BLOCK_CACHE] = [
      { token: 'keyword', regex: /\bttl\b/, next: QUERY_BLOCK_CACHE_TTL },
      { token: 'keyword', regex: /\bnever\b/ },
      { token: 'text', regex: /[}]/, next: QUERY_BLOCK },
      { defaultToken: 'text' }
    ].concat(commonRule(QUERY_BLOCK_CACHE))
  rules[QUERY_BLOCK_CACHE_TTL] = [
      { token: 'keyword', regex: /\b(key|seconds)\b/ },
      { token: 'text', regex: /[}]/, next: QUERY_BLOCK_CACHE },
      { defaultToken: 'text' }
    ].concat(commonRule(QUERY_BLOCK_CACHE_TTL))
  rules[QUERY_BLOCK_RAW] = [
      { token: 'keyword', regex: /\bparams\b/, next: QUERY_BLOCK_RAW_PARAMS },
      { token: 'keyword', regex: /\bquery\b/, next: QUERY_BLOCK_RAW_STATEMENT },
      { token: 'text', regex: /[}]/, next: QUERY_BLOCK },
      { defaultToken: 'text' }
    ].concat(commonRule(QUERY_BLOCK_RAW))
  rules[QUERY_BLOCK_RAW_PARAMS] = [
      { token: 'text', regex: /[}]/, next: QUERY_BLOCK_RAW },
      { defaultToken: 'text' }
    ].concat(commonRule(QUERY_BLOCK_RAW_PARAMS))
  rules[QUERY_BLOCK_RAW_STATEMENT] = [
      { token: 'string', regex: /"""/, next: QUERY_BLOCK_RAW_STATEMENT_SQL },
      { token: 'string', regex: /}/,   next: QUERY_BLOCK_RAW_STATEMENT }
    ]
  rules[QUERY_BLOCK_RAW_STATEMENT_SQL] = [
      { token: 'constant.language', regex: /[$]\b[a-zA-Z0-9_]+\b/, next: QUERY_BLOCK_RAW_STATEMENT_SQL },
      { token: 'text',              regex: /}/,                    next: QUERY_BLOCK_RAW_STATEMENT }
    ].concat(multilineRule(QUERY_BLOCK_RAW_STATEMENT)),
  rules[QUERY_BLOCK_WHERE] = [
      { token: 'contant.language', regex: /\b(and|or)\b/ },
      { token: 'text', regex: /[}]/, next: QUERY_BLOCK },
    ].concat(commonRule(QUERY_BLOCK_WHERE))
  rules[QUERY_BLOCK_ORDER] = [
      { token: 'contant.language', regex: /\b(asc|desc)\b/ },
      { token: 'text', regex: /[}]/, next: QUERY_BLOCK },
    ].concat(commonRule(QUERY_BLOCK_WHERE))
  return rules
}

const queryAclRule = (next) => {
  rules = Object.assign(rules, {
    'queryAcl': [
      { token: 'constant.language', regex: /(list|read|create|update|delete)/, next }
    ]
  })
  return next
}

const registerQueryRule = (next) => {
  queryAclRule('start')
  rules = Object.assign(rules, queryRule())
  return next
}

const entityRule = (next) => {
  const entityComputedPropsQueryRules = queryRule('entity.computedProps.block.meta.', [
    { token: 'variable.language', regex: /\bself\b/, next: 'query.block' },
  ], 'entity.computedProps.block')
  rules = Object.assign(rules, entityComputedPropsQueryRules, {
    'entity': [
      { token: 'keyword', regex: /storedProps/,   next: 'entity.storedProps' },
      { token: 'keyword', regex: /computedProps/, next: 'entity.computedProps' },
      { token: 'keyword', regex: /accessControl/, next: 'entity.accessControl' },
      { token: 'keyword', regex: /index/,         next: 'entity.index' },
      { token: 'text',    regex: /[}]/,           next: 'start' }
    ],
    'entity.index': [
      { token: 'text', regex: /[{]/, next: 'entity.indexBody' },
      { token: 'text', regex: /[}]/, next: 'entity' }
    ],
    'entity.indexBody': [
      { token: 'variable.language', regex: /[a-z][a-zA-Z0-9_]*/ },
      { token: 'text',              regex: /[{]/, next: 'entity.indexBodyFields' },
      { token: 'text',              regex: /[}]/, next: 'entity' }
    ],
    'entity.indexBodyFields': [
      { token: 'variable.language', regex: /[a-z][a-zA-Z0-9_]*/ },
      { token: 'constant.language', regex: /(ASC|DESC)/ },
      { token: 'text',              regex: /[}]/,       next: 'entity.indexBody' }
    ],
    'entity.accessControl': [
      { token: 'text', regex: /[{]/, next: 'entity.accessControlBody' },
      { token: 'text', regex: /[}]/, next: 'entity' }
    ],
    'entity.accessControlBody': [
      { token: 'variable.language', regex: /\bread|create|delete|update|list\b/ },
      { token: 'constant.buildin',  regex: /\balways|employee|forbidden\b/ },
      { token: 'text',              regex: /[}]/,  next: 'entity' },
      { token: 'text',              regex: /[{]/,  next: 'entity.accessControlLambda' },
    ],
    'entity.accessControlLambda': [
      { token: 'variable.language', regex: /~/},
      { token: 'constant.buildin',  regex: /\bwithError\b/},
      { token: 'string',            regex: /"[^"]+"/ },
      { token: 'text',              regex: /[}]/, next: 'entity.accessControlBody'}
    ].concat(commonRule('entity.accessControlLambda')),
    "entity.storedProps": [
      { token: 'text', regex: /[{]/, next: 'entity.storedProps.block' },
      { defaultToken: 'text '}
    ],
    "entity.storedProps.block": [
      { token: 'text', regex: /[{]/, next: 'entity.storedProps.block.meta' },
      { token: 'text', regex: /[}]/, next: 'entity' },
    ].concat(typeRule('entity.storedProps.block')),
    "entity.storedProps.block.meta": [
      { token: 'keyword', regex: /\bsearchableKeyword|searchable|unique|regexp|failure|identity|message\b/, },
      { token: 'keyword', regex: /\bvalidation\b/, next: 'entity.storedProps.block.meta.validation' },
      { token: 'text', regex: /[}]/, next: 'entity.storedProps.block' },
      { defaultToken: 'text' }
    ],
    "entity.storedProps.block.meta.validation": [
      { token: 'text', regex: /[{]/, next: 'entity.storedProps.block.meta.validation.rule' },
      { token: 'text', regex: /[}]/, next: 'entity.storedProps.block.meta' },
      { defaultToken: 'text' }
    ],
    "entity.storedProps.block.meta.validation.rule": [
      { token: 'keyword', regex: /\bregexp|failure|message\b/, },
      { token: 'string.regexp', regex: /'[^']+'/ },
      { token: 'string.regexp', regex: /"[^"]+"/ },
      { token: 'text', regex: /[}]/, next: 'entity.storedProps.block.meta.validation' }
    ],
    "entity.computedProps": [
      { token: 'text', regex: /[{]/, next: 'entity.computedProps.block' },
      { token: 'text', regex: /[}]/, next: 'entity'},
      { defaultToken: 'text '}
    ],
    'entity.computedProps.block': [
      { token: 'text', regex: /[{]/, next: 'entity.computedProps.block.meta.query.block' },
      { token: 'text', regex: /[}]/, next: 'entity.computedProps'},
    ].concat(typeRule('entity.computedProps.block')),
  })

  return next
}

const enumRule = (next) => {
  rules = Object.assign(rules, {
    'enum': [
      { token: 'text', regex: /[{]/, next: 'enum.block'},
    ].concat(typeRule('enum')),
    'enum.block': [
      { token: 'text', regex: /[}]/, next: 'start' },
      { defaultToken: 'text' }
    ]
  })

  return next
}

export class LaskarHighlightRules extends ace.acequire("ace/mode/text_highlight_rules").TextHighlightRules {

  getRules() {
    console.log("LaskarHighlightRules.getRules()", rules)
    return rules
  }
}

export default class LaskarMode extends ace.acequire("ace/mode/text").Mode {
  constructor() {
    super()
    enumRule('start')
    entityRule('start')
    registerQueryRule('start')
    this.HighlightRules = LaskarHighlightRules
  }
}
