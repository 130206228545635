import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

type Props = {
  statements: PropTypes.array.isRequired,
  onConfirmed: PropTypes.func.isRequired,
  onCancelled: PropTypes.func,
  env: PropTypes.string
}
export default class MigrationModal extends Component<Props> {
  constructor(props) {
    super(props)
    this.handleCancel = this.handleCancel.bind(this)
  }

  handleCancel() {
    if(typeof this.props.onCancelled === 'function') {
      this.props.onCancelled()
    }
  }

  render() {
    const { statements, onConfirmed, onCancelled, env } = this.props
    return (
      <Dialog open={statements.length > 0} onClose={onCancelled}>
        <DialogTitle>Run the following migrations {env ? `to ${env}` : ''}?</DialogTitle>
        <DialogContent>
          <ol>
            {statements.map((stmt, idx) => <li key={idx}>{stmt}</li>)}
          </ol>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel} color="primary" autoFocus>Cancel</Button>
          <Button onClick={onConfirmed} color="primary">OK</Button>
        </DialogActions>
      </Dialog>
    )
  }
}
