export const RESERVE_KEYWORDS = {
    LET: "let",
    IF: "if",
    ELSE: "else",
    MATCH: "match",
    CASE: "case"
}

export const BUILT_IN_TYPES = {
  BOOL: "BOOL",
  INT: "INT",
  FLOAT: "FLOAT",
  STRING: "STRING",
  UUID: "UUID",
  LAMBDA: "LAMBDA",
  REFERENCE: "REFERENCE"
}

export const BINARY_OP_TYPES = {
  ADDITION: "ADDITION",
  SUBTRACTION: "SUBTRACTION",
  MULTIPLICATION: "MULTIPLICATION",
  DIVISION: "DIVISION",
  MODULO: "MODULO"
}

export const LOGICAL_OP_TYPES = {
  AND: "AND",
  OR: "OR",
  EQUALS: "EQUALS",
  LESS_THAN: "LESS_THAN",
  LESS_THAN_EQUALS: "LESS_THAN_EQUALS",
  GREATER_THAN: "GREATER_THAN",
  GREATER_THAN_EQUALS: "GREATER_THAN_EQUALS"
}

export class Symbol {
  constructor(name) {
    this.name = name
  }
}

export class NullSymbol extends Symbol {
  constructor() {
    super('Null')
  }
}

export class TypeSymbol extends Symbol {
  constructor(name, isCollection = false) {
    super(name)
    this.isCollection = false
  }
}

export class ExpressionSymbol extends Symbol {
}

export class BinaryOpSymbol extends ExpressionSymbol {
  constructor(name, lft = null, rgt = null) {
    super(name)
    this.lft = lft
    this.rgt = rgt
  }
}

export class VariableSymbol extends Symbol {
  constructor(name, type, isCollection = false) {
    super(name)
    this.type = type
    this.isCollection = isCollection
  }
}

export class LiteralSymbol extends Symbol {
  constructor(type, value) {
    super(type)
    this.value = value
  }
}

export class ReferenceSymbol extends Symbol {
  constructor(name, reference) {
    super(name)
    this.reference = reference
  }
}

export class FunctionSymbol extends Symbol {
  constructor(name, args, type) {
    super(name)
    this.arguments = args
    this.type = type
  }
}

export class EntitySymbol extends Symbol {
  constructor(name, accessControl, storedProps, computedProps, indexes) {
    super(name)
    this.accessControl = accessControl
    this.storedProps = storedProps
    this.computedProps = computedProps
    this.indexes = indexes
  }
}

export class EnumerationSymbol extends Symbol {
  constructor(name, values = []) {
    super(name)
    this.values = values
  }
}

export class PropertySymbol extends Symbol {
  constructor(name, type, entity) {
    super(name)
    this.type = type
    this.entity = entity
  }
}

export class AccessControlSymbol extends Symbol {
  constructor(name, success, failure, authorization, lambdas = []) {
    super(name)
    this.success = success
    this.failure = failure
    this.authorization  = authorization
    this.lambdas = lambdas
  }
}

export class StoredPropertySymbol extends Symbol {
  constructor(name, type, identity, searchable = false, searchableKeyword = false, unique = false, validator = null) {
    super(name)
    this.type = type
    this.identity = identity
    this.searchable = searchable
    this.searchableKeyword = searchableKeyword
    this.unique = unique
    this.validator = validator
    this.identity = name === "id"
    this.isCollection = false
  }
}

export class ComputedPropertySymbol extends Symbol {
  constructor(name, type, isCollection, isNullable = false, where = [], orderBy = [], rawQuery = null) {
    super(name)
    this.type = type
    this.isCollection = isCollection
    this.where = where
    this.orderBy = orderBy
    this.rawQuery = rawQuery
    this.isNullable = isNullable
  }
}

export class LambdaSymbol extends Symbol {
  constructor(name, args) {
    super(name)
    this.args = args
  }
}

export class QuerySymbol extends Symbol {
  constructor(acl, name, args, type, isCollection, where, orderBy, sql, isNullable, cache) {
    super(name)
    this.acl = acl
    this.args = args
    this.type = type
    this.isCollection = isCollection
    this.where = where
    this.orderBr = orderBy
    this.sql = sql
    this.isNullable = isNullable
    this.cache = cache
  }
}

export const IntType = new TypeSymbol("Int")
export const StringType = new TypeSymbol("String")
export const BoolType = new TypeSymbol("Bool")
export const FloatType = new TypeSymbol("Float")
export const UUIDType = new TypeSymbol("UUID")
export const LambdaType = new TypeSymbol("Lambda")
export const TimestampType = new TypeSymbol("Timestamp")
