import React from 'react'

import { useViewedSchema, useBranch } from './Contexts';

export default function NewObjectCreationForm(props) {
  let { onObjectCreated } = props;
  let schema = useViewedSchema();
  let branch = useBranch();
  let readOnly = branch.merged || branch.closed;

  function createObject(objectName, objectType) {
    let newObject = null
    switch(objectType) {
      case 'Entity':
        newObject = {
          name: objectName,
          __type: 'Entity',
          body: {
            storedProps: {},
            computedProps: {},
            accessControl: {
              create: { __type: "AccessControl", name: "create", authorization: true, lambdas: []},
              update: { __type: "AccessControl", name: "update", authorization: true, lambdas: []},
              delete: { __type: "AccessControl", name: "delete", authorization: true, lambdas: []},
              read: { __type: "AccessControl", name: "read", authorization: true, lambdas: []},
              list: { __type: "AccessControl", name: "list", authorization: true, lambdas: []},
            }
          }
        }
        break
      case 'Enum':
        newObject = {
          name: objectName,
          __type: "Enum",
          body: {
            values: []
          }
        }
        break;
      case 'Query':
        newObject = {
          "__type": "Query",
          "name": objectName,
          "body": {
            "type": objectName,
            "isCollection": false,
            "acl": "read",
            "args": [],
            "meta": {
              "where": [],
              "orderBy": [],
              "rawQuery": null
            }
          }
        }
    }
    
    onObjectCreated(newObject);
  }

  function onFormSubmit(e) {
    e.preventDefault();
    let name = e.target.name.value;
    let type = e.target.type.value;

    if (schema.objects.hasOwnProperty(name)) {
      alert("Object with that name exists!");
      return;
    }

    if (!/[A-Z]/.test(name[0]) && (type === 'Entity' || type === 'Enum')) {
      alert("Name needs to be capitalized for Entity and Enum!");
      return;
    }

    if (!/[a-z]/.test(name[0]) && type === 'Query') {
      alert("Name needs to be uncapitalized for Query!");
      return;
    }

    createObject(name, type);
  }

  return (
     readOnly
      ? null
      : <form onSubmit={onFormSubmit} style={{padding: 10}}>
        <h3>Create New Object</h3>
        <hr/>
        <label>Name</label>
        <input name="name" type="text" />
        <br/>
        <label>Object Type</label>
        <select name="type" defaultValue="Entity">
          <option value="Entity">Entity</option>
          <option value="Enum">Enum</option>
          <option value="Query">Query</option>
        </select>
        <br/>
        <br/>
        <button>Create</button>
      </form>
  );
}
