import { BoolType, IntType, FloatType, StringType, UUIDType, LambdaType, TimestampType } from './Type'

export class SymbolTable {
  constructor(parent, depth) {
    if(parent && (parent instanceof SymbolTable) === false) {
      throw new Error("SymbolTable parent must be an instance of SymbolTable class`")
    }
    this.parent   = parent
    this.symbols  = []
    this.children = []
    this.current  = this
  }

  _resolve(table, fn) {
    const resolved = table.symbols.find(fn)
    if(!resolved && table.parent) {
      return table._resolve(table.parent, fn)
    } else if(!resolved) {
      return null
    } else {
      return resolved
    }
  }

  _exists(table, fn) {
    let found = !!table.symbols.find(fn)
    if(!found && table.parent) {
      return table._exists(table.parent, fn)
    } else if(!found) {
      return false
    } else {
      return true
    }
  }

  exists(name) {
      return this.current._exists(this.current, (symbol) => symbol.name === name)
  }

  existsFunc(fn) {
    if (typeof fn !== 'function') {
      throw new Error('`existFunc` argument must be a function, ' + (typeof fn) + ' given')
    }
    return this.current._exists(this.current, fn)
  }

  append(symbol) {
    this.current.symbols.push(symbol)
  }

  resolve(name) {
    return this.current._resolve(this.current, (symbol) => symbol.name === name)
  }

  resolveFunc(fn) {
    if (typeof fn !== 'function') {
      throw new Error('`existFunc` argument must be a function, ' + (typeof fn) + ' given')
    }
   return this.current._resolve(this.current, fn)
  }

  enter() {
    const child = new SymbolTable(this.current)
    this.current.children.push(child)
    this.current = child
  }

  exit() {
    if(this.current.parent instanceof SymbolTable) {
      this.current = this.current.parent
    }
  }
}

export class ValueTable {
  constructor(parent, depth) {
    if(parent && (parent instanceof ValueTable) === false) {
      throw new Error("SymbolTable parent must be an instance of SymbolTable class`")
    }
    this.parent   = parent
    this.children = []
    this.properties = {}
    this.current  = this
  }

  _resolve(table, name) {
    const resolved = table.properties.find(key => key === name)(R.keys(this.current.properties))
    if(!resolved && table.parent) {
      return table._resolve(table.parent, name)
    } else if(!resolve) {
      return null
    } else {
      return table.properties[resolved]
    }
  }

  _exists(table, name) {
    let found = !!table.properties.find(key => key)(R.keys(this.current.properties))
    if(!found && table.parent) {
      return table._exists(table.parent, name)
    } else if(!found) {
      return false
    } else {
      return true
    }
  }

  exists(name) {
      return this.current._exists(this.current, name)
  }

  set(key, value, override) {
    // TODO: validate existing?
    if(this.current.properties[key] && !override) {
      throw new Error(`Cannot override property/variable \`${key}\``)
    } else if (this.current.properties[key]) {
      this.current.properties[key] = value
    } else {
      this.current.properties[key] = value
    }
  }

  get(name) {
    return this.current._resolve(this.current, name)
  }

  enter() {
    const child = new ValueTable(this.current)
    this.current.children.push(child)
    this.current = child
  }

  exit() {
    if(this.current.parent instanceof ValueTable) {
      this.current = this.current.parent
    }
  }
}

export class RootTable extends SymbolTable {
  constructor() {
    super()
    this.append(IntType)
    this.append(BoolType)
    this.append(StringType)
    this.append(FloatType)
    this.append(UUIDType)
    this.append(LambdaType)
    this.append(TimestampType)
  }
}
