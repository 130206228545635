import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

type Props = {
  branch: PropTypes.object.isRequired
}
export default class SchemaInfo extends Component<Props> {
  render() {
    const { branch } = this.props

    return <div style={{padding: 10, borderBottom: "1px solid #ccc", background: "rgb(240, 240, 240)", display: 'flex', flexDirection: 'column'}}>
      <Link to="/" style={{fontSize: 12}}>Back to Schema List</Link>
      <div style={{marginTop: 5, fontSize: 16, color: "#444"}}>
        #{branch.hash.slice(0,6)}: {branch.message} {branch.merged ? '- (Merged)' : ''}
      </div>
      <div>
        <div style={{color: "#888", fontSize: 14}}>
          <span> By {branch.author} </span>
          &bull;
          <span> Base: #{branch.origin.hash.slice(0, 6)}</span>
        </div>
      </div>
    </div>
  }
}
