import React, { Component } from "react"
import ReactDOM from "react-dom"
import 'babel-polyfill'
import { Switch, Route, Router, Link } from 'react-router-dom'
import SchemaManager from './schema'
import * as R from 'ramda'
import { parse } from './parser'
import { ApolloProvider, Query, Mutation } from 'react-apollo'
import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks';

import SchemaEditor from './components/editor/SchemaEditor'
import momentjs from 'moment'
import sha1 from 'sha1'
import ApolloClient from 'apollo-boost'
import {createBrowserHistory} from 'history'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import BranchList from './components/BranchList'
import CommitList from './components/CommitList'
import ActivateCommitModal from './components/ActivateCommitModal'
import TextField from '@material-ui/core/TextField'
import TablePagination from '@material-ui/core/TablePagination'
import Paper from '@material-ui/core/Paper'

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import {queries, mutations} from './graphql'
import axios from 'axios'

const history = createBrowserHistory()

const apolloClient = new ApolloClient({
  uri: '/graphql'
})

const schemaManager = new SchemaManager()

class SchemaEditorPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      baseSchema: {}
    }
  }

  render() {
    const {match: { params }} = this.props
    return (
      <div style={{display: 'flex', height: '100%', flexDirection: 'column'}}>
        <Header />
        <Query query={queries.schemaDetail} variables={{hash: params.hash}} fetchPolicy="cache-and-network">
          {({loading, error, data, refetch}) => {
            if(loading) {
              return (<div>Loading...</div>)
            } else if (error) {
              return (<div>{error}</div>)
            } else {
              return (<SchemaEditor profile={profile} schemaManager={schemaManager} branch={data.getBranchByHash} activeCommit={data.getActiveCommit} fetchActiveCommit={refetch}/>)
            }
          }}
        </Query>
      </div>
    );
  }
}

class SchemaListPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: 1,
      showActivationModal: false,
      activateCommit: null,
      selectedCommit: null,
      searchKeyword: null,
      searchKeywordInput: null,
      commitPage: 1,
      commitPageSize: 10,
      commitSortField: "commitDate",
      commitSortDirection: "DESC",
      branchPage: 1,
      branchPageSize: 10,
      branchSortField: "createdAt",
      branchSortDirection: "DESC",
      branchFilter: "all"
    }
    this.searchRef = React.createRef()
    this.handleActivateCommit = this.handleActivateCommit.bind(this)
    this.confirmActivateCommit = this.confirmActivateCommit.bind(this)
    this.cancelActivateCommit = this.cancelActivateCommit.bind(this)
    this.getListVariables = this.getListVariables.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.handleSearchInput = this.handleSearchInput.bind(this)
    this.handleCommitChangePage = this.handleCommitChangePage.bind(this)
    this.handleCommitChangeRowsPerPage = this.handleCommitChangeRowsPerPage.bind(this)
    this.handleBranchChangePage = this.handleBranchChangePage.bind(this)
    this.handleBranchChangeRowsPerPage = this.handleBranchChangeRowsPerPage.bind(this)
    this.handleFilterBranch = this.handleFilterBranch.bind(this)
    this.searchTimer = null
  }

  async openEditorPage(data) {
    const origin = await schemaManager.resolveSchemaByHash(data.originCommitHash)
    history.push(`/schema/${data.hash}/edit`)
  }

  toggleTab(id) {
    this.setState({activeTab: id})
  }

  handleActivateCommit(current, selected) {
    this.setState({showActivationModal: true, activeCommmit: current, selectedCommit: selected})
  }

  cancelActivateCommit() {
    this.setState({showActivationModal: false, activeCommmit: null, selectedCommit: null})
  }

  async confirmActivateCommit(deactivate, activate) {
    if(this.state.selectedCommit) {
      const activeCommmit = Object.assign({}, this.state.activeCommmit, {active: false, __typename: undefined})
      const selectedCommit = Object.assign({}, this.state.selectedCommit, {active: true, __typename: undefined})
      await deactivate({variables: { input: activeCommmit }})
      await activate({variables: { input: selectedCommit }})
      await schemaManager.activateCommit(this.state.selectedCommit.hash)
      this.setState({showActivationModal: false, activateCommit: null, selectedCommit: null})
    }
  }

  handleBranchChangePage(e, branchPage) {
    this.setState({branchPage: branchPage + 1})
  }

  handleBranchChangeRowsPerPage(e) {
    this.setState({branchPageSize: e.target.value})
  }

  handleCommitChangePage(e, commitPage) {
    this.setState({commitPage: commitPage + 1})
  }

  handleCommitChangeRowsPerPage(e) {
    this.setState({commitPageSize: e.target.value})
  }

  handleFilterBranch(e) {
    this.setState({ branchFilter: e.target.value })
  }

  getFilterBranch(activeTab){
    if(activeTab == 2){
      return (
        <FormControl>
          <InputLabel>Filters</InputLabel>
          <Select
            value={this.state.branchFilter}
            onChange={this.handleFilterBranch}
            inputProps={{onChange:this.handleFilterBranch}}
          >
            <MenuItem value="all">All Branch</MenuItem>
            <MenuItem value="open">Open Branch</MenuItem>
            <MenuItem value="closed">Closed Branch</MenuItem>
          </Select>
        </FormControl>
      )
    }
  }

  getTabContent(id, data) {
    switch(id) {
      case 1:
        const activeCommit = data.getCommitPage.items.find(_ => _.active)
        return (
          <div>
            <CommitList
              schemas={data.getCommitPage.items}
              onFork={data => this.openEditorPage(data.insertBranch)}
              user={profile.userName}
              onActivate={selected => this.handleActivateCommit(activeCommit, selected)}/>
            <Mutation mutation={mutations.deactivateCommit}>
              {(deactivate, {data}) =>
                <Mutation mutation={mutations.activateCommit}>
                  {(activate, {data}) =>
                    <ActivateCommitModal
                      open={this.state.showActivationModal}
                      onConfirmed={_ => this.confirmActivateCommit(deactivate, activate)}
                      onCancelled={this.cancelActivateCommit}
                    />
                  }
                </Mutation>
              }
            </Mutation>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50, 100]}
              component="div"
              count={data.getCommitPage.total}
              rowsPerPage={this.state.commitPageSize}
              page={data.getCommitPage.page - 1}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onChangePage={this.handleCommitChangePage}
              onChangeRowsPerPage={this.handleCommitChangeRowsPerPage}
            />
          </div>
        )
      case 2:
        return (
          <div>
            <BranchList branches={data.getBranchPage.items}/>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50, 100]}
              component="div"
              count={data.getBranchPage.total}
              rowsPerPage={this.state.branchPageSize}
              page={data.getBranchPage.page - 1}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onChangePage={this.handleBranchChangePage}
              onChangeRowsPerPage={this.handleBranchChangeRowsPerPage}
            />
          </div>
        )
    }
  }

  handleSearchInput(e) {
    if(e){
      const searchKeywordInput = e.target.value
      this.setState({searchKeywordInput})
    }
  }

  handleSearch(e) {
    const searchKeywordInput = e.target.value
    if(e.charCode === 13) {
      this.setState({searchKeyword: searchKeywordInput, searchKeywordInput})
    } else {
      this.setState({searchKeywordInput})
    }
  }

  getListVariables() {
    const { commitPage, commitPageSize, commitSortField, commitSortDirection } = this.state
    const { branchPage, branchPageSize, branchSortField, branchSortDirection } = this.state
    const { searchKeyword } = this.state
    const { branchFilter } = this.state
    const variables = {
      commitPage,
      commitPageSize,
      commitSortField,
      commitSortDirection,
      branchPage,
      branchPageSize,
      branchSortField,
      branchSortDirection,
    }
    if(searchKeyword) {
      switch(this.state.activeTab) {
        case 1:
          variables.commitSearch = {
            "message": [{"operator": "like", "value": `%${searchKeyword}%`}],
            "hash": [{"operator": "like", "value": `${searchKeyword}%`}]
          }
          break
        case 2:
          variables.branchSearch = {
            "message": [{"operator": "like", "value": `%${searchKeyword}%`}],
            "hash": [{"operator": "like", "value": `${searchKeyword}%`}]
          }
          break
      }
    }
    if(branchFilter == "closed"){
      switch(this.state.activeTab) {
        case 2:
          variables.branchClosed = {
            "closed": [{"operator": "eq", "value": "true"}]
          }
          break
      }
    }
    else if(branchFilter == "open"){
      switch(this.state.activeTab) {
        case 2:
          variables.branchClosed = {
            "closed": [{"operator": "isEmpty"}]
          }
          break
      }
    }
    else{
      switch(this.state.activeTab) {
        case 2:
          variables.branchClosed = {}
          break
      }
    }
    return variables
  }

  render() {
    const {activeTab} = this.state
    return (
      <Query query={queries.schemaList} fetchPolicy="cache-and-network" variables={this.getListVariables()}>
        {({loading, error, data}) => {
          if (loading) {
            return (<div><Header/></div>)
          } else if (error) {
            return (<div><Header/>{error}</div>)
          }
          data.getBranchPage = data.getBranchPage||{items:[]}
          return (
            <div>
              <Header />
              <Tabs value={activeTab}>
                  <Tab value={1} label="Schemas" onClick={_ => this.toggleTab(1)}/>
                  <Tab value={2} label="Branches" onClick={_ => this.toggleTab(2)}/>
              </Tabs>
              <div>
                <div style={{padding: '12', display: 'flex'}}>
                  <TextField label="Search message/hash" onChange={this.handleSearchInput} inputProps={{onKeyPress: this.handleSearch}} value={this.state.searchKeywordInput} style={{flex: 1}}/>
                  {this.getFilterBranch(activeTab)}
                </div>
                {this.getTabContent(activeTab, data)}
              </div>
            </div>
          )
        }}
      </Query>
    );
  }
}

class Header extends Component {
  render() {
    return <div style={{background: "rgb(213, 62, 65)", padding: 10}}>
      <div style={{color: "white", fontWeight: "bold"}}>Backend As a Service</div>
    </div>;
  }
}

class RootComponent extends Component {
  constructor(props) {
    super(props)
  }

  async componentDidMount() {
  }

  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route exact path='/' component={SchemaListPage}/>
          <Route path='/schema/:hash/edit' component={SchemaEditorPage}/>
        </Switch>
      </Router>
    );
  }
}

let profile = {}
axios.get('/profile').then(res => {
  profile = res.data
  ReactDOM.render(
    <ApolloProvider client={apolloClient}>
      <ApolloHooksProvider client={apolloClient}>
        <RootComponent />
      </ApolloHooksProvider>
    </ApolloProvider>,
  window.root);
})
