import React, { useEffect, useState } from 'react'
import gql from "graphql-tag"
import SchemaCodeEditor from './SchemaCodeEditor'
import BranchInfo from './BranchInfo'
import SchemaInfo from './SchemaInfo'
import SchemaExplorer from './SchemaExplorer'
import MigrationModal from './MigrationModal'
import ProgressModal from './ProgressModal'
import CommitModal from './CommitModal'
import { format } from '../../formatter'
import { diff } from 'deep-object-diff'
import { useMutation, useQuery, useApolloClient } from 'react-apollo-hooks';
import { mutations } from '../../graphql'
import Snackbar from '@material-ui/core/Snackbar';
import * as R from 'ramda'
import sha1 from 'sha1'

import { BranchContext, BaseSchemaContext, ViewedSchemaContext } from './Contexts';

let locationHashObject = () => {
  var hash = window.location.hash.substring(1);
  var params = {};
  hash.split('&').map(hk => { 
    let temp = hk.split('='); 
      params[temp[0]] = temp[1] 
  });

  return params;
}

function useClientWidth() {

  function handleResize() {
    setClientWidth(getClientWidth());
  }

  function getClientWidth() {
    return document.body.clientWidth; 
  }

  let [clientWidth, setClientWidth] = useState(getClientWidth);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return clientWidth;
}

let getCommitPageQuery = gql`
query getCommitPage {
  getCommitPage(page: $page, sortField: $sortField, sortDirection: $sortDirection, pageSize: $pageSize) {
    items {
      hash
      committer
    }
  }
}`;

export default function SchemaEditor(props) {
  let { profile, schemaManager } = props;

  let [branch, setBranch] = useState(props.branch);
  let [schema, setSchema] = useState(null);
  let [baseSchema, setBaseSchema] = useState(null);
  let [diffs, setDiffs] = useState([]);

  let [viewedObjectName, setViewedObjectName] = useState(null);
  let [tabObjectNames, setTabObjectNames] = useState([]);
  let [objectBufferMap, setObjectBufferMap] = useState({});
  let [objectSavingStateMap, setObjectSavingStateMap] = useState({});
  
  let [branchMigrations, setBranchMigrations] = useState([]);
  let [commitMigrations, setCommitMigrations] = useState([]);
  let [openCommitModal, setOpenCommitModal] = useState(false);
  let [progress, setProgress] = useState({
    title: "Please Wait",
    message: "",
    open: false,
    closeable: false,
    showProgress: true,
    onClose: _ => setProgress({open: false})
  })
  let [branchMigrationCompleted, setBranchMigrationCompleted] = useState(false)
  let [commitResult, setCommitResult] = useState({message: null, variant: 'info'});

  let viewportWidth = useClientWidth();
  let apolloClient = useApolloClient();
  let rebaseMutation = useMutation(mutations.rebaseBranch);
  let commitMutation = useMutation(mutations.commitBranch);
  let insertBranchHistoryMutation = useMutation(mutations.insertBranchHistory);

  async function initialLoad() {
    try {
      let initialObjectNameToView = locationHashObject().objectName;
      let baseSchema = await schemaManager.resolveSchemaByHash(branch.origin.hash)
      let branchSchema = {objects:{}};
      if (branch.histories.length > 0) {
        branchSchema = await schemaManager.resolveSchemaByHash(branch.histories[0].hash)
        const diffs = diffSchema(baseSchema, branchSchema)
        const schema = {objects: Object.assign({}, baseSchema.objects, branchSchema.objects)}
        
        setBaseSchema(baseSchema);
        setSchema(schema);
        setDiffs(diffs);

        if (schema.objects.hasOwnProperty(initialObjectNameToView)) {
          setTabObjectNames([initialObjectNameToView]);
          setViewedObjectName(initialObjectNameToView);
        }
      
      } else {
        setBaseSchema(baseSchema);
        setSchema(Object.assign({objects:{}}, {objects: baseSchema.objects}));
      }

    } catch(e) {
      console.log(e)
      alert(e)
    }
  }
  // Run only once at the start
  useEffect(() => {
    initialLoad();
  }, []);

  function diffSchema(oldSchema, currSchema) {
    const diffSchema = diff(oldSchema, currSchema)
    if(!diffSchema.objects) {
      return
    }
    return R.keys(diffSchema.objects).map(objectName => {
      const prev = oldSchema.objects[objectName] ? format(oldSchema.objects[objectName]) : '';
      const curr = format(currSchema.objects[objectName])
      return {objectName, prev, curr}
    })
  }

  async function rebaseBranch(latestCommit) {
    branch.originCommitHash = latestCommit.hash
    try {
      setProgress({
        open: true,
        title: "Please wait",
        message: `Rebasing branch to latest commit (#${latestCommit.hash.slice(0,6)})...`,
        showProgress: true,
        closeable: false,
        onClose: _ => setProgress({open: false})
      })
      const baseSchema = await schemaManager.resolveSchemaByHash(latestCommit.hash)
      const branchSchema = await schemaManager.resolveSchemaByHash(branch.histories[0].hash)
      const rebaseSchema = R.mergeDeepRight(baseSchema, branchSchema)
      const rebaseHash = sha1(JSON.stringify(rebaseSchema))
      const rebaseHistory = {
        name: branch.name,
        branchHash: branch.hash,
        hash: rebaseHash,
        author: profile.userName,
        message: `Re-base branch with commit ${latestCommit.hash}`,
        createdAt: new Date(),
      };
      await schemaManager.store(rebaseHash, branch.author, rebaseSchema)
      await rebaseMutation({
        variables: {
          input: Object.assign({}, branch, { originCommitHash: latestCommit.hash, histories: undefined, origin: undefined, __typename: undefined }),
          history: rebaseHistory
        }
      })
      setBaseSchema(baseSchema);
      setDiffs(diffSchema(baseSchema, rebaseSchema));
      setBranch(Object.assign(branch, {originCommitHash: latestCommit.hash, origin: { hash: latestCommit.hash }}));
      setProgress({
        open: true,
        title: "Rebase Success",
        message: `Branch was successfully rebased to latest commit (#${latestCommit.hash.slice(0,6)}). Please verify your changes before commiting the branch.`,
        closeable: true,
        showProgress: false,
        onClose: _ => setProgress({open: false})
      })
    } catch(e) {
      console.log(e)
      setProgress({
        open: true,
        title: "Rebase Failed",
        message: `Rebase attempt with latest commit (#${latestCommit.hash.slice(0,6)}) has failed.`,
        showProgress: false,
        closeable: true,
        onClose: _ => setProgress({open: false})
      })
    }
  }

  function onObjectSelected(object) {
    const newtabObjectNames = tabObjectNames.indexOf(object) > -1 ?
      tabObjectNames : tabObjectNames.concat(object);

    setTabObjectNames(newtabObjectNames);
    setViewedObjectName(object);

    location.hash = '#objectName=' + object;
  }

  function onObjectCreated(newObject) {
    setObjectBufferMap(Object.assign({}, objectBufferMap, {[newObject.name]: format(newObject)}));
    
    let newSchema = JSON.parse(JSON.stringify(schema));
    newSchema.objects[newObject.name] = newObject;
    setSchema(newSchema);

    // Select the object right after creation.
    onObjectSelected(newObject.name);
  }

  function onObjectEdited(objectName, newObject) {
    setObjectBufferMap(Object.assign({}, objectBufferMap, {[objectName]: format(newObject)}));
  }

  async function onObjectSaved(objectName, newSchema) {
    /*
    On Save:
    1) Validate
      1a) Make sure parsing succeeds
      1b) Builds object schema
      1c) Make sure external references are valid
      1d) Diffs with previous object schema, and make sure diffs are valid
    2) Save
    */
    setObjectSavingStateMap(Object.assign({}, objectSavingStateMap, {[objectName]: true}));
    try {
      const now = new Date();
      const history = {
        name: branch.name,
        branchHash: branch.hash,
        hash: sha1(JSON.stringify(newSchema)),
        author: profile.userName,
        message: `Updating ${objectName}`,
        createdAt: Date.parse(now.toUTCString()),
      };

      await insertBranchHistoryMutation({variables: {input: history}});

      // const validationErrors = validateNewObjectSchema(oldObjectSchema, newObjectSchema);
      await schemaManager.store(history.hash, branch.author, newSchema);
      const updatedBranch = Object.assign({}, branch);
      updatedBranch.histories.unshift(history);
      const diffs = diffSchema(baseSchema, newSchema);

      setObjectSavingStateMap(R.omit([objectName], objectSavingStateMap));
      setObjectBufferMap(R.omit([objectName], objectBufferMap));
      setSchema(newSchema);
      setDiffs(diffs);
      setBranch(updatedBranch);
      setBranchMigrationCompleted(false);
    } catch(err) {
      alert(`Failed to parse schema: ${err}`);
    } finally {
      setObjectSavingStateMap(R.omit([objectName], objectSavingStateMap));
    }
  }

  function onObjectClosed(objectName) {

    const confirmMessage = "You have edited this object and haven't saved. Are you sure you want to wipe out your change? ";
    if (objectBufferMap[objectName] && !confirm(confirmMessage)) {
      return;
    }

    let filteredTabObjectNames = tabObjectNames.filter(oName => oName != objectName);

    // Handle logic of which tab should be viewed next.
    let nextViewedObjectName;
    if (filteredTabObjectNames.length === 0) {
      nextViewedObjectName = null;
    } else if (objectName === viewedObjectName) {
      const closingObjectIndex = tabObjectNames.indexOf(objectName);
      nextViewedObjectName = filteredTabObjectNames[closingObjectIndex > 0 ? closingObjectIndex - 1 : 0];
    } else {
      nextViewedObjectName = viewedObjectName;
    }

    setObjectBufferMap(R.omit([objectName], objectBufferMap));
    setViewedObjectName(nextViewedObjectName);
    setTabObjectNames(filteredTabObjectNames);

    if (nextViewedObjectName) {
      location.hash = '#objectName=' + nextViewedObjectName;
    }
  }

  function onCommitCancelled() {
    setOpenCommitModal(false);
  }

  async function onMigrateSchema() {
    setProgress({
      title: "Please Wait",
      message: "Checking for migration statements (STAGING)...",
      closeable: false,
      showProgress: true,
      open: true,
    })
    const statements = await schemaManager.resolveBranchMigrations(branch.hash)
    setProgress({message: "", open: false})
    if (statements.length === 0) {
      setProgress({
        message: "Schema already sync with database",
        open: true,
        closeable: true,
        showProgress: false,
        onClose: _ => setProgress({open: false})
      })
      setBranchMigrationCompleted(true)
      return
    }
    setBranchMigrations(statements);
  }

  async function onBranchMigrationConfirmed() {
    try {
      setProgress({
        title: "Please wait",
        open: true,
        message: "Executing migration statements to database STAGING",
        showProgress: true,
        closeable: false,
      })
      await schemaManager.doBranchMigrations(branch.hash)
      setProgress({
        title: "Migration Success (STAGING)",
        open: true,
        message: "Migration finish successfully to database STAGING",
        closeable: true,
        onClose: _ => setProgress({ open: false })
      })
      setBranchMigrationCompleted(true)
    } catch(e) {
      setProgress({
        title: "Migration Failed (STAGING)",
        open: true,
        message: "Failed to execute migration statements on database STAGING",
        closeable: true,
        onClose: _ => setProgress({open: false })
      })
    } finally {
      setBranchMigrations([])
    }
  }

  function onBranchMigrationCancelled() {
    setBranchMigrations([]);
  }

  async function onCommitMigrationConfirmed() {
    try {
      await schemaManager.doCommitMigrations(branch.hash)
      setCommitMigrations([]);
    } catch(e) {
      alert(e)
    }
  }

  function onCommitMigrationCancelled() {
    setCommitMigrations([]);
  }

  function onCommitSchema() {
    setOpenCommitModal(true);
  }

  async function onCommitConfirmed() {
    try {
      let { data } = await apolloClient.query({
        query: getCommitPageQuery, 
        variables: {
          "page": 1,
          "pageSize": 1,
          "sortField": "commitDate",
          "sortDirection": "DESC"
        }
      });
      let commits = data.getCommitPage.items;
      let latestCommit = commits[0];

      setOpenCommitModal(false);

      // Force rebase
      if (latestCommit.hash !== branch.origin.hash) {
        rebaseBranch(latestCommit);
        return
      }
      setProgress({
        open:true,
        title: "Please wait",
        message: "Checking for migration statements (PRODUCTION)...",
        showProgress: true,
        closeable: false
      })
      // Force migration
      const statements = await schemaManager.resolveCommitMigrations(branch.hash)
      setProgress({open: false})

      if (statements.length > 0) {
        setCommitMigrations(statements);
        return;
      }
      setProgress({
        open:true,
        title: "Please wait",
        message: "Commiting branch...",
        showProgress: true,
        closeable: false
      })

      await commitMutation({variables: {
        input: Object.assign({}, branch, { originCommitHash: latestCommit.hash, merged: true, histories: undefined, origin: undefined, __typename: undefined }),
        commit: {
          hash: branch.hash,
          commitDate: new Date(),
          message: branch.message,
          committer: profile.userName,
          active: false
        }
      }});

      await schemaManager.store(branch.hash, branch.author, schema);
      setProgress({open: false})

      setCommitResult({
        message: "Committed successfully",
        variant: "success"
      });
      setBranch(Object.assign(branch, {merged: true}));
    } catch(err) {
      setProgress({open: false})
      setCommitResult({
        message: `Failed to commit this branch with err: ${err.toString()}`,
        variant: "error"
      });
    }
  }

  function clearCommitResultMessage() {
    setCommitResult({
      message: null, 
      variant: "info"
    });
  }

  const explorerRatio = 0.2;
  const editorRatio = 0.5;
  const rightColumnRatio = 0.3;

  const explorerWidth = explorerRatio * viewportWidth;
  const editorWidth = editorRatio * viewportWidth;
  const rightColumnWidth = rightColumnRatio * viewportWidth;

  if (!schema) {
    return <div>Loading schema...</div>;
  }

  return (
    <BranchContext.Provider value={branch}>
      <BaseSchemaContext.Provider value={baseSchema}>
        <ViewedSchemaContext.Provider value={schema}>
          <div style={{display: 'flex', height: '100%'}}>
            <div style={{display: 'flex', flexDirection: 'column', width: explorerWidth}}>
              <SchemaInfo branch={branch}/>
              <SchemaExplorer readonly={branch.closed||branch.merged} schema={schema} onObjectSelected={onObjectSelected} onObjectCreated={onObjectCreated}/>
            </div>
            <div style={{flex: 8, position: 'relative'}}>
              <div style={{display: 'flex', flexDirection: 'column', borderLeft: "1px solid #ccc", height: '100%'}}>
                <SchemaCodeEditor
                  width={editorWidth}
                  schema={schema}
                  branch={branch}
                  profile={profile}
                  tabObjectNames={tabObjectNames}
                  viewedObjectName={viewedObjectName}
                  objectBufferMap={objectBufferMap}
                  objectSavingStateMap={objectSavingStateMap}
                  schemaManager={schemaManager}
                  onObjectCreated={onObjectCreated}
                  onObjectSelected={onObjectSelected}
                  onObjectClosed={onObjectClosed}
                  onObjectEdited={onObjectEdited}
                  onObjectSaved={onObjectSaved}
                />
              </div>
            </div>
            <div style={{width: rightColumnWidth}}>
              <BranchInfo
                readonly={branch.closed||branch.merged}
                branchMigrationCompleted={branchMigrationCompleted}
                onMigrate={onMigrateSchema}
                onCommit={onCommitSchema}
                diffs={diffs}
                onRebase={null}
                branch={branch}
              />
              <ProgressModal {...progress}/>
              <MigrationModal statements={branchMigrations} onConfirmed={onBranchMigrationConfirmed} onCancelled={onBranchMigrationCancelled} env={'STAGING'}/>
              <MigrationModal statements={commitMigrations} onConfirmed={onCommitMigrationConfirmed} onCancelled={onCommitMigrationCancelled} env={'PRODUCTION'}/>
              <CommitModal open={openCommitModal} onConfirmed={onCommitConfirmed} onCancelled={onCommitCancelled} />
              <Snackbar
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={commitResult.message !== null}
                onClose={clearCommitResultMessage}
                autoHideDuration={3000}
                variant={commitResult.variant || 'info'}
                message={commitResult.message}
              />
            </div>
          </div>
        </ViewedSchemaContext.Provider>
      </BaseSchemaContext.Provider>
    </BranchContext.Provider>
  );
}
