// Generated from ./dsl/grammar/entity.g4 by ANTLR 4.7.1
// jshint ignore: start
var antlr4 = require('antlr4/index');
var R = require('ramda');
var { entityVisitor } = require('./entityVisitor');
var {
  EntitySymbol,
  LiteralSymbol,
  EnumerationSymbol,
  AccessControlSymbol,
  StoredPropertySymbol,
  ComputedPropertySymbol,
  LambdaSymbol,
  QuerySymbol,
  VariableSymbol,
  NullSymbol,
  BinaryOpSymbol,
  BINARY_OP_TYPES
} = require('../symbol/Type');

const QUERY_UNARY_OPERATOR = new Set([
  'isEmpty',
  'notEmpty'
])

function validateQualId(symbolTable, qualId) {
    const paths = qualId.getText().split(".")
    const resolves = R.map(path => symbolTable.resolve(path), paths)
    const id = paths.pop()
    const resolvedId = resolves.pop()
    const unresolvedParentIdx = R.findIndex(R.empty, resolves)
    if (unresolvedParentIdx > -1) {
        return new Error(`value ${paths[unresolvedParentIdx]} was undefined`)
    }
    return null
}

function isLiteralValue(text) {
  return text.match(/^".*"/)
    || text.match(/^(?:-)[0-9]+(?:\.[0-9]+)/)
    || text.match(/^true$/)
    || text.match(/^false$/)
    || text.match(/"""[\s\S]*"""/mi)
}

// This class defines a complete generic visitor for a parse tree produced by entityParser.

function entityResult(schema, symbolTable, valueTable, errorListener) {
  antlr4.tree.ParseTreeVisitor.call(this);
  this.schema = schema
  this.symbolTable = symbolTable
  this.valueTable = valueTable
  this.errorListener = errorListener

  return this;
}

entityResult.prototype = Object.create(entityVisitor.prototype);
entityResult.prototype.constructor = entityResult;

// Visit a parse tree produced by entityParser#entityDef.
entityResult.prototype.visitMain = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#entityDef.
entityResult.prototype.visitEntityDef = function(ctx) {
  const entityName = ctx.Typeid().getText()
  const entityDef = this.visit(ctx.entityBlock())
  const symbol = new EntitySymbol(entityName, entityDef.accessControl, entityDef.properties, entityDef.computedProperties, entityDef.indexes)
  this.symbolTable.append(symbol)
  return symbol
};

// Visit a parse tree produced by entityParser#enumerationDef.
entityResult.prototype.visitEntityBlock = function(ctx) {
  const createDefaultAcl = name => ({
    name,
    authorization: true,
    success: null,
    failure: null,
    lambdas: []
  })
  const dfltAcls = R.map(createDefaultAcl, ['read', 'list', 'create', 'update', 'delete'])
  return {
    accessControl: dfltAcls.concat(ctx.accessControlDef() ? this.visit(ctx.accessControlDef()) : []),
    properties: this.visit(ctx.propsDef()),
    computedProperties: ctx.computedDef() ? this.visit(ctx.computedDef()) : [],
    indexes: ctx.indexDef() ? this.visit(ctx.indexDef()) : null
  }
}

// Visit a parse tree produced by entityParser#enumerationDef.
entityResult.prototype.visitEnumerationDef = function(ctx) {
  const enumName = ctx.Typeid().getText()
  const enumValues = R.map(enumValue => {
    let value
    if (enumValue.Typeid()) {
      value = enumValue.Typeid().getText()
    } else if(enumValue.Varid()) {
      value = enumValue.Varid().getText()
    }
    let description = null
    if (enumValue.StringLiteral()) {
      description = enumValue.StringLiteral().getText().replace(/^"/,'').replace(/"$/, '')
    }
    return { value, description }
  })(ctx.enumerationValue())
  const symbol = new EnumerationSymbol(enumName, enumValues)
  this.symbolTable.append(symbol)
  return symbol
}

// Visit a parse tree produced by entityParser#accessControlDef.
entityResult.prototype.visitAccessControlDef = function(ctx) {
  return this.visit(ctx.accessControl());
};


// Visit a parse tree produced by entityParser#accessControl.
entityResult.prototype.visitAccessControl = function(ctx) {
  const dfltAcl = {
    name: ctx.accessControlType().getText(),
    authorization: null,
    success: null,
    failure: null,
    lambdas: []
  }
  const name  = this.visit(ctx.accessControlType())
  const accessControlValue = ctx.accessControlValue()
  const rawValue = accessControlValue.getText()
  const acl = Object.assign(dfltAcl, this.visit(ctx.accessControlValue()))

  return new AccessControlSymbol(acl.name, acl.success, acl.failure, acl.authorization, acl.lambdas)
};

// Visit a parse tree produced by entityParser#accessControlTypes.
entityResult.prototype.visitAccessControlTypes = function(ctx) {
  return ctx.getText()
};

// Visit a parse tree produced by entityParser#accessControlBlock.
entityResult.prototype.visitAccessControlValue = function(ctx) {
  const employeeLambda = {
    lambda: 'includes',
    errorMessage: "Authorized SaleStock Employee Only",
    inputs: [
      { source: 'ViewerContext', value: 'timasClientID' },
      { source: null, value: 'laskar-app-' }
    ]
  }
  const rawValue = ctx.getText()
  if(rawValue === 'always') {
    return { authorization: true }
  } else if(rawValue === 'forbidden') {
    return { failure: "Forbidden", authorization: false }
  } else if(rawValue === 'employee') {
    return { lambdas: [employeeLambda] }
  } else {
    const configurations = this.visit(ctx.accessControlBlock())
    const result = R.reduce((res, obj) => {
      if (obj.lambdas) {
        res.lambdas = res.lambdas.concat(obj.lambdas)
        return res
      } else {
        return R.merge(res, obj)
      }
    }, {lambdas: []})(configurations)
    return result
  }
}

// Visit a parse tree produced by entityParser#accessControlBlock.
entityResult.prototype.visitAccessControlBlock = function(ctx) {
  if (ctx.accessControlAuthorization()) {
    return this.visit(ctx.accessControlAuthorization())
  } else if (ctx.accessControlSuccess()) {
    return this.visit(ctx.accessControlSuccess())
  } else if (ctx.accessControlFailure()) {
    return this.visit(ctx.accessControlFailure())
  } else if (ctx.accessControlLambda()) {
    return this.visit(ctx.accessControlLambda())
  }
}


// Visit a parse tree produced by entityParser#accessControlBlock.
entityResult.prototype.visitAccessControlLambda = function(ctx) {
  const lambda = this.visit(ctx.lambdaExpr())
  const errorMessage = ctx.StringLiteral().getText().replace(/^"/, '').replace(/"$/, '')
  return { lambdas: [{ name: lambda.name, errorMessage, inputs: lambda.args }] }
};


// Visit a parse tree produced by entityParser#accessControlSuccess.
entityResult.prototype.visitAccessControlSuccess = function(ctx) {
  if(ctx.StringLiteral().getText()) {
    return { success: ctx.StringLiteral().getText() }
  } else {
    return { success: null }
  }
};


// Visit a parse tree produced by entityParser#accessControlFailure.
entityResult.prototype.visitAccessControlFailure = function(ctx) {
  return { failure: ctx.StringLiteral().getText() }
};


// Visit a parse tree produced by entityParser#accessControlAuthorization.
entityResult.prototype.visitAccessControlAuthorization = function(ctx) {
  return this.visit(ctx.accessControlAuthorizationValue())
};


// Visit a parse tree produced by entityParser#accessControlAuthorizationValue.
entityResult.prototype.visitAccessControlAuthorizationValue = function(ctx) {
  if (ctx.BooleanLiteral()) {
    return { authorization: ctx.BooleanLiteral().getText() === 'true' , lambdas: []}
  } else if(ctx.lambdaExpr()) {
    const lambdas = this.visit(ctx.lambdaExpr())
    return { lambdas }
  }
};


// Visit a parse tree produced by entityParser#propsDef.
entityResult.prototype.visitPropsDef = function(ctx) {
  return this.visit(ctx.storedProp());
};


// Visit a parse tree produced by entityParser#storedProp.
entityResult.prototype.visitStoredProp = function(ctx) {
  const varId = ctx.Varid()
  const typeId = ctx.Typeid()
  const identityPattern = new RegExp('^@')
  const metas = ctx.metaPropertyDef() ? this.visit(ctx.metaPropertyDef()) : {}
  const metadata = R.reduce(R.merge)({}, metas)
  if(!typeId) {
    err = new Error(`Missing type`)
    this.errorListener.typeError(err, ctx)
    return null
  }
  return new StoredPropertySymbol(varId.getText(), typeId.getText(), identityPattern.test(ctx.getText()), metadata.searchable, metadata.searchableKeyword, metadata.unique, metadata.validator)
};


// Visit a parse tree produced by entityParser#metaPropertyDef.
entityResult.prototype.visitMetaPropertyDef = function(ctx) {
  const raw = ctx.getText()
  if(raw === 'searchable') {
    return { searchable: true }
  } else if(raw === 'searchableKeyword') {
    return { searchableKeyword: true }
  } else if(raw === 'unique') {
    return { unique: true }
  } else if(ctx.metaPropertyValidationDef()) {
    const validationSymbol = this.visit(ctx.metaPropertyValidationDef())
    const validation = R.reduce((config, meta) => {
      config[meta.name] = meta.value
      return config
    })({}, validationSymbol.value)
    const result = {}
    result[validationSymbol.name] = validation
    return {validator: result}
  } else {
    return new Error(`Unknown property metadata \`${ctx.getText()}\``)
  }
};


// Visit a parse tree produced by entityParser#metaPropertyValidationDef.
entityResult.prototype.visitMetaPropertyValidationDef = function(ctx) {
  return { name: ctx.Varid().getText(), value: this.visit(ctx.metaPropertyValidation()) }
}

// Visit a parse tree produced by entityParser#metaPropertyValidation.
entityResult.prototype.visitMetaPropertyValidation = function(ctx) {
  if(ctx.metaPropertyValidationRegexp()) {
    return { name: "regexp", value: ctx.metaPropertyValidationRegexp().StringLiteral().getText() }
  } else if (ctx.metaPropertyValidationFailure()) {
    return { name: "failure", value: ctx.metaPropertyValidationFailure().StringLiteral().getText()}
  } else {
    throw new Error(`Unknown property validation \`${ctx.getText()}\``)
  }
};


// Visit a parse tree produced by entityParser#computedDef.
entityResult.prototype.visitComputedDef = function(ctx) {
  return this.visit(ctx.computedProp());
};


// Visit a parse tree produced by entityParser#computedProp.
entityResult.prototype.visitComputedProp = function(ctx) {
  const varId = ctx.Varid()
  const propType = ctx.computedPropType()
  const entity = ctx.parentCtx.parentCtx.parentCtx.Typeid().getText()
  let isCollection = false
  let isNullable = false
  let type
  if(!propType) {
    return null
  } else  if(propType.NullableTypeid()) {
    isNullable = true
    type = propType.NullableTypeid().getText().replace(/[?]$/, '')
  } else if(propType.Typeid()) {
    type = propType.Typeid().getText()
  } else if(propType.CollectionTypeid()) {
    type = propType.CollectionTypeid().getText().replace(/\[\]/, '')
    isCollection = true
  }
  if(!this.symbolTable.exists(type) && !this.schema.objects[type]) {
    const err = new Error(`Invalid computed property type: \`${type}\``)
    this.errorListener.typeError(err, ctx)
  }
  let metaProperties = []
  if (propType.metaComputedProp()) {
    metaProperties = this.visit(propType.metaComputedProp())
  }
  return new ComputedPropertySymbol(varId.getText(), type, isCollection, isNullable, R.filter(_ => _.__type === 'FILTER')(metaProperties), R.filter(_ => _.__type === 'ORDER')(metaProperties))
};


// Visit a parse tree produced by entityParser#metaComputedProp.
entityResult.prototype.visitMetaComputedProp = function(ctx) {
  if(ctx.queryWhereBlock()) {
    const filters = this.visit(ctx.queryWhereBlock()) || []
    const data = R.reduce((aggr, filter) => aggr.concat(...filter.data), [], filters)
    return { __type: 'FILTER', data }
  } else if(ctx.queryOrderBlock()) {
    return { __type: 'ORDER', data: this.visit(ctx.queryOrderBlock()) }
  }
}


// Visit a parse tree produced by entityParser#computedPropSingle.
entityResult.prototype.visitComputedPropSingle = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#computedPropCollection.
entityResult.prototype.visitComputedPropCollection = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#computedPropRel.
entityResult.prototype.visitComputedPropRel = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#queriesDef.
entityResult.prototype.visitQueriesDef = function(ctx) {
  return this.visitChildren(ctx)
};


// Visit a parse tree produced by entityParser#queriesDef.
entityResult.prototype.visitIndexDef = function(ctx) {
  return this.visit(ctx.indexBlock()).reduce((indexes, block) => {
    indexes[block.name] = block.fields;
    return indexes;
  }, {})
}


// Visit a parse tree produced by entityParser#computedPropRel.
entityResult.prototype.visitIndexBlock = function(ctx) {
  const name = ctx.Varid().getText();
  const fields = ctx.indexField().map(def => {
    return {property: def.Varid().getText(), sort: def.QuerySort() ? def.QuerySort().getText() : 'ASC' }
  })
  return { name, fields };
};


// Visit a parse tree produced by entityParser#queryBlock.
entityResult.prototype.visitQueryBlock = function(ctx) {
  const acl = ctx.queryAccessControl().accessControlType().getText()
  const queryName = ctx.Varid().getText()
  const queryArgs = R.map(binding => {
    const argName = binding.Varid().getText()
    let isArgCollection = false
    let argType
    if(binding.Typeid()) {
      argType = binding.Typeid().getText()
    } else {
      isArgCollection = true
      argType = binding.CollectionTypeid().getText().replace(/^\[\]/, '')
    }
    return new VariableSymbol(argName,  argType, isArgCollection)
  })(ctx.binding())
  let isQueryTypeCollection = false
  let queryType
  let isNullable = false
  if (ctx.Typeid()) {
    queryType = ctx.Typeid().getText()
  } else if(ctx.NullableTypeid()) {
    queryType = ctx.NullableTypeid().Typeid()
    isNullable = true
  } else {
    if(ctx.CollectionTypeid()){
      queryType = ctx.CollectionTypeid().getText().replace(/^\[\]/, '')
      isQueryTypeCollection = true
    }
  }
  // queryOperation
  let queryConditions = []
  let queryOrders = []
  let queryCache = null
  let rawSql = null
  const queryOperation = ctx.queryOperation()
  if (queryOperation && queryOperation.queryOperationRaw()) {
    rawSql = this.visit(queryOperation.queryOperationRaw().queryOperationRawBlock())
    if (queryOperation.queryOperationRaw().queryCacheBlock()) {
      queryCache = this.visit(queryOperation.queryOperationRaw().queryCacheBlock().queryCacheStrategy())
    }
  } else {
    if (queryOperation && queryOperation.queryOperationBlock() && queryOperation.queryOperationBlock().queryWhereBlock()) {
      queryConditions = this.visit(queryOperation.queryOperationBlock().queryWhereBlock())
    }
    if (queryOperation && queryOperation.queryOperationBlock() && queryOperation.queryOperationBlock().queryOrderBlock()) {
      queryOrders = this.visit(queryOperation.queryOperationBlock().queryOrderBlock())
    }
    if (queryOperation && queryOperation.queryOperationBlock() && queryOperation.queryOperationBlock().queryCacheBlock()) {
      queryCache = this.visit(queryOperation.queryOperationBlock().queryCacheBlock().queryCacheStrategy())
    }
  }
  const symbol = new QuerySymbol(acl, queryName, queryArgs, queryType, isQueryTypeCollection, queryConditions, queryOrders, rawSql, isNullable, queryCache)
  this.symbolTable.append(symbol)
  return symbol
};


// Visit a parse tree produced by entityParser#queryWhereBlock.
entityResult.prototype.visitQueryWhereBlock = function(ctx) {
    return ctx.queryWhereGroup() ? this.visit(ctx.queryWhereGroup()) : [];
};


// Visit a parse tree produced by entityParser#queryWhereGroup.
entityResult.prototype.visitQueryWhereGroup = function(ctx) {
  const wheres = ctx.queryWhere();
  return (wheres||[]).length ? this.visitChildren(ctx).filter(filter => !!filter) : [];
};


// Visit a parse tree produced by entityParser#queryWhereJunction.
entityResult.prototype.visitQueryWhereJunction = function(ctx) {
  const data = []
  const raw = ctx.getText()
  switch(raw) {
    case 'and':
      data.push({ name: 'AND', raw, __type: "CONJUCTION" })
      break;
    case 'or':
      data.push({ name: 'OR',  raw, __type: "CONJUCTION" })
      break;
    default:
      throw new Error(`Unknown query junction '${ctx.getText()}'`)
  }
  return {__type: "CONJUNCTION", data}
};


// Visit a parse tree produced by entityParser#queryWhere.
entityResult.prototype.visitQueryWhere = function(ctx) {
  if(ctx.getText().trim() === "") {
    return null
  }
  const field = this.visit(ctx.queryWhereField())
  const operator = ctx.queryOperator() ? this.visit(ctx.queryOperator()) : null
  if(!operator) {
    return null
  }
  const data = [field, operator]
  if (!QUERY_UNARY_OPERATOR.has(operator.name)) {
    const value = ctx.queryWhereValue() ? this.visit(ctx.queryWhereValue()) : null
    if(!value) {
      const expectValueErr = new Error(`Operator ${operator.name} expected a value`)
      this.errorListener.typeError(expectValueErr, ctx.queryWhereValue())
      return null
    }
    data.push(value)
  }
  return {
    __type: "FILTER",
    data
  }
};


// Visit a parse tree produced by entityParser#queryWhereField.
entityResult.prototype.visitQueryWhereField = function(ctx) {
  const raw = ctx.getText()
  return {
    __type: "OPERAND",
    name: raw.replace(/^self\./, ''),
    raw: ctx.getText(),
    isRelative: /^self\./.test(raw),
    isLiteral: false,
    isNull: false
  }
};


// Visit a parse tree produced by entityParser#queryWhereValue.
entityResult.prototype.visitQueryWhereValue = function(ctx) {
  if(!ctx.getText()) {
    return null
  }
  let value, valueType, isNull = false, isLiteral = false, isRelative = false
  if (ctx.Typeid()) {
    let valueTypeSymbol = this.symbolTable.resolve(ctx.Typeid().getText())
    if(!valueTypeSymbol && this.schema.objects[ctx.Typeid().getText()]){
      const err = new Error(`Unknown type \`${ctx.Typeid().getText()}\``);
      this.errorListener.typeError(err, ctx)
    }
    valueType = valueTypeSymbol.name
    value = ctx.qualId().getText()
  } else if(ctx.literal()) {
    let literal = this.visit(ctx.literal())
    valueType = literal.type
    value = literal.value
    isLiteral = true
  } else if(ctx.getText() === 'null') {
    valueType = new NullSymbol()
    value = null
    isNull = true
  } else if(ctx.Varid()) {
    value = ctx.Varid().getText()
  } else if(/^self\./.test(ctx.getText())) {
    isRelative = true
    value = ctx.getText().replace(/^self\./, '')
  } else {
    const invalidValueErr = new Error(`Invalid value '${ctx.getText()}'`)
    this.errorListener.typeError(invalidValueErr, ctx)
    return null
  }
  return { __type: "OPERAND", type: valueType, raw: value, name: value, isNull, isLiteral, isRelative }
};


// Visit a parse tree produced by entityParser#queryOperator.
entityResult.prototype.visitQueryOperator = function(ctx) {
  switch (ctx.getText()) {
    case "":
      const err = new Error("Expected an operator")
      this.errorListener.typeError(err, ctx)
      return null;
    case "<": return { __type: "OPERATOR", name: 'lessThan', raw: '<'};
    case '<=': return { __type: "OPERATOR", name: 'lessThanEqual', raw: '<='};
    case ">": return { __type: "OPERATOR", name: 'greaterThan', raw: '>' };
    case ">=": return { __type: "OPERATOR", name: 'greaterThanEqual', raw: '>=' };
    case "like": return { __type: "OPERATOR", name: 'equal', raw: 'like'}
    case "=": return {__type: "OPERATOR", name: 'equals', raw: 'eq'};
    case "notEmpty": return {__type: "OPERATOR", name: "notEmpty", raw: 'notEmpty'};
    case "isEmpty": return {__type:"OPERATOR", name: "isEmpty", raw: 'isEmpty'};
    default:
      const unknownOperatorErr = new Error(`Unsupported query where operator \`${ctx.getText()}\``)
      this.errorListener(unknownOperatorErr, ctx)
      return null
  }
};


// Visit a parse tree produced by entityParser#queryOperationRawBlock.
entityResult.prototype.visitQueryOperationRawBlock = function(ctx) {
  if(ctx.queryRawBlock())
    return this.visit(ctx.queryRawBlock())
}


// Visit a parse tree produced by entityParser#queryRawBlock.
entityResult.prototype.visitQueryRawBlock = function(ctx) {
  let params = {}
  let query = ""
  if(ctx.queryRawParamsBlock()) {
    const rawParams = this.visit(ctx.queryRawParamsBlock())
    params = R.reduce((params, param) => {
      params[param.name] = param
      return params
    }, {}, rawParams)
  }
  if(ctx.queryRawSqlBlock() && ctx.queryRawSqlBlock().MultiLineString() && ctx.queryRawSqlBlock().MultiLineString().getText()){
    const lines = ctx.queryRawSqlBlock().MultiLineString().getText().split("\n")
    query = R.map(line => line.replace(/"""/g, '').trim(), lines).join("\n")
  }
  return {
    params,
    query
  }
}


// Visit a parse tree produced by entityParser#queryRawParamsBlock.
entityResult.prototype.visitQueryRawParamsBlock = function(ctx) {
  return this.visit(ctx.queryRawParamBlock())
}


// Visit a parse tree produced by entityParser#queryRawParamBlock.
entityResult.prototype.visitQueryRawParamBlock = function(ctx) {
  const value = this.visit(ctx.queryWhereValue())
  const name = ctx.Varid().getText()
  return Object.assign({}, value, {name})
}


// Visit a parse tree produced by entityParser#queryOrderBlock.
entityResult.prototype.visitQueryOrderBlock = function(ctx) {
  return R.map(sortCtx => {
    const field = sortCtx.Varid()
    const sort = sortCtx.QuerySort()
    return { name: field.getText(), sort: sort.getText() ? sort.getText() : 'asc' }
  })(ctx.queryFieldSort())
};


// Visit a parse tree produced by entityParser#queryCacheStrategy
entityResult.prototype.visitQueryCacheStrategy = function(ctx) {
  let key = [{ name: 'id', isLiteral: false, isRelative: false, isNull: false }]
  let strategy = 'TTL'
  let options = {}
  if (ctx.queryCacheStrategyTTL()) {
    options.ttlSecond = 60 // in seconds
    const strategyCtx = ctx.queryCacheStrategyTTL();
    if (strategyCtx.queryCacheStrategyTTLOption()) {
      options.ttlSecond = parseInt(strategyCtx.queryCacheStrategyTTLOption().IntegerLiteral().getText(), 10)
    }
    if (strategyCtx.queryCacheKey()) {
      key = this.visit(strategyCtx.queryCacheKey().queryCacheKeyValue())
    }
  } else if (ctx.queryCacheStrategyNever()) {
    strategy = 'NEVER'
  }
  return { key, strategy, options }
};


// Visit a parse tree produced by entityParser#queryFieldSort.
entityResult.prototype.visitQueryCacheKeyValue = function(ctx) {
  if(ctx.getText().startsWith('self.')) {
    return { name: ctx.Varid().getText(), isRelative: true, isLiteral: false, isNull: false }
  } else {
    return { name: ctx.Varid().getText(), isRelative: false, isLiteral: false, isNull: false }
  }
}


// Visit a parse tree produced by entityParser#queryFieldSort.
entityResult.prototype.visitQueryFieldSort = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#literal.
entityResult.prototype.visitLiteral = function(ctx) {
  if(ctx.IntegerLiteral()) {
    let value = parseInt(ctx.IntegerLiteral().getText(), 10)
    if(isNaN(value)) {
      throw new Error(`Invalid integer value \`${ctx.IntegerLiteral().getText()}\``)
    }
    return { type: 'Int', value }
  } else if(ctx.StringLiteral()) {
    return { type: 'String', value: ctx.getText() }
  } else if(ctx.BooleanLiteral()) {
    let value = ctx.getText()
    if(value !== "true" && value !== "false") {
      throw new Error(`Invalid boolean value \`${ctx.getText()}\``)
    }
    return { type: 'Bool', value: value=== "true" }
  } else if(ctx.FloatingPointLiteral()) {
    let value = parseFloat(ctx.FloatingPointLiteral().getText(), 10)
    if(isNaN(val)) {
      throw new Error(`Invalid float value \`${ctx.FloatingPointLiteral().getText()}\``)
    }
    return { type: 'Float', value }
  } else if(ctx.MultiLineString()) {
    return { type: 'String', value: ctx.getText() }
  }
};


// Visit a parse tree produced by entityParser#scalarType.
entityResult.prototype.visitScalarType = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#varIds.
entityResult.prototype.visitVarIds = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#qualId.
entityResult.prototype.visitQualId = function(ctx) {
  const paths = ctx.getText().split(".")
  // check all path in qualId
  const id = paths.shift()
  if(paths.length > 0) {
    const id = paths.shift()
    const existing = this.symbolTable.resolve(id)
    if(!existing) {
        throw new Error(`Undefined variable \`${id}\` `)
    }
    return existing.resolve(paths)
  } else {
    return this.symbolTable.resolve(id)
  }
};


// Visit a parse tree produced by entityParser#qualIds.
entityResult.prototype.visitQualIds = function(ctx) {
  return this.visit(ctx.qualId());
};


// Visit a parse tree produced by entityParser#exprs.
entityResult.prototype.visitExprs = function(ctx) {
  return this.visit(ctx.expr());
};


// Visit a parse tree produced by entityParser#expr.
entityResult.prototype.visitExpr = function(ctx) {
  if(ctx.variableDeclExpr()) {
    this.symbolTable.append(this.visit(ctx.variableDeclExpr()))
  } else if(ctx.conditionalExpr()) {
    this.symbolTable.append(this.visit(ctx.conditionalExpr()))
  } else if(ctx.simpleExpr()) {
    this.symbolTable.append(this.visit(ctx.simpleExpr()))
  }
};


// Visit a parse tree produced by entityParser#lambdaExpr.
entityResult.prototype.visitLambdaExpr = function(ctx) {
  const lambdaName = ctx.Varid().getText()
  const lambdaArgs = this.visit(ctx.lambdaArgs())

  return { name: lambdaName, inputs: lambdaArgs }
};


// Visit a parse tree produced by entityParser#lambdaArgs.
entityResult.prototype.visitLambdaArgs = function(ctx) {
  return this.visit(ctx.lambdaArg())
};

entityResult.prototype.visitLambdaArg = function(ctx) {
  if(ctx.literal()) {
    return { source: null, value: this.visit(ctx.literal()).value }
  } else if(ctx.lambdaArgPrefix()) {
    return { source: ctx.lambdaArgPrefix().getText(), value: ctx.qualId().getText() }
  } else if(ctx.qualId()) {
    return { source: "SCOPE", value: ctx.qualId().getText() }
  }
}


// Visit a parse tree produced by entityParser#variableDeclExpr.
entityResult.prototype.visitVariableDeclExpr = function(ctx) {
  const typeId = ctx.Typeid()
  const collectionTypeId = ctx.CollectionTypeid()
  const varId = ctx.Varid()
  if (!typeId) {
    throw new Error('variable type must be defined')
  } else if(!this.symbolTable.exists(typeId.getText())) {
    throw new Error(`Unknown type \`${typeId.getText()}\``)
  }
  if(this.symbolTable.exists(varId)) {
    const existing = this.symbolTable.resolve(varId.getText())
    if(typeId && existing.type !== typeId.getText()) {
      throw new Error(`Cannot change variable type to \`${typeId.getText()}\`, previously defined as \`${existing.type}\``)
    } else if(collectionTypeId && existing.type !== collectionTypeId.getText()) {
      throw new Error(`Cannot change variable type to \`${collectionTypeId.getText()}\`, previously defined as \`${existing.type}\``)
    }
  } else {
    if(ctx.simpleExpr()) {
      this.valueTable[ctx.Varid().getText()] = this.visit(ctx.simpleExpr())
    }
    const symbol = new VariableSymbol(ctx.Varid().getText(), ctx.Typeid().getText());
    this.symbolTable.append(symbol)
  }
};


// Visit a parse tree produced by entityParser#conditionalExpr.
entityResult.prototype.visitConditionalExpr = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#ifExpr.
entityResult.prototype.visitIfExpr = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#matchExpr.
entityResult.prototype.visitMatchExpr = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#logicExpr.
entityResult.prototype.visitLogicExpr = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#arithmeticExprs.
entityResult.prototype.visitArithmeticExprs = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#arithmeticExpr.
entityResult.prototype.visitArithmeticExpr = function(ctx) {
  const terms = this.visit(ctx.arithmeticTerm())
  if(ctx.arithmeticExprOperator().length === 0) {
    return terms[0]
  }
  let i = 0
  return R.reduce((symbol, op) => {
    const root = this.visit(op)
    i = i + 2
    root.rgt = this.visit(ctx.getChild(i))
    root.lft = symbol
    return root
  })(terms[0], ctx.arithmeticExprOperator())
};


// Visit a parse tree produced by entityParser#arithmeticExprOperator.
entityResult.prototype.visitArithmeticExprOperator = function(ctx) {
  switch(ctx.getText()) {
    case '+': return new BinaryOpSymbol(BINARY_OP_TYPES.ADDITION, R.add);
    case '-': return new BinaryOpSymbol(BINARY_OP_TYPES.SUBTRACTION, R.subtract);
  }
};


// Visit a parse tree produced by entityParser#arithmeticTerm.
entityResult.prototype.visitArithmeticTerm = function(ctx) {
  const terms = this.visit(ctx.arithmeticFactor())
  if(ctx.arithmeticTermOperator().length === 0) {
    return terms[0]
  }
  let i = 0
  return R.reduce((symbol, op) => {
    i = i + 2
    const root = this.visit(op)
    root.lft = this.visit(ctx.getChild(i))
    root.rgt = symbol
    return root
  })(terms[0], ctx.arithmeticTermOperator())
};


// Visit a parse tree produced by entityParser#arithmeticTermOperator.
entityResult.prototype.visitArithmeticTermOperator = function(ctx) {
  switch(ctx.getText()) {
    case '*': return new BinaryOpSymbol(BINARY_OP_TYPES.MULTIPLICATION, R.mul);
    case '/': return new BinaryOpSymbol(BINARY_OP_TYPES.SUBTRACTION, R.div);
    case '%': return new BinaryOpSymbol(BINARY_OP_TYPES.MODULO, R.mod);
  }
};


// Visit a parse tree produced by entityParser#arithmeticOperand.
entityResult.prototype.visitArithmeticFactor = function(ctx) {
  const qualId = ctx.qualId()
  const int = ctx.IntegerLiteral()
  const float = ctx.FloatingPointLiteral()
  let node, value
  if (qualId) {
    validateQualId(this.symbolTable, qualId)
    const referredName = qualId.getText()
    return new ReferenceSymbol(referredName, this.symbolTable.resolve(referredName))
  } else if(int) {
    value = parseInt(int.getText(), 0)
    if (isNaN(value)) {
      throw new Error(`\`${value}\` is not a valid Int value `)
    }
    return new LiteralSymbol('Int', value)
  } else if(float) {
    value = parseFloat(float.getText(), 0)
    if (isNaN(value)) {
      throw new Error(`\`${value}\` is not a valid Float value `)
    }
    return new LiteralSymbol('Float', value)
  } else {
    return this.visit(ctx.arithmeticExpr())
  }
}


// Visit a parse tree produced by entityParser#arithmeticOp.
entityResult.prototype.visitArithmeticOp = function(ctx) {
  const op = this.visit(ctx.arithmeticOperator())
  const expr = this.visit(ctx.arithmeticExpr())
  const rawExpr = expr.getText()
  if(rawExpr.startsWith("(")) {
    if(!rawExpr.endsWith(")")) {
      throw new Error("Unclosed parenthesis")
    }
  }
  return { op, expr }
};


// Visit a parse tree produced by entityParser#bindings.
entityResult.prototype.visitBindings = function(ctx) {
  return this.visit(ctx.binding());
};


// Visit a parse tree produced by entityParser#binding.
entityResult.prototype.visitBinding = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#bindingSingle.
entityResult.prototype.visitBindingSingle = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#bindingMultiple.
entityResult.prototype.visitBindingMultiple = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#simpleExpr.
entityResult.prototype.visitSimpleExpr = function(ctx) {
  if(ctx.literal()) {
    return this.visit(ctx.literal())
  } else if(ctx.arithmeticExpr()) {
    return this.visit(ctx.arithmeticExpr())
  } else if(ctx.assignExpr()) {
    return this.visit(ctx.assignExpr())
  }
};


// Visit a parse tree produced by entityParser#assignExpr.
entityResult.prototype.visitAssignExpr = function(ctx) {
  const qualId = ctx.qualId()
  if(!qualId) {
    throw new Error("Invalid variable assingnment")
  }
  const id = ctx.qualId().getText()
  const ids = id.split(".")
  let symbol
  // Object symbol
  if(ids > 0) {
    const variable = ids.shift()
    // find top symbol
    if(!this.symbolTable.exists(variable)) {
      throw new Error(`Undefined variable \`${variable}\``)
    }
    const objectVariable = this.symbolTable.resolve(variable)
    if (objectVariable.type !== "Object") {
      throw new Error(`Variable ${variable} is not an object`)
    }
    symbol = objectVariable.resolve(...ids)
  } else if (!this.symbolTable.exists(qualId.getText())) {
    throw new Error(`Undefined variable \`${qualId.getText()}\``)
  }
  symbol = this.symbolTable.resolve(id)
  if (!symbol) {
    throw new Error(`Undefined variable \`${id}\``)
  }
  const value = this.visit(ctx.assignValueExpr())
  // TODO: validate value type against variable type
  this.valueTable[symbol.name] = value
  return symbol
};


// Visit a parse tree produced by entityParser#assignValueExpr.
entityResult.prototype.visitAssignValueExpr = function(ctx) {
  if(ctx.qualId()) {
    return this.visit(ctx.qualId())
  } else if(ctx.literal()) {
    return this.visit(ctx.literal())
  } else if(ctx.arithmeticExpr()) {
    return this.visit(ctx.arithmeticExpr())
  } else{
    const typeId = ctx.Typeid()
    if (!typeId) {
      throw Error(`Undefine type \`${typeId}\``)
    }
    const type = this.valueTable[typeId]
    const args = R.map(qualId => this.symbolTable.resolve(qualId), ctx.qualIds())
    return type.apply(null, args)
  }
};


// Visit a parse tree produced by entityParser#blockExpr.
entityResult.prototype.visitBlockExpr = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#block.
entityResult.prototype.visitBlock = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#caseClauses.
entityResult.prototype.visitCaseClauses = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#caseClause.
entityResult.prototype.visitCaseClause = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#patternExpr.
entityResult.prototype.visitPatternExpr = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#pattern.
entityResult.prototype.visitPattern = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by entityParser#arithmeticOperator.
entityResult.prototype.visitArithmeticOperator = function(ctx) {
  switch (ctx.getText()) {
    case "+": return new BinaryOpSymbol(BINARY_OP_TYPES.ADDITION);
    case "-": return new BinaryOpSymbol(BINARY_OP_TYPES.SUBTRACTION);
    case "/": return new BinaryOpSymbol(BINARY_OP_TYPES.DIVISION);
    case "*": return new BinaryOpSymbol(BINARY_OP_TYPES.MULTIPLICATION);
    case "%": return new BinaryOpSymbol(BINARY_OP_TYPES.MODULO);
  }
};


// Visit a parse tree produced by entityParser#logicOperator.
entityResult.prototype.visitLogicOperator = function(ctx) {
  return this.visitChildren(ctx);
};



exports.entityResult = entityResult;
