import { createContext, useContext } from "react";

export const BaseSchemaContext = createContext(null);
export const ViewedSchemaContext = createContext(null);
export const ViewedObjectNameContext = createContext(null);
export const EditMapContext = createContext(null);
export const BranchContext = createContext(null);

export const useViewedSchema = function () {
  return useContext(ViewedSchemaContext);
}

export const useObjectByName = function (objectName) {
  let schema = useViewedSchema();
  let editMap = useContext(EditMapContext);

  let object = editMap[objectName] ? editMap[objectName] : schema.objects[objectName];

  return [object, !!editMap[objectName]];
}

export const useViewedObject = function () {
  let viewedObjectName = useContext(ViewedObjectNameContext);

  return useObjectByName(viewedObjectName);
}

export const useBaseObject = function () {
  let baseSchema = useContext(BaseSchemaContext);
  let objectName = useContext(ViewedObjectNameContext);

  return baseSchema.objects[objectName];
}

export const useBranch = function() {
  return useContext(BranchContext);
}
