//
//1. Resolve paginated schema
//2. Resolve schema by id
//3. Fork schema by id
//4. Commit schema by id
import axios from 'axios';

export default class SchemaManager {
  constructor(url) {
    this.httpClient = axios.create({
      baseURL: url
    })
  }

  async resolveSchemaPage(page, limit, filter = {}) {
    return this.schemas
  }

  async resolveBranchMigrations(hash) {
    const res = await this.httpClient.post(`/branch/${hash}/migrations`)
    return res.data
  }

  async doBranchMigrations(hash) {
    const res = await this.httpClient.post(`/branch/${hash}/doMigrations`)
    return res.data
  }

  async resolveCommitMigrations(hash) {
    const res = await this.httpClient.post(`/commit/${hash}/migrations`)
    return res.data
  }

  async doCommitMigrations(hash) {
    const res = await this.httpClient.post(`/commit/${hash}/doMigrations`)
    return res.data
  }

  async store(hash, author, schemaObject) {
    const res = await this.httpClient.post(`/api/v1/schema`, { hash: hash, schema: schemaObject, author:  author }, {
      "content-type": "application/json"
    })
    return res.data
  }

  async resolveSchemaByHash(hash) {
    const res = await this.httpClient.get(`/api/v1/schema/${hash}`)
    return res.data.schema
  }

  async activateCommit(hash) {
    await this.httpClient.post(`/api/v1/schema/activate/${hash}`)
  }
}
