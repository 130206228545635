/* @flow */
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import momentjs from 'moment'
import uuid from 'uuid'
import { Mutation } from 'react-apollo'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Star from '@material-ui/icons/Star'
import IconButton from '@material-ui/core/IconButton'
import CallSplit from '@material-ui/icons/CallSplit'
import sha1 from 'sha1'
import gql from 'graphql-tag'
import {mutations} from '../graphql'

type Props = {
  schemas: PropTypes.array.isRequired,
  onFork: PropTypes.func.isRequired,
  user: PropTypes.string.isRequired,
  schemaManager: {
    resolveSchemaByHash: PropTypes.func.isRequired
  },
  onActivate: PropTypes.func.isRequired,
}
export default class CommitList extends Component<Props> {
  async handleForkCommit(mutation, commit) {
    const message = prompt('New branch name')
    if (!message) {
      return
    }
    const id = uuid()
    const input = {
      id,
      message,
      author: this.props.user,
      originCommitHash: commit.hash,
      hash: sha1(`${commit.hash}:${id}`)
    }
    await mutation({variables: {input}})
  }

  async handleActivateCommit(commit) {
    if(commit.active) {
      return
    }
    // TOOD: activate commit
    if(typeof this.props.onActivate === 'function') {
      this.props.onActivate(commit)
    }
  }

  render() {
    const { schemas, onFork } = this.props
    return (
      <List>
        { schemas.map(s =>
          <ListItem key={s.id}>
            <ListItemIcon onClick={() => this.handleActivateCommit(s)}>
              <Star color={s.active ? 'secondary' : 'disabled'}/>
            </ListItemIcon>
            <ListItemText
              title={`${momentjs(new Date(s.commitDate)).format('llll')}`}
              primary={`${s.hash.slice(0,6)} ${s.message}`}
              secondary={`by ${s.committer} ${momentjs(new Date(s.commitDate)).fromNow()}`}
            />
            <Mutation mutation={mutations.forkCommit} onCompleted={onFork}>
              {(forkCommit, {data}) => (
              <ListItemSecondaryAction>
                <IconButton onClick={_ => this.handleForkCommit(forkCommit, s)}>
                  <CallSplit/>
                </IconButton>
              </ListItemSecondaryAction>
              )}
            </Mutation>
          </ListItem>
        )}
      </List>
    );
  }
}
